import { Button, ButtonGroup, ModalCard, Spacing, Spinner } from "@vkontakte/vkui";
import { Icon28MentionOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal, timeUntilTheNextEmail, userEmail, userPassword } from "../storage/States";
import React from "react";
import RequestToApi from "../api";


const NecessaryToConfirmEmail = ({ id }: BaseProps) => {
    const [timerMinutes, setTimerMinutes] = React.useState<string>();
    const [timerSeconds, setTimerSeconds] = React.useState<string>();
    const [showTimer, setShowTimer] = React.useState<boolean>();
    const [loading, setLoading] = React.useState(false);

    let interval = React.useRef<ReturnType<typeof setInterval>>();

    const startTimer = () => {
        interval.current = setInterval(() => {
            const now = Date.now();

            const distance = timeUntilTheNextEmail.get() - now;

            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current);
                setShowTimer(false);
            } else {
                setTimerMinutes(minutes < 10 ? ("0" + minutes) : String(minutes));
                setTimerSeconds(seconds < 10 ? ("0" + seconds) : String(seconds));
                setShowTimer(true);
            }
        }, 10)
    }

    React.useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    });

    const resendEmailConfirm = () => {
        setLoading(true);

        const data = { email: userEmail.get(), password: userPassword.get() };

        RequestToApi({ method: 'post', url: 'auth/resend-email', data: data }, (d: any) => {
            RequestToApi({ method: 'post', url: 'auth/resend-time', data: data }, (data: any) => {
                setLoading(false);
                timeUntilTheNextEmail.set(data.timeUntilTheNextEmail)
            }, (e) => activeModal.set(''));

        }, (e) => activeModal.set(''));
    };

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28MentionOutline width={56} height={56} fill="#F3294A" />}
            header="Необходимо подтвердить E-mail"
            subheader={
                <>
                    Для подтверждения вашего адреса электронной почты, пожалуйста, перейдите по ссылке из письма. Это поможет нам убедиться, что указанный вами адрес является действительным и принадлежит именно вам.
                    <br /><br />

                    Если вы не видите письмо для подтверждения, пожалуйста, проверьте папку «Спам» или «Нежелательная почта», так как иногда уведомления могут оказаться там.
                </>
            }
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="horizontal" stretched>
                        <Button
                            size="l"
                            mode="secondary"
                            appearance="negative"
                            stretched
                            onClick={() => activeModal.set('')}
                        >
                            Хорошо
                        </Button>
                        <Button
                            disabled={loading ? true : showTimer ? true : false}
                            style={{ backgroundColor: "#F3294A" }}
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => resendEmailConfirm()}
                        >
                            {!loading ? <>{!showTimer ? "Отправить ещё" : (timerMinutes + ":" + timerSeconds)}</> : <Spinner size="regular" style={{ color: "#fff" }} />}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default NecessaryToConfirmEmail;