import { Button, ButtonGroup, Div, FormItem, FormStatus, Input, ModalCard, Spacing, Spinner, Snackbar } from "@vkontakte/vkui";
import { Icon28CheckCircleOutline, Icon28LockOutline, Icon28MentionOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal, snackbar } from "../storage/States";
import React from "react";
import { instance } from "../api";

const ResetPassword = ({ id }: BaseProps) => {
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorMessageFromServer, setErrorMessageFromServer] = React.useState("");

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
        setErrorMessage("");
    }

    const validateEmail = (email: string) => {
        return email.match(
            /* eslint-disable-next-line */
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    };

    const reset = () => {
        setErrorMessageFromServer("");

        if (email.length > 0) {
            if (validateEmail(email)) {
                setLoading(true);
                instance.post('auth/reset-password', { email: email })
                    .then(() => {
                        setLoading(false);
                        activeModal.set('');
                        snackbar.set(
                            <Snackbar
                                mode="dark"
                                onClose={() => snackbar.set(null)}
                                before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                            >
                                На ваш E-mail отправлена ссылка для восстановления доступа к вашему аккаунту
                            </Snackbar>
                        )
                    })
                    .catch((e) => {
                        setLoading(false);
                        if (e.response && e.response.data) {
                            setErrorMessageFromServer(e.response.data.message);
                        } else {
                            setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
                        }
                    });
            } else {
                setErrorMessage("Электронная почта некорректна");
            }
        } else {
            setErrorMessage("Необходимо ввести свой E-mail");
        }
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28LockOutline width={56} height={56} fill="#F3294A" />}
            header="Восстановление пароля"
            subheader="Введите адрес электронной почты, связанный с вашим аккаунтом. На него будет отправлена ссылка для восстановления доступа."
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            disabled={loading}
                            onClick={() => reset()}
                        >
                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : "Восстановить"}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            {errorMessageFromServer.length > 0 ? <Div>
                <FormStatus mode="error">
                    {errorMessageFromServer}
                </FormStatus>
            </Div> : <></>}
            <FormItem
                status={errorMessage.length > 0 ? 'error' : 'valid'}
                bottom={errorMessage}
            >
                <Input placeholder="E-mail" type="email" before={<Icon28MentionOutline width={24} height={24} fill="#818C99" />} onChange={onChangeEmail} value={email} />
            </FormItem>
        </ModalCard>
    )
}

export default ResetPassword;