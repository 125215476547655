import { Button, ButtonGroup, FormItem, Input, ModalCard, Snackbar, Spacing, Spinner } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import React from "react";
import { Icon28CheckCircleOutline, Icon28UserTextRectangleHorizontalOutline } from "@vkontakte/icons";
import { activeModal, snackbar, userData } from "../storage/States";
import RequestToApi from "../api";

const ChangeNameModal = ({ id }: BaseProps) => {
    const [firstname, setFirstname] = React.useState(userData.get() !== null ? userData.get()!.firstName : '');
    const [lastname, setLastname] = React.useState(userData.get() !== null ? userData.get()!.lastName : '');

    const [isNotDisabled, setIsNotDisabled] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const onChangeFirstnameOrLastname = (e: any) => {
        const { id } = e.currentTarget;
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (id === 'firstname') {
                setFirstname(firstname.substring(0, firstname.length - 1));
            } else if (id === 'lastname') {
                setLastname(lastname.substring(0, lastname.length - 1));
            } else return;
        } else if (e.nativeEvent.inputType === 'insertText') {
            const value = e.nativeEvent.data;
            if (value.charCodeAt() >= 1040 && value.charCodeAt() <= 1103) {
                if (id === 'firstname') {
                    setFirstname(firstname + value);
                } else if (id === 'lastname') {
                    setLastname(lastname + value);
                }
            }
        }
    }

    /* eslint-disable-next-line */
    React.useEffect(() => {
        setIsNotDisabled(
            !loading
            && firstname.length > 1
            && lastname.length > 1
            && (firstname !== (userData.get() !== null ? userData.get()!.firstName : '') || lastname !== (userData.get() !== null ? userData.get()!.lastName : ''))
        )
    });

    const change = () => {
        setLoading(true);

        const token = localStorage.getItem('access_token');

        RequestToApi({ method: 'post', url: 'user/name', data: { first_name: firstname, last_name: lastname }, token: token },
            (res) => {
                RequestToApi({ method: 'get', url: 'user/profile', token: token }, (response) => {
                    setLoading(false);
                    activeModal.set('');

                    snackbar.set(
                        <Snackbar
                            mode="dark"
                            onClose={() => snackbar.set(null)}
                            before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                        >
                            Вы успешно изменили свое имя
                        </Snackbar>
                    );
                    userData.set(response);
                },
                    (err) => {
                        setLoading(false);
                        activeModal.set('');
                    });

            }, (e) => {
                setLoading(false);
                activeModal.set('');
            })
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28UserTextRectangleHorizontalOutline width={56} height={56} fill="#F3294A" />}
            header="Изменение имени"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            disabled={!isNotDisabled}
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => change()}
                        >
                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : 'Изменить'}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            <FormItem>
                <Input id="firstname" placeholder="Введите своё имя" type="text" value={firstname} onChange={onChangeFirstnameOrLastname} />
            </FormItem>
            <FormItem>
                <Input id="lastname" placeholder="Введите свою фамилию" type="text" value={lastname} onChange={onChangeFirstnameOrLastname} />
            </FormItem>
        </ModalCard>
    )
}

export default ChangeNameModal;