import { Banner, Div, Footer, Group, Header, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Skeleton, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { Icon28AddOutline, Icon28FavoriteCircleFillGreen } from "@vkontakte/icons";
import { activeView, activeModal, allPromotions, editablePromotionId } from "../storage/States";
import React from "react";
import { getAllPromotions } from "../api/promotions";

const RenderPromotions = ({ isActive }: { isActive: boolean }) => {
    const getPromotions = allPromotions.get();

    if (getPromotions.length) {
        const render = [];

        for (let i = 0; i < getPromotions.length; i++) {
            if (getPromotions[i].isActive === isActive) {
                render.push(
                    <Div key={"promotion" + Math.random()}>
                        <Banner
                            before={<Icon28FavoriteCircleFillGreen />}
                            header="Акция"
                            subheader={getPromotions[i].text}
                            asideMode="expand"
                            onClick={() => { editablePromotionId.set(getPromotions[i].id); activeModal.set('editPromotion') }}
                        />
                    </Div>
                )
            } else continue;
        }

        if(render.length !== 0) {
            return <>{render}</>
        } else return <Footer>Акции не найдены</Footer>
    } else return <Footer>Акции не найдены</Footer>;
}

const RenderLoadingPromotions = () => {
    const render = [];

    for (let i = 0; i <= 5; i++) {
        render.push(
            <Div key={"loading_promotions_" + Math.random()}>
                <Skeleton height={86} />
            </Div>
        )
    }

    return <>{render}</>
}

const PromotionsView = ({ id }: BaseProps) => {
    const [loading, SetLoading] = React.useState(true);

    React.useEffect(() => getAllPromotions(() => setTimeout(() => SetLoading(false), 2000)), [loading]);

    return (
        <View id={id} activePanel={id}>
            <Panel id={id} >
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={<PanelHeaderButton onClick={() => activeModal.set('addPromotion')}><Icon28AddOutline /></PanelHeaderButton>}
                >
                    Акции
                </PanelHeader>
                <Group header={<Header mode="secondary">Активные акции</Header>}>
                    {loading ? <RenderLoadingPromotions /> : <RenderPromotions isActive={true} />}
                </Group>
                <Group header={<Header mode="secondary">Неактивные акции</Header>}>
                    {loading ? <RenderLoadingPromotions /> : <RenderPromotions isActive={false} />}
                </Group>
            </Panel>
        </View>
    )
}

export default PromotionsView;