import { Gradient, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder, View, File, Image, Button, Div, FormItem, FormLayoutGroup, Input, Select, Textarea, ScreenSpinner, Snackbar } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, editableProductId, popout, snackbar, tabs } from "../storage/States";
import React from "react";
import { getProductById } from "../utils/ActionsWithCart";
import { Icon24Camera, Icon28CheckCircleOutline } from "@vkontakte/icons";
import RequestToApi, { productPhotoUrl } from "../api";

const EditProductView = ({ id }: BaseProps) => {
    const product = getProductById(editableProductId.get());

    const findCategoryByProduct = tabs.get().find(tab => tab.products.find(product => product.id === editableProductId.get()));

    const [photo, setPhoto] = React.useState<any>(product?.photo);
    const [photoURL, setPhotoURL] = React.useState('');
    const [category, setCategory] = React.useState<number>(findCategoryByProduct!.id);
    const [name, setName] = React.useState(product!.name);
    const [cost, setCost] = React.useState<number>(product!.cost);
    const [quantity, setQuantity] = React.useState<string>(product!.quantity);
    const [quantityColor, setQuantityColor] = React.useState(product!.quantity_color);
    const [structure, setStructure] = React.useState(product!.structure);

    const InputCost = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            setCost(Number(cost.toString().substring(0, cost.toString().length - 1)));
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (!isNaN(e.nativeEvent.data)) {
                if (cost > 1000000) return;

                setCost(Number(cost.toString() + e.nativeEvent.data));
            } else return;
        }
    }

    const inputQuantity = (e: any) => {
        setQuantity(e.target.value);
    }

    const onChangePhoto = (e: any) => {
        const [file] = e.target.files;

        if (file) {
            setPhotoURL(URL.createObjectURL(file));
            setPhoto(file);
        }
    }

    const editProduct = () => {
        const token = localStorage.getItem('access_token');
        popout.set(<ScreenSpinner />);

        const data = {
            name: name,
            cost: cost,
            quantity: quantity.length > 0 ? quantity : "",
            quantity_color: quantityColor,
            structure: structure,
            category_id: category
        }

        RequestToApi({ method: 'put', url: 'products/' + product?.id, data: data, token: token }, (data) => {
            if ((typeof photo !== 'string')) {

                let formData = new FormData();
                formData.set('file', photo, photo.name);

                RequestToApi({ method: 'post', url: 'products/set-photo/' + product?.id, data: formData, token: token }, (data) => {
                    getCategoriesAndProducts(() => {
                        popout.set(null);
                        activeView.set('productsView');
                        showSuccessSnackbar();
                    })
                }, (e) => popout.set(null));
            } else {
                getCategoriesAndProducts(() => {
                    popout.set(null);
                    activeView.set('productsView');
                    showSuccessSnackbar();
                })
            }
        }, (e) => popout.set(null))
    }

    const showSuccessSnackbar = () => {
        snackbar.set(
            <Snackbar
                mode="dark"
                onClose={() => snackbar.set(null)}
                before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
            >
                Вы успешно отредактировали позицию
            </Snackbar>
        )
    }

    const getCategoriesAndProducts = (callback: () => void) => {
        RequestToApi({ method: 'get', url: 'products' }, (data) => {

            tabs.set(data);

            callback();
        }, (e) => callback());
    }


    return (
        <View id={id} activePanel={id}>
            <Panel id={id} >
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('productsView')} />}
                >
                    Редактировать
                </PanelHeader>
                <Group>
                    <Gradient mode="tint" to="top">
                        <Placeholder
                            icon={<Image id="photo_product" size={96} src={(typeof photo === 'string' ? (productPhotoUrl + photo) : photoURL)} />}
                            action={
                                <File mode="outline" appearance="negative" before={<Icon24Camera role="presentation" />} size="m" onChange={onChangePhoto}>
                                    Открыть галерею
                                </File>
                            }
                        >
                        </Placeholder>
                    </Gradient>

                    <FormLayoutGroup mode="horizontal">
                        <FormItem
                            top="Название"
                        >
                            <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Введите название"
                            />
                        </FormItem>

                        <FormItem top="Цена">
                            <Input type="tel" id="name" value={cost.toLocaleString('ru-RU') + " ₸"} onChange={InputCost} />
                        </FormItem>
                    </FormLayoutGroup>

                    <FormLayoutGroup mode="horizontal" segmented>
                        <FormItem htmlFor="quantity">
                            <Input id="quantity" placeholder="Кол-во" value={quantity} onChange={inputQuantity} />
                        </FormItem>
                        <FormItem>
                            <Select
                                onChange={(e) => setQuantityColor(e.target.value)}
                                options={[{ label: 'Белый', value: 'white' }, { label: 'Красный', value: 'red' }].map(
                                    (i) => ({
                                        label: i.label,
                                        value: i.value,
                                    }),
                                )}
                                value={quantityColor}
                            />
                        </FormItem>
                    </FormLayoutGroup>

                    <FormItem top="Описание">
                        <Textarea placeholder="Введите описание" value={structure} onChange={(e) => setStructure(e.target.value)} />
                    </FormItem>


                    <FormItem top="Категория" htmlFor="gender-select-id">
                        <Select
                            onChange={(e) => setCategory(Number(e.target.value))}
                            value={category}
                            id="category-select-id"
                            placeholder="Не выбрана"
                            options={tabs.get().map(tab => {
                                return {
                                    label: tab.name,
                                    value: tab.id
                                }
                            })}
                        />
                    </FormItem>
                    <Div>
                        <Button
                            onClick={() => editProduct()}
                            stretched
                            size="l"
                            style={{ backgroundColor: "#F3294A" }}
                        >
                            Изменить
                        </Button>
                    </Div>
                </Group>
            </Panel>
        </View>
    )
}

export default EditProductView;