import React from 'react';
import { Icon28MotorcycleOutline, Icon28ShoppingCartOutline, Icon56CoinsStacks3Outline, Icon28ReceiptOutline } from "@vkontakte/icons";
import { Group, SimpleCell } from "@vkontakte/vkui";
import { actionWithBonuses, deliveryMethods, newCart, percentOfBonuses, selectedDeliveryMethod, userData } from "../storage/States";
import { getProductById } from "../utils/ActionsWithCart";

const InfoAboutOrder = () => {

    const getOrderSummary = () => {
        return newCart.get().reduce((a, b) => a + (b.count * Number(getProductById(b.productId)?.cost)), 0);
    }

    const getCostOfSelectedMethodDelivery = () => {
        if (deliveryMethods.get().length !== 0 && selectedDeliveryMethod.get() !== 0) {
            return deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())!.cost
        } else return 0;
    }

    const getValueBonuses = (): number => {
        const summary = getOrderSummary();

        if (actionWithBonuses.get() === 'GAIN') {
            return Number((summary * (percentOfBonuses.get() / 100)).toFixed(0));
        } else if (actionWithBonuses.get() === 'PAY') {
            if (userData.get() !== null) {
                if(summary < userData.get()!.balance) {
                    return (summary - 30);
                } else {
                    if((summary - userData.get()!.balance) >= 30) {
                        return userData.get()!.balance
                    } else {
                        return ((userData.get()!.balance) - Math.abs((summary - userData.get()!.balance) - 30))
                    }
                }
            } else {
                return 0;
            }
        } else return 0;
    }

    const getTotalSumOfOrder = () => {
        if (actionWithBonuses.get() === 'PAY') {
            return ((getOrderSummary() - getValueBonuses()) + getCostOfSelectedMethodDelivery());
        } else {
            return (getOrderSummary() + getCostOfSelectedMethodDelivery())
        };
    }


    return (
        <>
            <Group>
                <SimpleCell
                    before={<Icon28ShoppingCartOutline />}
                    indicator={getOrderSummary().toLocaleString('ru-RU') + " ₸"}
                >
                    Подытог
                </SimpleCell>
                <SimpleCell
                    before={<Icon28MotorcycleOutline />}
                    indicator={"+ " + getCostOfSelectedMethodDelivery().toLocaleString('ru-RU') + " ₸"}
                >
                    Доставка
                </SimpleCell>
            </Group>
            {userData.get() !== null ? (<Group>
                <SimpleCell
                    before={<Icon56CoinsStacks3Outline width={28} height={28} />}
                    indicator={actionWithBonuses.get() === 'GAIN' ? "+ " + getValueBonuses().toLocaleString('ru-RU') + " Б" : "- " + getValueBonuses().toLocaleString('ru-RU') + " Б"}
                >
                    Бонусы
                </SimpleCell>
            </Group>) : <></>}
            <Group>
                <SimpleCell
                    before={<Icon28ReceiptOutline />}
                    indicator={getTotalSumOfOrder().toLocaleString('ru-RU') + " ₸"}
                >
                    Итого к оплате
                </SimpleCell>
            </Group>
        </>
    )
}

export default InfoAboutOrder;