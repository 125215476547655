import React from "react";
import { Button, ButtonGroup, FormItem, Input, ModalCard, Snackbar, Spacing, Spinner } from "@vkontakte/vkui";
import { Icon28CheckCircleOutline, Icon56PhoneOutline } from "@vkontakte/icons";
import { AsYouType } from "libphonenumber-js";

import BaseProps from "../props/BaseProps";
import { activeModal, snackbar, userData } from "../storage/States";
import { instance } from "../api";
import flag from '../lib/img/flag.svg';

const ChangePhoneNumberModal = ({ id }: BaseProps) => {
    const [phoneNumber, setPhoneNumber] = React.useState('+77');
    const [phoneNumberInputError, setPhoneNumberInputError] = React.useState(!(phoneNumber.length === 12) ? "Пожалуйста, введите номер телефона в международном формате" : "");

    const [isNotDisabled, setIsNotDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onChangePhoneNumber = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (phoneNumber.length > 3) {
                setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 1));
                setPhoneNumberInputError("Пожалуйста, введите номер телефона в международном формате");
            }
            else return;
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (phoneNumber.length < 12 && !isNaN(e.nativeEvent.data)) {
                setPhoneNumber(phoneNumber + e.nativeEvent.data);
                if (phoneNumber.length === 11) setPhoneNumberInputError("");
            } else return;
        }
    }

    /* eslint-disable-next-line */
    React.useEffect(() => {
        setIsNotDisabled(!loading && !phoneNumberInputError)
    });

    const change = () => {
        setLoading(true);
        setPhoneNumberInputError('');

        const token = localStorage.getItem('access_token');

        instance.post('user/phone', { phone_number: phoneNumber }, { headers: { 'authorization': 'Bearer ' + token } })
            .then((res) => {
                instance.get('user/profile', { headers: { 'authorization': 'Bearer ' + token } })
                    .then((response) => {
                        setLoading(false);
                        activeModal.set('');

                        snackbar.set(
                            <Snackbar
                                mode="dark"
                                onClose={() => snackbar.set(null)}
                                before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                            >
                                Вы успешно изменили свой номер телефона
                            </Snackbar>)

                        userData.set(response.data);
                    })
                    .catch((e) => {
                        setLoading(false);

                        if (e.response && e.response.data) {
                            setPhoneNumberInputError(e.response.data.message);
                        } else {
                            setPhoneNumberInputError('Произошли непредвиденные сложности при выполнении запроса');
                        }

                    })
            })
            .catch((e) => {
                setLoading(false);

                if (e.response && e.response.data) {
                    setPhoneNumberInputError(e.response.data.message);
                } else {
                    setPhoneNumberInputError('Произошли непредвиденные сложности при выполнении запроса');
                }
            })
    }


    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon56PhoneOutline width={56} height={56} fill="#F3294A" />}
            header="Изменение номера телефона"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            disabled={!isNotDisabled}
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => change()}
                        >
                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : 'Изменить'}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            <FormItem top="Номер телефона"
                status={phoneNumberInputError ? 'error' : 'valid'}
                bottom={phoneNumberInputError}
            >
                <Input
                    disabled={loading}
                    type="tel"
                    placeholder="Введите номер телефона"
                    value={new AsYouType('KZ').input(String(phoneNumber))} onChange={onChangePhoneNumber}
                    before={<img width={28} height={28} src={flag} alt="" />}
                />
            </FormItem>
        </ModalCard>
    )
}

export default ChangePhoneNumberModal;