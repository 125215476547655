import { Alert, Avatar, Button, ButtonGroup, Div, Footer, Gradient, Group, Header, Panel, PanelHeader, PanelHeaderBack, Placeholder, ScreenSpinner, Separator, SimpleCell, Snackbar, View, Link, PanelHeaderButton } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, activeStory, activeView, errorMessage, infoAboutOrderById, myOrders, popout, snackbar } from "../storage/States";
import { Icon36CoinsStacks3Outline, Icon28MarketOutline, Icon28MarketSlashOutline, Icon28PaymentCardOutline, Icon20ClockOutline, Icon20CheckCircleOutline, Icon20CancelCircleOutline, Icon28ErrorCircleOutline, Icon28CheckCircleOutline, Icon28ReplayOutline, Icon28MoneyWadOutline } from "@vkontakte/icons";
import RequestToApi, { instance } from "../api";
import { getReasonMessage } from "../utils/getReasonTextForCancellation";
import { AsYouType } from "libphonenumber-js";
import { getCategoriesAndProductsWithCallback } from "../api/products";
import ProductInOrderType from "../types/ProductInOrderType";
import { getProductById, addProductsInCart } from "../utils/ActionsWithCart";

const InfoAboutOrderView = ({ id }: BaseProps) => {
    const order = myOrders.get().find(orderByID => orderByID.id === infoAboutOrderById.get());

    const getStatusIcon = (methodOfPayment: 'CASH' | 'BANK_CARD', status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') {
            if(methodOfPayment === 'BANK_CARD') return <Icon20ClockOutline fill="var(--vkui--color_icon_secondary)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
            else return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        }
        if (status === 'CONFIRMED') return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'NOT_CONFIRMED') return <Icon20CancelCircleOutline fill="var(--vkui--color_icon_negative)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
    }

    const getTextStatus = (methodOfPayment: 'CASH' | 'BANK_CARD', status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') {
            if(methodOfPayment === 'BANK_CARD') return 'Ожидает оплаты';
            else return 'Заказ принят';
        };
        if (status === 'CONFIRMED') return 'Заказ принят';
        if (status === 'NOT_CONFIRMED') return 'Отменён';
    }

    const payOrder = () => {
        if (order!.payUrl.length > 0) window.open("https://securepayments.fortebank.com/widget/hpp.html?token=" + order!.payUrl, '_blank', '');
        else snackbar.set(
            <Snackbar
                mode="dark"
                onClose={() => snackbar.set(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
            >
                Произошла ошибка при оплате
            </Snackbar>
        )
    }

    // eslint-disable-next-line
    const repeatOrder = (products: ProductInOrderType[]) => {
        popout.set(<ScreenSpinner />);

        getCategoriesAndProductsWithCallback(() => {
            const findedProduct = [];

            for (let i = 0; i < products.length; i++) {
                const product = getProductById(products[i].productId);

                if (product) {
                    findedProduct.push(products[i]);
                } else continue;
            }

            popout.set(null);

            if (findedProduct.length !== 0) {
                addProductsInCart(products);

                activeStory.set('cart');
                activeView.set('homeView');
            } else snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                >
                    Произошла ошибка
                </Snackbar>
            )
        })
    }

    const cancelOrder = () => {
        popout.set(
            <Alert
                actions={[
                    {
                        title: 'Отменить',
                        mode: 'destructive',
                        action: () => cancelOrderConfirmed()
                    },
                    {
                        title: 'Нет',
                        mode: 'cancel',
                    }
                ]}
                actionsLayout="horizontal"
                dismissButtonMode="inside"
                onClose={() => popout.set(null)}
                header="Отменить заказ"
                text="Вы уверены, что хотите отменить заказ?"
            />,

        )
    }


    const handleException = (e: any) => {
        popout.set(null);
        if (e.response && e.response.data) {
            if (e.response.data.type === 'WARN') {
                if (e.response.id === 'ORDER_CANNOT_BE_CANCELLED') {
                    activeModal.set('orderCannotBeCancelled')
                } else {
                    snackbar.set(
                        <Snackbar
                            mode="dark"
                            onClose={() => snackbar.set(null)}
                            before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                        >
                            {e.response.data.message}
                        </Snackbar>
                    );
                }
            }
            else if (e.response.data.type === 'ERROR') {
                errorMessage.set(e.response.data.message);
                activeView.set('errorView');
            }
        } else {
            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                >
                    Произошли неприедвиденные сложности
                </Snackbar>
            );
        }
    }

    const cancelOrderConfirmed = () => {
        popout.set(<ScreenSpinner />);

        const access_token = localStorage.getItem('access_token');

        instance.put('order/cancel/' + order!.id, {}, { headers: { 'authorization': 'Bearer ' + access_token } })
            .then(response => {
                instance.get('user/orders', { headers: { 'authorization': 'Bearer ' + access_token } }).then(orders => {
                    myOrders.set(orders.data);
                    popout.set(null);
                    snackbar.set(
                        <Snackbar
                            mode="dark"
                            onClose={() => snackbar.set(null)}
                            before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                        >
                            Вы успешно отменили заказ
                        </Snackbar>
                    );
                })
                    .catch(handleException)
            }).catch(handleException)
    }

    const getMyOrders = () => {
        popout.set(<ScreenSpinner />);
        RequestToApi({ method: 'get', url: 'user/orders', token: localStorage.getItem("access_token") }, (response) => {
            myOrders.set(response);
            popout.set(null)
        }, () => popout.set(null));
    }


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('myOrdersView')} />}
                    after={<PanelHeaderButton onClick={() => getMyOrders()}><Icon28ReplayOutline /></PanelHeaderButton>}
                >
                    Информация о заказе
                </PanelHeader>

                <Group>
                    <Gradient mode="tint" to="top">
                        <Placeholder
                            icon={<Avatar size={96} style={{ backgroundColor: "#F3294A" }}><Icon28MarketOutline fill="#ffffff" width={56} height={56} /></Avatar>}
                            header={<>Заказ №{order!.id} {' '} {getStatusIcon(order!.methodOfPayment, order!.status)}</>}
                            action={
                                order!.actionWithBonuses === 'GAIN' ? <Button before={<Icon36CoinsStacks3Outline width={20} height={20} />} mode="outline" size="m" appearance="neutral" disabled>
                                    {" + " + order!.bonuses.toLocaleString("ru-RU") + " Б"}
                                </Button> : <></>
                            }

                        >
                            {getTextStatus(order!.methodOfPayment, order!.status)}
                            <br />
                            <br />
                            {order!.status === 'NOT_CONFIRMED' ? <>Причина: <br /> {getReasonMessage(order!.reasonForCancellation)}</> : <></>}
                            {order!.status === 'AWAITING_CONFIRMATION' && order!.methodOfPayment === 'BANK_CARD' ? 'Для того чтобы ваш заказ был принят, вам необходимо его оплатить' : ''}
                        </Placeholder>
                    </Gradient>

                    {order!.status === 'AWAITING_CONFIRMATION' && order!.methodOfPayment === 'BANK_CARD' ? <Div>
                        <ButtonGroup mode="horizontal" gap="s" stretched>
                            <Button before={<Icon28PaymentCardOutline />} mode="outline" size="l" appearance="neutral" onClick={() => payOrder()} stretched>
                                Оплатить
                            </Button>
                            <Button before={<Icon28MarketSlashOutline />} mode="outline" size="l" appearance="negative" onClick={() => cancelOrder()} stretched>
                                Отменить
                            </Button>
                        </ButtonGroup>
                    </Div> : <></>}

                    <Group mode="plain" header={<Header>Способ оплаты</Header>}>
                        {order!.methodOfPayment === 'BANK_CARD' ?
                            <SimpleCell before={<Icon28PaymentCardOutline />} indicator={order!.creditCardFirst1.length > 0 ? order!.creditCardFirst1 + "****" + order!.creditCardLast4 : ""}>Банковская карта</SimpleCell> :
                            <SimpleCell before={<Icon28MoneyWadOutline />}>Наличный расчёт</SimpleCell>
                        }
                    </Group>


                    <Group mode="plain" header={<Header >Номер телефона</Header>}>
                        <SimpleCell

                        >
                            {new AsYouType('KZ').input(String(order!.phoneNumber))}
                        </SimpleCell>
                    </Group>


                    {order!.deliveryAddress.length > 0 ?
                        <Group mode="plain" header={<Header >Адрес доставки</Header>}>
                            <SimpleCell

                            >
                                {order!.deliveryAddress}
                            </SimpleCell>
                        </Group>
                        : <></>}

                    {order!.comment.length > 0 ?
                        <Group mode="plain" header={<Header >Комметарий к заказу</Header>}>
                            <SimpleCell

                            >
                                {order!.comment}
                            </SimpleCell>
                        </Group>
                        : <></>}

                    <Group mode="plain" header={<Header aside={<Button mode="tertiary" onClick={() => repeatOrder(order!.products)}>Повторить заказ</Button>}>Заказ: </Header>}>
                        <Div style={{ marginTop: 0, paddingTop: 0 }}>
                            {order!.products.map((product, index) => (
                                <div key={"order" + index + "_" + Math.random()}>
                                    <SimpleCell key={'infoAboutOrder_' + order!.id + "_" + index} before={index + 1}
                                        indicator={(product.cost * product.count).toLocaleString("ru-RU") + "₸"}
                                        subtitle={product.count + " шт. × " + product.cost.toLocaleString('ru-RU') + "₸"}>
                                        {product.name}
                                    </SimpleCell>
                                    {index + 1 < order!.products.length ? <><Separator /></> : <></>}
                                </div>
                            ))}
                            <Separator style={{ color: '#000' }} />
                            {order!.actionWithBonuses === 'PAY' || order!.deliveryMethod.cost > 0 ? <SimpleCell

                                indicator={order!.summary.toLocaleString('ru-RU') + " ₸"}
                            >
                                Подытог
                            </SimpleCell> : <></>}
                            {order!.actionWithBonuses === 'PAY' ? <SimpleCell

                                indicator={"- " + order!.bonuses.toLocaleString('ru-RU') + " Б"}
                            >
                                Бонусы
                            </SimpleCell> : <></>}
                            {order!.deliveryMethod.cost > 0 ? <SimpleCell

                                subtitle={order!.deliveryMethod.name}
                                indicator={"+ " + order!.deliveryMethod.cost.toLocaleString("ru-RU") + " ₸"}
                            >
                                Доставка
                            </SimpleCell> : <></>}
                            <Separator />
                            <SimpleCell

                                indicator={order!.amountToBePaid.toLocaleString("ru-RU") + " ₸"}><b>Итого к оплате</b></SimpleCell>
                        </Div>
                    </Group>
                    {order!.status === 'CONFIRMED' || order!.methodOfPayment === 'CASH' && order!.status === 'AWAITING_CONFIRMATION' ? <Footer>Заказ уже принят. <br />Для того чтобы уточнить возможность отмены заказа, вы можете с нами связаться по номеру телефона: <br /><Link href='tel:+77054618106'>+7 (705) 461-81-06</Link></Footer> : <></>}
                </Group>
            </Panel>
        </View >
    )
}

export default InfoAboutOrderView;