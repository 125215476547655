import React from 'react';
import { ScreenSpinner } from "@vkontakte/vkui"
import { activeModal, activeStory, newCart, popout, tabs } from "../storage/States"
import RequestToApi from "../api";
import { checkAvaivability } from "./ActionsWithCart";

export const GoToCart = () => {
    if (activeStory.get() !== 'cart') {
        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'get', url: 'products' }, (response) => {
            tabs.set(response);

            const productInCart = checkAvaivability(newCart.get());

            popout.set(null);

            if (productInCart.length !== 0) {
                activeStory.set('cart');
                window.scrollTo(0, 0);
            } else {
                activeModal.set("cartIsEmpty");
            }
        }, () => popout.set(null));
    } else return;
}