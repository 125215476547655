import { Icon28WarningTriangleOutline } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, FormItem, SimpleCell, Switch, ScreenSpinner, Textarea, Input } from "@vkontakte/vkui";
import React from "react";
import BaseProps from "../props/BaseProps";
import { activeModal, allReasonsForClosure, editableReasonToClosure, popout } from "../storage/States";
import RequestToApi from "../api";

const EditReasonToClosure = ({ id }: BaseProps) => {
    const reason = allReasonsForClosure.get().find(r => r.id === editableReasonToClosure.get());

    const [title, setTitle] = React.useState(reason?.title);
    const [message, setMessage] = React.useState(reason?.message);
    const [isActive, setIsActive] = React.useState(reason?.active);

    const onChangeMessage = (e: any) => setMessage(e.target.value);
    const onChangeTitle = (e: any) => setTitle(e.target.value);

    const editReason = () => {
        activeModal.set('');
        popout.set(<ScreenSpinner />);

        const data = {
            title: title,
            message: message,
            active: isActive
        }

        const token = localStorage.getItem('access_token');

        RequestToApi({ method: 'put', url: 'settings/close/' + reason!.id, data: data, token: token }, (response) => {
            console.log(response);

            RequestToApi({ method: 'get', url: 'settings/close', token: token }, (data) => {
                allReasonsForClosure.set(data);
                popout.set(null);
            }, (e) => popout.set(null));
        }, (e) => popout.set(null));
    }

    const deleteReason = () => {
        const token = localStorage.getItem('access_token');

        activeModal.set('');
        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'delete', url: 'settings/close/' + reason!.id, token: token }, (response) => {
            RequestToApi({ method: 'get', url: 'settings/close', token: token }, (data) => {
                allReasonsForClosure.set(data);
                popout.set(null);
            }, (e) => popout.set(null));
        })
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28WarningTriangleOutline width={56} height={56} fill="#F3294A" />}
            header="Редактирование"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="horizontal" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => editReason()}
                        >

                            Изменить
                        </Button>
                        <Button
                            appearance="negative"
                            key="join"
                            size="l"
                            mode="outline"
                            stretched
                            onClick={() => deleteReason()}
                        >

                            Удалить
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            <FormItem>
                <Input placeholder="Введите название причины" value={title} onChange={onChangeTitle} />
            </FormItem>
            <FormItem>
                <Textarea placeholder="Введите сообщение клиенту" value={message} onChange={onChangeMessage} />
            </FormItem>
            <FormItem>
                <SimpleCell after={<Switch defaultChecked={isActive} onClick={() => setIsActive(!isActive)} />}>Активна?</SimpleCell>
            </FormItem>
        </ModalCard>
    )
}

export default EditReasonToClosure;