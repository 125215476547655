import { View, Panel, PanelHeader, PanelHeaderBack, HorizontalScroll, Tabs, TabsItem, Footer, Separator, Spacing, Checkbox, SimpleCell, AdaptiveIconRenderer, ScreenSpinner, PanelHeaderButton } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeTab, activeView, availabilityOfTheDish, notAvailabilityOfTheDish, popout, tabs } from "../storage/States";
import { Icon20WriteOutline, Icon28EditOutline } from "@vkontakte/icons";
import RequestToApi from "../api";


const availability = (id: number, a: boolean) => {
    if (a) {
        const notAvailabilityOfTheDishArray = notAvailabilityOfTheDish.get();
        const availabilityOfTheDishArray = availabilityOfTheDish.get();

        if (availabilityOfTheDishArray.indexOf(id) === -1) {
            availabilityOfTheDishArray.push(id);

            const index = notAvailabilityOfTheDishArray.indexOf(id);

            notAvailabilityOfTheDishArray.splice(index, 1);
            notAvailabilityOfTheDish.set(notAvailabilityOfTheDishArray);
            availabilityOfTheDish.set(availabilityOfTheDishArray);
        }
    } else {
        const notAvailabilityOfTheDishArray = notAvailabilityOfTheDish.get();
        const availabilityOfTheDishArray = availabilityOfTheDish.get();

        if (notAvailabilityOfTheDishArray.indexOf(id) === -1) {
            notAvailabilityOfTheDishArray.push(id);
            notAvailabilityOfTheDish.set(notAvailabilityOfTheDishArray);

            const index = availabilityOfTheDishArray.indexOf(id);

            availabilityOfTheDishArray.splice(index, 1);
            availabilityOfTheDish.set(availabilityOfTheDishArray);
        }
    }
}

const RenderProducts = () => {
    const tab = tabs.get();

    const products = [];

    for (let i = 0; i < tab.length; i++) {
        for (let j = 0; j < tab[i].products.length; j++) {

            if (tab[i].products[j].availability) {
                const availabilityOfTheDishArray = availabilityOfTheDish.get();
                const notAvailabilityOfTheDishArray = notAvailabilityOfTheDish.get();

                if (availabilityOfTheDishArray.indexOf(tab[i].products[j].id) === -1 && notAvailabilityOfTheDishArray.indexOf(tab[i].products[j].id) === -1) {
                    availabilityOfTheDishArray.push(tab[i].products[j].id);
                    availabilityOfTheDish.set(availabilityOfTheDishArray);
                }
            } else {
                const notAvailabilityOfTheDishArray = notAvailabilityOfTheDish.get();
                const availabilityOfTheDishArray = availabilityOfTheDish.get();
                if (notAvailabilityOfTheDishArray.indexOf(tab[i].products[j].id) === -1 && availabilityOfTheDishArray.indexOf(tab[i].products[j].id) === -1) {
                    notAvailabilityOfTheDishArray.push(tab[i].products[j].id);
                    notAvailabilityOfTheDish.set(notAvailabilityOfTheDishArray);
                }
            }


            if (tab[i].id === activeTab.get()) {
                products.push(
                    <div key={"product_admin" + Math.random()}>
                        <Checkbox
                            onClick={() => availability(tab[i].products[j].id, !(notAvailabilityOfTheDish.get().indexOf(tab[i].products[j].id) === -1))}
                            checked={notAvailabilityOfTheDish.get().indexOf(tab[i].products[j].id) === -1 ? true : false}>
                            <SimpleCell subtitle={tab[i].products[j].structure}>{tab[i].products[j].name} {tab[i].products[j].quantity.length > 0 ? `(${tab[i].products[j].quantity})` : ``}</SimpleCell>
                        </Checkbox>
                        {j !== (tab[i].products.length - 1) ? <Separator key={"product_admin_separator" + Math.random()} /> : <></>}
                    </div>
                )
            }
        }
    }

    return <div>{products}</div>
}

const save = () => {
    popout.set(<ScreenSpinner />)

    RequestToApi({ url: 'products/availability', method: 'post', token: localStorage.getItem('access_token'), data: { availability: availabilityOfTheDish.get(), notAvailability: notAvailabilityOfTheDish.get() } },
        (data: any) => {
            RequestToApi({ method: 'get', url: 'products' }, (response: any) => {
                popout.set(null);
                tabs.set(response);
            })
        }, () => popout.set(null));
}




const RenderTabs = () => {
    const tab = tabs.get();
    const renderedTabs = [];

    for (let i = 0; i < tab.length; i++) {
        renderedTabs.push(
            <TabsItem
                key={"tab_" + Math.random()}
                selected={activeTab.get() === tab[i].id}
                onClick={() => activeTab.set(tab[i].id)}
            >
                {tab[i].name}
            </TabsItem>
        )
    }

    return (
        <Tabs
            mode="accent"
            layoutFillMode="stretched"
            withScrollToSelectedTab
            scrollBehaviorToSelectedTab="center"
        >
            <HorizontalScroll arrowSize="m">
                {renderedTabs}
            </HorizontalScroll>
        </Tabs>
    )
}

const AvailabilityOfTheDishView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => {
                        activeView.set('productsView');
                        notAvailabilityOfTheDish.set([]);
                        availabilityOfTheDish.set([]);
                    }} />}
                    after={<PanelHeaderButton onClick={() => save()}><AdaptiveIconRenderer IconCompact={Icon20WriteOutline} IconRegular={Icon28EditOutline} /></PanelHeaderButton>}
                >В наличие/Нет в наличии</PanelHeader>
                <RenderTabs />
                <RenderProducts />
            </Panel>
        </View>
    )
}

export default AvailabilityOfTheDishView;