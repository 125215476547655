import { View, Panel, PanelHeader, PanelHeaderBack, Group, Cell, List, ScreenSpinner } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeTab, activeView, draggingListTabs, popout, tabs } from "../storage/States";
import React, { useEffect } from "react";
import RequestToApi from "../api";

const SortCategoriesView = ({ id }: BaseProps) => {
    const onDragFinish = ({ from, to }: { from: any, to: any }) => {
        const dg = draggingListTabs.get();
        const _list = [...dg];
        _list.splice(from, 1);
        _list.splice(to, 0, dg[from]);

        draggingListTabs.set(_list);
    };

    const exit = () => {
        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'post', url: 'products/queue', data: { ids: draggingListTabs.get().map(tab => tab.id) }, token: localStorage.getItem('access_token') }, (data) => {
            RequestToApi({ method: 'get', url: 'products' }, (data: any) => {
                if (data.length !== 0) {
                    tabs.set(data);
                    draggingListTabs.set(data);
                    activeTab.set(data[0].id);

                    popout.set(null)
                    activeView.set('categoriesView');
                }
            }, (e) => {
                tabs.set([]);
                activeTab.set(0);
            })
        }, () => popout.set(null));
    }

    useEffect(() => {
        if (draggingListTabs.get().length === 0) {
            draggingListTabs.set(tabs.get());
        }
    }, [])


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    delimiter="spacing"
                    before={<PanelHeaderBack onClick={() => exit()} />}
                >
                    Сортировать категории
                </PanelHeader>
                <Group>
                    <List>
                        {draggingListTabs.get().map((item) => (
                            <Cell key={'drag' + item.id} draggable onDragFinish={onDragFinish}>
                                {item.name}
                            </Cell>
                        ))}
                    </List>
                </Group>
            </Panel>
        </View>
    )
}

export default SortCategoriesView;