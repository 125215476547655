import { Button, Div, FormItem, FormLayoutGroup, FormStatus, Group, Header, Input, Panel, PanelHeader, PanelHeaderBack, ScreenSpinner, SegmentedControl, SelectMimicry, SubnavigationBar, SubnavigationButton, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { actionWithBonuses, activeModal, activeView, anonimOrder, anonimOrderToken, deliveryMethods, infoAboutOrderById, lastOrderId, lastOrderPayToken, myOrders, newCart, popout, selectedDeliveryMethod, userData } from "../storage/States";
import InfoAboutOrder from "../elements/InfoAboutOrder";
import { AsYouType } from "libphonenumber-js";
import React from "react";
import RequestToApi from "../api";
import { clearCart } from "../utils/ActionsWithCart";

import flag from '../lib/img/flag.svg';
import { getMobileOperatingSystem } from "../Providers";
import { Icon28MoneyWadOutline, Icon28PaymentCardOutline } from "@vkontakte/icons";

const CreateOrderView = ({ id }: BaseProps) => {
    const [status, setStatus] = React.useState(false);

    const [street, setStreet] = React.useState('');
    const [house, setHouse] = React.useState('');
    const [apartment, setApartment] = React.useState('');

    const [phoneNumber, setPhoneNumber] = React.useState(userData.get() !== null ? userData.get()!.phoneNumber : '+77');
    const [phoneNumberInputError, setPhoneNumberInputError] = React.useState(!(phoneNumber.length === 12));
    const [comment, setComment] = React.useState('');
    const [methodOfPayment, setMethodOfPayment] = React.useState<'CASH' | 'BANK_CARD'>('CASH')

    const newOrder = () => {
        popout.set(<ScreenSpinner state="loading" />);

        const access_token = localStorage.getItem('access_token');

        RequestToApi({
            method: 'post',
            url: 'order',
            data: {
                actionWithBonuses: actionWithBonuses.get(),
                deliveryAddress: (deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.address) ?
                    (street + ", д. " + house + (apartment.length > 0 ? (", " + apartment + " кв. / п.") : "")) : "",
                phoneNumber: phoneNumber,
                deliveryMethodId: selectedDeliveryMethod.get(),
                comment: comment,
                products: newCart.get(),
                methodOfPayment: methodOfPayment
            },
            token: actionWithBonuses.get() !== "NOT_AUTHORIZED" ? access_token : null
        },
            (data: any) => {
                activeView.set('homeView')
                clearCart();

                if (actionWithBonuses.get() !== "NOT_AUTHORIZED") {
                    actionWithBonuses.set('GAIN');
                    RequestToApi({ method: 'get', url: 'user/orders', token: localStorage.getItem("access_token") }, (response) => {
                        myOrders.set(response);
                        popout.set(null);
                        infoAboutOrderById.set(data.orderId);
                        activeView.set('infoAboutOrder');
                    }, () => popout.set(null));
                } else {
                    console.log(data.order);
                    popout.set(null);
                    lastOrderPayToken.set(data.payURL);
                    anonimOrderToken.set(data.order.token);
                    lastOrderId.set(data.order.id);
                    anonimOrder.set(data.order);
                    activeView.set('anonimOrderView');
                }
            },
            (e: any) => {
                if (e.response && e.response.data) {
                    if (e.response.data.type === "ERROR") {
                        activeModal.set('');
                    }
                } else {
                    activeModal.set('');
                }

                popout.set(null);
            });
    }

    const changeReportType = (value: string) => {
        if (value === "PAY") {
            if (userData.get()!.balance < 1) {
                setStatus(true);

                setTimeout(() => setStatus(false), 5000);
                return;
            }
        }

        setStatus(false);
        actionWithBonuses.set(value);
    }

    const onChangePhoneNumber = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (phoneNumber.length > 3) {
                setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 1));
                setPhoneNumberInputError(true);
            }
            else return;
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (phoneNumber.length < 12 && !isNaN(e.nativeEvent.data)) {
                setPhoneNumber(phoneNumber + e.nativeEvent.data);
                if (phoneNumber.length === 11) setPhoneNumberInputError(false);
            } else return;
        }
    }

    const disabledButton = () => {
        const conditions = {
            street: false,
            house: false,
            apartment: false,
            phoneNumber: false
        };

        if (deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.address) {
            if (street.length < 1) conditions.street = true;
            if (house.length < 1) conditions.house = true;
            if (deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.apartment && apartment.length < 1) conditions.apartment = true;
        }

        if (phoneNumber.length !== 12) {
            conditions.phoneNumber = true
        };

        let condition = false;

        /* eslint-disable-next-line */
        for (const [key, value] of Object.entries(conditions)) {
            if (value === true) {
                condition = true;
                break;
            }
        }
        return condition;
    }
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('homeView')} />}

                >
                    Оформление заказа
                </PanelHeader>

                <Group header={<Header>Информация о заказе</Header>}>
                    <InfoAboutOrder />
                </Group>
                <Group>
                    <FormItem top="Способ оплаты">
                        <SubnavigationBar mode="fixed">
                            <SubnavigationButton
                                before={<Icon28MoneyWadOutline fill={methodOfPayment !== 'CASH' ? "#F3294A" : ""}/>}
                                size="l"

                                selected={methodOfPayment === 'CASH'}
                                onClick={() => setMethodOfPayment('CASH')}
                            >
                                Наличные
                            </SubnavigationButton>
                            <SubnavigationButton
                                before={<Icon28PaymentCardOutline fill={methodOfPayment !== 'BANK_CARD' ? "#F3294A" : ""}/>}
                                size="l"
                                selected={methodOfPayment === 'BANK_CARD'}
                                onClick={() => setMethodOfPayment('BANK_CARD')}
                            >
                                Банковская карта
                            </SubnavigationButton>
                        </SubnavigationBar>
                    </FormItem>
                    {status ? <Div>
                        <FormStatus header="Недостаточно бонусов" mode="error">
                            К сожалению, у вас нет бонусов для оплаты заказа
                        </FormStatus>
                    </Div> : <></>}
                    {userData.get() !== null ? (<FormItem top="Действие с бонусами">
                        <SegmentedControl
                            size="m"
                            name="type"
                            value={actionWithBonuses.get()}
                            onChange={(value) => changeReportType(String(value))}
                            options={[
                                {
                                    label: 'Накопить',
                                    value: 'GAIN'
                                },
                                {
                                    label: 'Потратить',
                                    value: 'PAY'
                                },
                            ]}
                        />
                    </FormItem>) : <></>}
                    <FormLayoutGroup>
                        <FormItem top="Способ доставки">
                            <SelectMimicry placeholder="Не выбрана" onClick={() => activeModal.set('chooseDeliveryMethod')}>
                                {deliveryMethods.get().length !== 0 ? deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.name : ''}
                            </SelectMimicry>
                        </FormItem>
                    </FormLayoutGroup>
                    {deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.address ? <FormLayoutGroup >
                        <FormLayoutGroup mode="horizontal" segmented>
                            <FormItem >
                                <Input placeholder="Микрорайон / улица" value={street} onChange={(e) => setStreet(e.target.value)} />
                            </FormItem>
                            <FormItem >
                                <Input placeholder="№ дома" value={house} onChange={(e) => setHouse(e.target.value)} />
                            </FormItem>
                        </FormLayoutGroup>
                        {deliveryMethods.get().find(method => method.id === selectedDeliveryMethod.get())?.apartment ?
                            <FormItem>
                                <Input placeholder="№ квартиры / подъезда" value={apartment} onChange={(e) => setApartment(e.target.value)} />
                            </FormItem> : <></>}

                    </FormLayoutGroup> : <></>}


                    <FormItem top="Номер телефона для связи"
                        status={phoneNumberInputError ? 'error' : 'valid'}
                        bottom={phoneNumberInputError ? "Введите номер телефона в международном формате" : ""}
                    >
                        <Input
                            type="tel"
                            placeholder="Введите номер телефона"
                            value={new AsYouType('KZ').input(String(phoneNumber))} onChange={onChangePhoneNumber}
                            before={<img width={28} height={28} src={flag} alt="" />}
                        />
                    </FormItem>

                    <FormItem top="Комментарий к заказу" >
                        <Input placeholder="Введите комментарий" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </FormItem>

                    <Div style={{ marginBottom: '15%' }}>
                        <Button
                            onClick={() => newOrder()}
                            disabled={disabledButton()}
                            style={{ backgroundColor: "#F3294A" }}
                            size="l"
                            mode="primary"
                            stretched

                        >
                            Заказать
                        </Button>
                    </Div>
                </Group>
            </Panel>
        </View>
    )
}

export default CreateOrderView;