import React from 'react';
import { ModalPage } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, idAboutProduct } from "../storage/States";
import { Icon24Cancel } from "@vkontakte/icons";
import { getProductById } from "../utils/ActionsWithCart";
import { productPhotoUrl } from "../api";

const InfoAboutProduct = ({ id }: BaseProps) => {
    const getProduct = getProductById(idAboutProduct.get());


    return (
        <ModalPage
            id={id}
            onClose={() => activeModal.set('')}
        >
            <div className="MobileUIBottomSheet_inner MobileUIBottomSheet_innerShadow MobileUIBottomSheet_innerHideOverflow">

                <button className="DetailedMenuItemBottomSheet_close" aria-label="Закрыть" onClick={() => activeModal.set('')}>
                    <Icon24Cancel className="UiKitUiKitIcon_m UiKitUiKitIcon_root" />
                </button>

                <div className="ItemCover_imageWrapper">
                    <div className="SmartImage_root ItemCover_imageRoot">
                        <img src={productPhotoUrl + getProduct?.photo} alt=""
                            className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" />
                    </div>
                </div>
                <div>
                    <section className="UiKitBlockRounded_root MobileAddToCartOptions_blockRoundedWithoutPadding">
                        <div className="Badges_container">
                            <div className="UiKitToken_root UiKitToken_root-d8 UiKitToken_m">
                                <span className="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitText_root UiKitText_Body2 UiKitText_Text">{getProduct?.name}</span>
                                <span className="MenuItemCountEdit_weight">{getProduct?.quantity}</span>
                            </div>
                        </div>
                        <div className="DescriptionAndNutrients_descriptions">
                            <div>
                                <div className="UiKitProductCardDescriptions_collapseItem UiKitProductCardDescriptions_collapseItem-d3 UiKitProductCardDescriptions_smallCollapseItem">
                                    <h3 className="UiKitProductCardDescriptions_descriptionTitle">Состав</h3>
                                    <div className="UiKitProductCardDescriptions_descriptionText UiKitProductCardDescriptions_notExpanded UiKitProductCardDescriptions_descriptionTextShort">
                                        {getProduct?.structure}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="MobileUIBottomSheetFooter_root">
                    <div className="MenuItemCountEdit_root" >
                        <div className="MenuItemCountEdit_row MenuItemCountEdit_titleRow">
                            <div className="MenuItemCountEdit_price">
                                <span className="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitText_root UiKitText_Body2 UiKitText_Medium UiKitText_Text">{getProduct?.cost.toLocaleString('ru-RU') + " ₸"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="MenuItemCountEdit_row MenuItemCountEdit_counterWrapper">
                        <button onClick={() => activeModal.set('')} className="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MenuItemCountEdit_confirm MenuItemCountEdit_common">Понятно</button>
                    </div>
                </div>
            </div>
        </ModalPage >
    )
}

export default InfoAboutProduct;