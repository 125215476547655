import React from 'react';
import { Group, Panel, PanelHeader, PanelHeaderBack, View, Div, Banner } from "@vkontakte/vkui"
import BaseProps from "../props/BaseProps"
import { activeView } from "../storage/States"
import { Icon28FavoriteOutline, Icon28MarketOutline, Icon28FolderOutline, Icon28ChefHatOutline, Icon28MotorcycleOutline, Icon28StatisticsOutline, Icon28WarningTriangleOutline } from "@vkontakte/icons"

const AdminView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    delimiter="spacing"
                    before={<PanelHeaderBack onClick={() => activeView.set('homeView')} />}
                >
                    Админ-панель
                </PanelHeader>
                <Group>
                    <Div>
                        <Banner
                            before={<Icon28MarketOutline fill="#F3294A" />}
                            header="Заказы"
                            asideMode="expand"
                            onClick={() => activeView.set('ordersAdminPanelView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28FolderOutline fill="#F3294A" />}
                            header="Категории"
                            asideMode="expand"
                            onClick={() => activeView.set('categoriesView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28ChefHatOutline fill="#F3294A" />}
                            header="Блюда"
                            asideMode="expand"
                            onClick={() => activeView.set('productsView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28MotorcycleOutline fill="#F3294A" />}
                            header="Доставка"
                            asideMode="expand"
                            onClick={() => activeView.set('deliveryMethodsView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28FavoriteOutline fill="#F3294A" />}
                            header="Акции"
                            asideMode="expand"
                            onClick={() => activeView.set('promotionsView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28StatisticsOutline fill="#F3294A" />}
                            header="Статистика"
                            asideMode="expand"
                            onClick={() => activeView.set('statsView')}
                        />
                    </Div>
                    <Div>
                        <Banner
                            before={<Icon28WarningTriangleOutline fill="#F3294A" />}
                            header="Возможность приема заказов"
                            asideMode="expand"
                            onClick={() => activeView.set('reasonsToClosureView')}
                        />
                    </Div>
                </Group>
            </Panel>
        </View>
    )
}

export default AdminView;