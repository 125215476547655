import React from 'react';
import { Alert, Avatar, Button, ButtonGroup, Footer, Group, Header, RichCell, Skeleton } from "@vkontakte/vkui";
import RenderOrdersForAdminPanelProps from "../props/RenderOrdersForAdminPanelProps";
import { actionWithBonusesOrdersAdminPanel, activeTabOrdersAdminPanel, activeView, infoAboutOrderByIdAdmin, ordersForAdminPanel, popout } from "../storage/States";
import timeConverter from "../utils/TimeConverter";
import { Icon20InfoCircleOutline, Icon28MarketSlashOutline, Icon28ReceiptOutline } from "@vkontakte/icons";
import { AsYouType } from 'libphonenumber-js';
import { cancelOrder } from '../api/cancelOrder';

const getButtons = (
    orderId: number,
    status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED',
    methodOfPayment: 'CASH' | 'BANK_CARD',
    changeStatus: (orderId: number, status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => void
) => {

    if (status === 'AWAITING_CONFIRMATION') {
        if (methodOfPayment === 'BANK_CARD') return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <ButtonGroup mode="horizontal" gap="s" stretched>
                    <Button before={<Icon28MarketSlashOutline width={20} height={20} />} mode="outline" size="s" appearance="negative" onClick={() => openAction(orderId)}>
                        Отменить заказ
                    </Button>
                    <Button mode="outline" size="s" appearance="neutral" onClick={() => {
                        infoAboutOrderByIdAdmin.set(orderId);
                        activeView.set('infoAboutOrderAdminPanel')
                    }}>
                        <Icon20InfoCircleOutline />
                    </Button>
                </ButtonGroup>
            </ButtonGroup>
        )
        else return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <ButtonGroup mode="horizontal" gap="s" stretched>
                    <Button size="s" appearance="positive" onClick={() => changeStatus(orderId, 'CONFIRMED')}>
                        Подтвердить
                    </Button>
                    <Button mode="outline" size="s" appearance="negative" onClick={() => changeStatus(orderId, 'NOT_CONFIRMED')}>
                        Отменить
                    </Button>
                    <Button mode="outline" size="s" appearance="neutral" onClick={() => {
                        infoAboutOrderByIdAdmin.set(orderId);
                        activeView.set('infoAboutOrderAdminPanel')
                    }}>
                        <Icon20InfoCircleOutline />
                    </Button>
                </ButtonGroup>
            </ButtonGroup>
        )
    } else if (status === 'CONFIRMED') {
        if (methodOfPayment === 'BANK_CARD') return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <Button before={<Icon28MarketSlashOutline width={20} height={20} />} mode="outline" size="s" appearance="negative" onClick={() => openAction(orderId)}>
                    Отменить заказ
                </Button>
                <Button mode="outline" size="s" appearance="neutral" onClick={() => {
                    infoAboutOrderByIdAdmin.set(orderId);
                    activeView.set('infoAboutOrderAdminPanel')
                }}>
                    <Icon20InfoCircleOutline />
                </Button>
            </ButtonGroup>
        )
        else return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <ButtonGroup mode="horizontal" gap="s" stretched>
                    <Button mode="outline" size="s" appearance="negative" onClick={() => changeStatus(orderId, 'NOT_CONFIRMED')}>
                        Отменить
                    </Button>
                    <Button mode="outline" size="s" appearance="neutral" onClick={() => {
                        infoAboutOrderByIdAdmin.set(orderId);
                        activeView.set('infoAboutOrderAdminPanel')
                    }}>
                        <Icon20InfoCircleOutline />
                    </Button>
                </ButtonGroup>
            </ButtonGroup>
        )
    } else if (status === 'NOT_CONFIRMED') {
        if (methodOfPayment === 'BANK_CARD') return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <Button before={<Icon20InfoCircleOutline />} mode="outline" size="s" appearance="neutral" onClick={() => {
                    infoAboutOrderByIdAdmin.set(orderId);
                    activeView.set('infoAboutOrderAdminPanel')
                }}>
                    Информация о заказе
                </Button>
            </ButtonGroup>
        )
        else return (
            <ButtonGroup mode="horizontal" gap="s" stretched>
                <ButtonGroup mode="horizontal" gap="s" stretched>
                    <Button size="s" appearance="positive" onClick={() => changeStatus(orderId, 'CONFIRMED')}>
                        Подтвердить
                    </Button>
                    <Button mode="outline" size="s" appearance="neutral" onClick={() => {
                        infoAboutOrderByIdAdmin.set(orderId);
                        activeView.set('infoAboutOrderAdminPanel')
                    }}>
                        <Icon20InfoCircleOutline />
                    </Button>
                </ButtonGroup>
            </ButtonGroup>
        )
    }
}

const openAction = (orderId: number) => {
    popout.set(
        <Alert
            actions={[
                {
                    title: 'Отменить заказ',
                    mode: 'destructive',
                    action: () => cancelOrder(orderId),
                },
                {
                    title: 'Отмена',
                    mode: 'cancel',
                },
            ]}
            actionsLayout="vertical"
            onClose={() => popout.set(null)}
            header="Подтвердите действие"
            text="Вы уверены, что хотите отменить заказ?"
        />,
    );
};

const RenderOrdersForAdminPanel = ({ loading, changeStatus }: RenderOrdersForAdminPanelProps) => {
    if (!loading) {
        const orders = ordersForAdminPanel.get();

        const ordersForRender = [];

        for (let i = 0; i < orders.length; i++) {
            if (orders[i].status === activeTabOrdersAdminPanel.get()) {
                if (orders[i].actionWithBonuses === actionWithBonusesOrdersAdminPanel.get()) {
                    ordersForRender.push(
                        <Group key={"order_for_admin_panel" + Math.random()} header={<Header mode="secondary">{timeConverter(orders[i].created_at)}</Header>}>
                            <RichCell
                                subhead={orders[i].deliveryMethod.name}
                                before={<Avatar size={48}><Icon28ReceiptOutline fill="#F3294A" /></Avatar>}
                                after={(orders[i].total - (orders[i].actionWithBonuses === 'PAY' ? orders[i].bonuses : 0)).toLocaleString("ru-RU") + " ₸"}
                                afterCaption={orders[i].methodOfPayment === 'BANK_CARD' ? 'Банковская карта' : 'Наличные'}
                                text={orders[i].user}
                                caption={new AsYouType('KZ').input(String(orders[i].phoneNumber))}
                                actions={getButtons(orders[i].id, orders[i].status, orders[i].methodOfPayment, changeStatus)}
                                multiline
                            >
                                Заказ №{orders[i].id}
                            </RichCell>
                        </Group>
                    );
                }
            }
        }
        return <>{ordersForRender.length === 0 ? <Footer>Заказов не найдено</Footer> : ordersForRender}</>
    } else {
        const ordersForRender = [];

        for (let i = 0; i < 10; i++) {
            ordersForRender.push(
                <Group key={"order_for_admin_panel" + Math.random()} header={<Header mode="secondary"><Skeleton width={170} height={20} /></Header>}>
                    <RichCell
                        before={<Skeleton width={48} height={48} borderRadius="50%" />}
                        after={<Skeleton width={80} />}
                        afterCaption={<Skeleton width={50} />}
                        caption={<Skeleton width={120} />}
                        actions={
                            <ButtonGroup mode="horizontal" gap="s" stretched>

                                <Skeleton width={100} height={28} />

                                <Skeleton width={100} height={28} />
                            </ButtonGroup>
                        }
                        multiline
                    >
                        <Skeleton width={90} />
                    </RichCell>
                </Group>
            )
        }

        return <>{ordersForRender}</>
    }
}

export default RenderOrdersForAdminPanel;