import React from 'react';
import { View, Panel, PanelHeader, PanelHeaderBack, Banner, Button, Div, Group, PanelHeaderButton, Alert, ScreenSpinner, Snackbar, Footer } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, activeTab, activeView, editableCategoryId, popout, snackbar, tabs } from "../storage/States";
import { Icon20BrushOutline, Icon28AddOutline, Icon28CheckCircleOutline, Icon28SortOutline } from "@vkontakte/icons";

import categoryBackground from '../lib/img/categoryBackground.jpg';
import RequestToApi from "../api";


const deleteCategoryAlert = (id: number) => {
    popout.set(
        <Alert
            actions={[
                {
                    title: 'Удалить',
                    mode: 'destructive',
                    action: () => deleteCategory(id)
                },
                {
                    title: 'Отмена',
                    mode: 'cancel',
                },
            ]}
            actionsLayout="horizontal"
            onClose={() => popout.set(null)}
            header="Подтвердите действие"
            text="Вы уверены, что хотите удалить категорию и находящиеся в ней позиции?"
        />
    )
}

const deleteCategory = (id: number) => {
    const token = localStorage.getItem('access_token');
    popout.set(<ScreenSpinner />);

    RequestToApi({ method: 'delete', url: ('products/category/' + id), token: token }, (data) => {
        RequestToApi({ method: 'get', url: 'products' }, (res) => {
            tabs.set(res.length !== 0 ? res : []);

            if (res.length !== 0) {
                activeTab.set(res[0].id);
            } else {
                activeTab.set(1);
            }

            popout.set(null);

            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                >
                    Вы успешно удалили категорию
                </Snackbar>
            )
        }, (e) => popout.set(null));
    }, (e) => popout.set(null));
}

const RenderCategories = () => {
    if (tabs.get().length !== 0) {
        const tab = tabs.get();
        const categories = [];

        for (let i = 0; i < tab.length; i++) {
            categories.push(
                <Div key={"category_in_admin_panel" + i}>
                    <Banner
                        mode="image"
                        size="m"
                        header={
                            <span>
                                {tab[i].name}
                            </span>
                        }
                        subheader={"Блюд · " + tab[i].products.length}
                        background={
                            <div
                                style={{
                                    backgroundColor: '#000',
                                    backgroundPositionY: '61%',
                                    backgroundImage:
                                        `url(${categoryBackground})`,

                                    backgroundSize: '100%',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        }
                        asideMode="dismiss"
                        onDismiss={() => deleteCategoryAlert(tab[i].id)}
                        actions={
                            <Button
                                onClick={() => {
                                    editableCategoryId.set(tab[i].id);
                                    activeModal.set('editCategory');
                                }}
                                appearance="overlay"
                                size="m"
                                mode="primary"
                                before={<Icon20BrushOutline width={20} height={20} />}
                            >
                                Редактировать
                            </Button>
                        }
                    />
                </Div>
            );
        }

        return <>{categories}</>

    } else return <Footer>Ничего не найдено</Footer>
}

const CategoriesView = ({ id }: BaseProps) => {

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={
                        <React.Fragment>
                            <PanelHeaderButton onClick={() => activeModal.set('addNewCategory')}><Icon28AddOutline /></PanelHeaderButton>
                            <PanelHeaderButton onClick={() => activeView.set('sortCategoriesView')}><Icon28SortOutline /></PanelHeaderButton>
                        </React.Fragment>
                    }
                >
                    Категории
                </PanelHeader>

                <Group>
                    <RenderCategories />
                </Group>
            </Panel>
        </View>
    )
}

export default CategoriesView;