import React from 'react';
import { SplitLayout, Root, ModalRoot } from '@vkontakte/vkui';

import { activeView, snackbar, activeModal, popout } from './storage/States';

import LoadingView from './views/LoadingView';
import HomeView from './views/HomeView';
import ErrorView from './views/ErrorView';

import CartIsEmptyModal from './modals/CartIsEmptyModal';
import InfoAboutRestaurantModal from './modals/InfoAboutRestaurantModal';
import AuthorizationModal from './modals/AuthorizationModal';
import SignInModal from './modals/SignInModal';
import NewOrder from './modals/NewOrderModal';
import ChooseDeliveryMethod from './modals/ChooseDeliveryMethod';
import OrderHasBeenCreated from './modals/OrderHasBeenCreated';
import AdminView from './views/AdminView';
import EmailIsActivated from './modals/EmailIsActivatedModal';
import NecessaryToConfirmEmail from './modals/NecessaryToConfirmEmailModal';
import SignUpView from './views/SignUpView';
import ResetPassword from './modals/ResetPasswordModal';
import ResetPasswordByToken from './modals/ResetPassswordByToken';
import ChangeNameModal from './modals/ChangeNameModal';
import ChangePhoneNumberModal from './modals/ChangePhoneNumberModal';
import NecessaryToConfirmAndEditEmail from './modals/NecessaryToConfirmAndEditEmailModal';
import ChangeEmail from './modals/ChangeEmailModal';
import MyOrdersView from './views/MyOrdersView';
import ChangePassword from './modals/ChangePasswordModal';
import OrdersAdminPanelView from './views/OrdersAdminPanelView';
import CategoriesView from './views/CategoriesView';
import AddNewCategory from './modals/AddNewCategoryModal';
import EditCategory from './modals/EditCategoryModal';
import ProductsView from './views/ProductsView';
import AddProductView from './views/AddProductView';
import EditProductView from './views/EditProductView';
import DeliveryMethodsView from './views/DeliveryMethodsView';
import EditDeliveryMethod from './modals/EditDeliveryMethodModal';
import AddNewDeliveryMethod from './modals/AddNewDeliveryMethod';
import PromotionsView from './views/PromotionsView';
import Promotions from './modals/PromotionsModal';
import AddNewPromotion from './modals/AddNewPromotion';
import EditPromotion from './modals/EditPromotionModal';
import InfoAboutProduct from './modals/InfoAboutProduct';
import StatsView from './views/StatsView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import PaymentTermsView from './views/PaymentTermsView';
import OfferView from './views/OfferView';
import DeliveryTermsView from './views/DeliveryTermsView';
import AboutUs from './modals/AboutUsModal';
import InfoAboutOrderView from './views/InfoAboutOrderView';
import OrderCannotBeCancelled from './modals/OrderCannotBeCancelledModal';
import InfoAboutOrderAdminPanelView from './views/InfoAboutOrderAdminPanelView';
import CreateOrderView from './views/CreateOrderView';
import WorkShedule from './modals/WorkScheduleModal';
import ReasonForClosure from './modals/ReasonForClosureModal';
import ReasonsToClosureView from './views/ReasonsToClosureView';
import AddReasonToClosure from './modals/AddReasonToClosureModal';
import EditReasonToClosure from './modals/EditReasonToClosureModal';
import AvailabilityOfTheDishView from './views/AvailabilityOfTheDishView';
import SortCategoriesView from './views/SortCategoriesView';
import AnonimOrderView from './views/AnonimOrderView';
import InfoAboutOrderById from './modals/InfoAboutOrderByIdModal';
import InfoAboutOrderByIdView from './views/InfoAboutOrderByIdView';

const App = () => {
  const modals = (
    <ModalRoot activeModal={activeModal.get()} onClose={() => activeModal.set('')}>
      <CartIsEmptyModal id="cartIsEmpty" />
      <InfoAboutRestaurantModal id="infoAboutRestaurant" />
      <AuthorizationModal id="authorization"/>
      <SignInModal id="signIn" />
      <NewOrder id="newOrder" />
      <ChooseDeliveryMethod id="chooseDeliveryMethod" />
      <OrderHasBeenCreated id="orderHasBeenCreated" />
      <EmailIsActivated id="emailIsActivated" />
      <NecessaryToConfirmEmail id="necessaryToConfirmEmail" />
      <NecessaryToConfirmAndEditEmail id="necessaryToConfirmAndEditEmail" />
      <ResetPassword id="resetPassword" />
      <ResetPasswordByToken id="resetPasswordByToken" />
      <InfoAboutProduct id="infoAboutProduct" />
      <AboutUs id="aboutUs"/>
      <OrderCannotBeCancelled id="orderCannotBeCancelled" />
      <WorkShedule id="workSchedule" />
      <ReasonForClosure id="reasonForClosure" />
      <AddReasonToClosure id="addReasonToClosure" />
      <EditReasonToClosure id="editReasonToClosure" />

      <ChangeNameModal id="changeName" />
      <ChangePhoneNumberModal id="changePhoneNumber" />
      <ChangeEmail id="changeEmail" />
      <ChangePassword id="changePassword" />

      <AddNewCategory id="addNewCategory" />
      <EditCategory id="editCategory" />
      <EditDeliveryMethod id="editDeliveryMethod" />
      <AddNewDeliveryMethod id="addDeliveryMethod" />
      <Promotions id="promotions" />
      <AddNewPromotion id="addPromotion" /> 
      <EditPromotion id="editPromotion" />
      <InfoAboutOrderById id="infoAboutOrderById" />
    </ModalRoot>
  );


  return (
    <SplitLayout modal={modals} popout={popout.get()} aria-live="polite" aria-busy={!!popout.get()}>
      <Root activeView={activeView.get()} >
        <LoadingView id='loadingView' />
        <ErrorView id='errorView' />
        <HomeView id='homeView' />
        <SignUpView id="signUpView" />
        <AdminView id="adminView" />
        <MyOrdersView id="myOrdersView" />
        <InfoAboutOrderView id="infoAboutOrder" />
        <CreateOrderView id="createOrderView" />
        <AnonimOrderView id="anonimOrderView" />
        <ReasonsToClosureView id="reasonsToClosureView" />
        
       

        <OrdersAdminPanelView id="ordersAdminPanelView" />
        <InfoAboutOrderAdminPanelView id="infoAboutOrderAdminPanel" />
        <InfoAboutOrderByIdView id="infoAboutOrderByIdView" />
        <CategoriesView id="categoriesView" />
        <SortCategoriesView id="sortCategoriesView" />
        <ProductsView id="productsView" />
        <AvailabilityOfTheDishView id="availabilityOfTheDishView" />
        <AddProductView id="addProductView" />
        <EditProductView id="editProductView" />
        <DeliveryMethodsView id="deliveryMethodsView" />
        <PromotionsView id="promotionsView" />
        <StatsView id="statsView" />
        <PrivacyPolicyView id="privacyPolicyView" />
        <PaymentTermsView id="paymentTermsView" />
        <DeliveryTermsView id="deliveryTerms" />
        <OfferView id="offerView"/>
      </Root>
      {snackbar.get()}
    </SplitLayout>
  )
}

export default App;
