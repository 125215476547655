const TabletOrDesktop = () => {
    return (
        <div id="notfound">
            <div className="notfound">
                <div>
                    <div className="notfound-404">
                    </div>
                    <h2>УПС....</h2>
                    <p>Сайт пока нельзя открыть на компьютере или планшете, но вы можете воспользоваться своим смартфоном.</p>
                </div>
            </div>
        </div>
    )
}

export default TabletOrDesktop;