import { Icon20ClockOutline, Icon20CheckCircleOutline, Icon20CancelCircleOutline, Icon28CopyOutline, Icon28MarketOutline, Icon28MarketSlashOutline, Icon36CoinsStacks3Outline, Icon28CheckCircleOutline, Icon28ErrorCircleOutline, Icon28PaymentCardOutline, Icon28MoneyWadOutline } from "@vkontakte/icons";
import { Alert, Avatar, Button, ButtonGroup, Div, Gradient, Group, Header, IconButton, InfoRow, Panel, PanelHeader, PanelHeaderBack, Placeholder, ScreenSpinner, Separator, SimpleCell, Snackbar, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, errorMessage, infoAboutOrderByIdObject, orderByDate, ordersForAdminPanel, popout, snackbar } from "../storage/States";
import { AsYouType } from "libphonenumber-js";
import { getReasonMessage } from "../utils/getReasonTextForCancellation";
import RequestToApi, { instance } from "../api";

const InfoAboutOrderByIdView = ({ id }: BaseProps) => {
    const order = infoAboutOrderByIdObject.get();

    const getStatusIcon = (status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') return <Icon20ClockOutline fill="var(--vkui--color_icon_secondary)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'CONFIRMED') return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'NOT_CONFIRMED') return <Icon20CancelCircleOutline fill="var(--vkui--color_icon_negative)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
    }

    const openAction = (orderId: number) => {
        popout.set(
            <Alert
                actions={[
                    {
                        title: 'Отменить заказ',
                        mode: 'destructive',
                        action: () => cancelOrderById(orderId),
                    },
                    {
                        title: 'Отмена',
                        mode: 'cancel',
                    },
                ]}
                actionsLayout="vertical"
                onClose={() => popout.set(null)}
                header="Подтвердите действие"
                text="Вы уверены, что хотите отменить заказ?"
            />,
        );
    };

    const cancelOrderById = (id: number) => {

        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'delete', url: 'billing/cancel/' + id, token: localStorage.getItem('access_token') }, (res) => {
            RequestToApi({ method: 'get', url: 'order/' + id, token: localStorage.getItem('access_token') }, (response) => {

                infoAboutOrderByIdObject.set(response);
                popout.set(null);

                snackbar.set(

                    <Snackbar
                        mode="dark"
                        onClose={() => snackbar.set(null)}
                        before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                    >
                        {res.message}
                    </Snackbar>

                );
            }, () => popout.set(null));
        }, () => popout.set(null));

    }
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('ordersAdminPanelView')} />}
                >
                    Информация о заказе
                </PanelHeader>
                <Group>
                    <Gradient mode="tint" to="top">
                        <Placeholder
                            icon={<Avatar size={96} style={{ backgroundColor: "#F3294A" }}><Icon28MarketOutline fill="#ffffff" width={56} height={56} /></Avatar>}
                            header={<>Заказ №{order!.id} {' '} {getStatusIcon(order!.status)}</>}
                            action={
                                order!.actionWithBonuses === 'GAIN' ? <Button before={<Icon36CoinsStacks3Outline width={20} height={20} />} mode="outline" size="m" appearance="neutral" disabled>
                                    {" + " + order!.bonuses.toLocaleString("ru-RU") + " Б"}
                                </Button> : <></>
                            }

                        >
                            {order!.deliveryMethod.name}
                            <br />
                            <br />
                            {order!.status === 'NOT_CONFIRMED' ? <>Причина: <br /> {getReasonMessage(order!.reasonForCancellation)}</> : <></>}
                        </Placeholder>
                    </Gradient>
                    {order!.status === 'AWAITING_CONFIRMATION' || order!.status === 'CONFIRMED' ? <Div>
                        <ButtonGroup mode="horizontal" gap="s" stretched>
                            <Button before={<Icon28MarketSlashOutline />} mode="outline" size="l" appearance="negative" stretched onClick={() => openAction(order!.id)}>
                                Отменить
                            </Button>
                        </ButtonGroup>
                    </Div> : <></>}
                    <Group mode="plain" header={<Header>Способ оплаты</Header>}>
                        {order!.methodOfPayment === 'BANK_CARD' ?
                            <SimpleCell before={<Icon28PaymentCardOutline />} indicator={order!.creditCardFirst1.length > 0 ? order!.creditCardFirst1 + "****" + order!.creditCardLast4 : ""}>Банковская карта</SimpleCell> :
                            <SimpleCell before={<Icon28MoneyWadOutline />}>Наличный расчёт</SimpleCell>
                        }
                    </Group>
                    <Group mode="plain" header={<Header >Информация о заказе</Header>}>
                        <SimpleCell multiline>
                            <InfoRow header="Клиент">{order!.user}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline>
                            <InfoRow header="Номер телефона">{new AsYouType('KZ').input(String(order!.phoneNumber))}</InfoRow>
                        </SimpleCell>
                        {order!.deliveryAddress.length > 0 ? <SimpleCell multiline>
                            <InfoRow header="Адрес доставки">{order!.deliveryAddress}</InfoRow>
                        </SimpleCell> : <></>}
                        {order!.comment.length > 0 ? <SimpleCell multiline>
                            <InfoRow header="Комментарий к заказу">{order!.comment}</InfoRow>
                        </SimpleCell> : <></>}
                    </Group>

                    <Group mode="plain" header={<Header>Заказ: </Header>}>
                        <Div style={{ marginTop: 0, paddingTop: 0 }}>
                            {order!.products.map((product, index) => (
                                <div key={"order" + index + "_" + Math.random()}>
                                    <SimpleCell before={index + 1}
                                        indicator={(product.cost * product.count).toLocaleString("ru-RU") + "₸"}
                                        subtitle={product.count + " шт. × " + product.cost.toLocaleString('ru-RU') + "₸"}>
                                        {product.name}
                                    </SimpleCell>
                                    {index + 1 < order!.products.length ? <><Separator /></> : <></>}
                                </div>
                            ))}
                            <Separator style={{ color: '#000' }} />
                            {order!.actionWithBonuses === 'PAY' || order!.deliveryMethod.cost > 0 ? <SimpleCell

                                indicator={order!.summary.toLocaleString('ru-RU') + " ₸"}
                            >
                                Подытог
                            </SimpleCell> : <></>}
                            {order!.actionWithBonuses === 'PAY' ? <SimpleCell

                                indicator={"- " + order!.bonuses.toLocaleString('ru-RU') + " Б"}
                            >
                                Бонусы
                            </SimpleCell> : <></>}
                            {order!.deliveryMethod.cost > 0 ? <SimpleCell

                                subtitle={order!.deliveryMethod.name}
                                indicator={"+ " + order!.deliveryMethod.cost.toLocaleString("ru-RU") + " ₸"}
                            >
                                Доставка
                            </SimpleCell> : <></>}
                            <Separator />
                            <SimpleCell

                                indicator={order!.amountToBePaid.toLocaleString("ru-RU") + " ₸"}><b>Итого к оплате</b></SimpleCell>
                        </Div>
                    </Group>
                    {order!.status !== 'AWAITING_CONFIRMATION' && order!.methodOfPayment === 'BANK_CARD' ? <Group mode="plain" header={<Header >Информация о транзакции</Header>}>
                        {order!.creditCardFirst1.length > 0 ?
                            <SimpleCell multiline>
                                <InfoRow header="Банковская карта">{order!.creditCardFirst1 + '****' + order!.creditCardLast4}</InfoRow>
                            </SimpleCell> : <></>}
                        <SimpleCell multiline>
                            <InfoRow header="Сумма к оплате">{order!.amountToBePaid.toLocaleString("ru-RU") + " ₸"}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline after={order!.transactionUid.length > 0 ?
                            <IconButton label="Скопировать" onClick={() => navigator.clipboard.writeText(order!.transactionUid)}>
                                <Icon28CopyOutline />
                            </IconButton> : <></>
                        }>
                            <InfoRow header="Номер транзакции">{order!.transactionUid.length > 0 ? order!.transactionUid : 'Отсутствует'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline after={order!.rrn.length > 0 ?
                            <IconButton label="Скопировать" onClick={() => navigator.clipboard.writeText(order!.rrn)}>
                                <Icon28CopyOutline />
                            </IconButton> : <></>
                        }>
                            <InfoRow header="RRN">{order!.rrn.length > 0 ? order!.rrn : 'Отсутствует'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline after={order!.refId.length > 0 ?
                            <IconButton label="Скопировать" onClick={() => navigator.clipboard.writeText(order!.refId)}>
                                <Icon28CopyOutline />
                            </IconButton> : <></>
                        }>
                            <InfoRow header="REF ID">{order!.refId.length > 0 ? order!.refId : 'Отсутствует'}</InfoRow>
                        </SimpleCell>
                        <SimpleCell multiline after={order!.payUrl.length > 0 ?
                            <IconButton label="Скопировать" onClick={() => navigator.clipboard.writeText(order!.payUrl)}>
                                <Icon28CopyOutline />
                            </IconButton> : <></>
                        }>
                            <InfoRow header="Токен платежа">{order!.payUrl.length > 0 ? order!.payUrl : 'Отсутствует'}</InfoRow>
                        </SimpleCell>
                    </Group> : <></>}
                </Group>
            </Panel>
        </View>
    )

}

export default InfoAboutOrderByIdView;