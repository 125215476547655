interface ReasonsForCancellationType {
    id: string;
    message: string;
}

const ReasonsForCancellation: ReasonsForCancellationType[] = [
    {
        id: 'CANCELLATION_BY_THE_CLIENT',
        message: 'Заказ отменён по просьбе клиента'
    },
    {
        id: 'ORDER_HAS_BEEN_NOT_PAID',
        message: 'Заказ отменён, так как не была произведена оплата'
    },
    {
        id: 'CANCELLATION_BY_THE_ADMINISTRATOR',
        message: 'Заказ отменён администратором'
    }
];

export const getReasonMessage = (id: string) => {
    const reason = ReasonsForCancellation.find(r => r.id === id);

    return reason?.message;
}