import React from 'react';
import { ModalPage, Subhead } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal } from "../storage/States";
import { Icon24Cancel } from "@vkontakte/icons";

import background from '../lib/img/background.png';

const AboutUs = ({ id }: BaseProps) => {

    return (
        <ModalPage
            id={id}
            onClose={() => activeModal.set('')}
        >
            <div className="MobileUIBottomSheet_inner MobileUIBottomSheet_innerShadow MobileUIBottomSheet_innerHideOverflow">

                <button className="DetailedMenuItemBottomSheet_close" aria-label="Закрыть" onClick={() => activeModal.set('')}>
                    <Icon24Cancel className="UiKitUiKitIcon_m UiKitUiKitIcon_root" />
                </button>

                <div className="ItemCover_imageWrapper">
                    <div className="SmartImage_root ItemCover_imageRoot">
                        <img src={background} alt=""
                            id="aboutUs" className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg " />
                    </div>
                </div>
                <div>
                    <section className="UiKitBlockRounded_root MobileAddToCartOptions_blockRoundedWithoutPadding">
                        <div className="Badges_container">
                            <div className="UiKitToken_root UiKitToken_root-d8 UiKitToken_m">
                                <span className="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitText_root UiKitText_Body2 UiKitText_Text">Фудзияма</span>
                                <span className="MenuItemCountEdit_weight">{'Делаем жизнь вкуснее :)'}</span>
                            </div>
                        </div>
                        <div className="DescriptionAndNutrients_descriptions">
                            <div>
                                <div className="UiKitProductCardDescriptions_collapseItem UiKitProductCardDescriptions_collapseItem-d3 UiKitProductCardDescriptions_smallCollapseItem">
                                    <h3 className="UiKitProductCardDescriptions_descriptionTitle">О Нас</h3>
                                    <div className="UiKitProductCardDescriptions_descriptionText UiKitProductCardDescriptions_notExpanded UiKitProductCardDescriptions_descriptionTextShort">
                                        <Subhead>
                                            Фудзияма - это уютное заведение, открывшее свои двери для клиентов в 2018 году. Мы специализируемся на приготовлении различных блюд, таких как роллы и суши, а также предлагаем предлагаем широкий ассортимент пиццы и других вкусностей.
                                            Наша команда использует только свежие и качественные ингридиенты, чтобы гарантировать отменный вкус и высокое качество каждого блюда. Мы стремимся создать комфортную атмосферу для наших клиентов, поэтому мы подходим как для быстрого перекуса, так и для приятного времяпревождения с друзьями и семьёй.
                                        </Subhead>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </ModalPage >
    )
}

export default AboutUs;