import React from 'react';
import BaseProps from "../props/BaseProps";

import { View, Panel, Placeholder, Button } from '@vkontakte/vkui';

import { Icon28CancelCircleFillRed } from '@vkontakte/icons';

import { errorMessage } from '../storage/States';


const ErrorView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id} centered>
                <div>
                    <Placeholder
                        icon={<Icon28CancelCircleFillRed width={96} height={96} />}
                        action={
                            <Button size="m" mode="secondary" onClick={() => window.location.reload()}>
                                Повторить попытку
                            </Button>
                        }
                        stretched
                    >
                        {errorMessage.get() ? errorMessage.get() : "Произошли непредвиденные сложности"}
                    </Placeholder>
                </div>
            </Panel>
        </View>
    )
}

export default ErrorView;