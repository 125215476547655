import React from 'react';
import { Icon28FavoriteCircleFillGreen } from "@vkontakte/icons";
import { SimpleCell, Badge, Div, Banner } from "@vkontakte/vkui";
import { activeModal, promotions } from "../storage/States";

const RenderPromotions = () => {
    const getPromotions = promotions.get();

    if (getPromotions.length === 0) return <></>;
    else if (getPromotions.length === 1) {
        return (
            <Div>
                <Banner
                    key={getPromotions[0].id}
                    before={<Icon28FavoriteCircleFillGreen />}
                    header="Акция"
                    subheader={getPromotions[0].text}
                />
            </Div>
        )
    } else {
        return (
            <Div>
                <SimpleCell
                    onClick={() => activeModal.set('promotions')}
                    expandable="auto"
                    indicator={<Badge style={{ backgroundColor: '#4BB34B' }} />}
                    before={<Icon28FavoriteCircleFillGreen />}
                >
                    Акции
                </SimpleCell>
            </Div>
        )
    }
}

export default RenderPromotions