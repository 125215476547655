import { Group, Header, Panel, PanelHeader, PanelHeaderBack, RichCell, View, Button, Placeholder, Skeleton, Cell, Snackbar, ScreenSpinner, Avatar, ButtonGroup, PanelHeaderButton } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeStory, activeView, infoAboutOrderById, myOrders, popout, snackbar } from "../storage/States";
import React from "react";
import RequestToApi from "../api";
import timeConverter from "../utils/TimeConverter";
import { addProductsInCart, getProductById } from "../utils/ActionsWithCart";
import { Icon20PaymentCardOutline, Icon20CancelCircleOutline, Icon20ClockOutline, Icon20CheckCircleOutline, Icon20InfoCircleOutline, Icon36GhostSimpleOutline, Icon28MarketOutline, Icon28ErrorCircleOutline, Icon28ReplayOutline } from "@vkontakte/icons";
import ProductInOrderType from "../types/ProductInOrderType";
import { getCategoriesAndProductsWithCallback } from "../api/products";
import OrderType from "../types/OrderType";

const RenderMyOrders = () => {

    const infoAboutOrder = (id: number) => {
        infoAboutOrderById.set(id);
        activeView.set('infoAboutOrder');
    }

    const payOrder = (order: OrderType) => {
        if (order.payUrl.length > 0) window.open("https://securepayments.fortebank.com/widget/hpp.html?token=" + order!.payUrl, '_blank', '');
        else snackbar.set(
            <Snackbar
                mode="dark"
                onClose={() => snackbar.set(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
            >
                Произошла ошибка при оплате
            </Snackbar>
        )
    }



    const getStatusIcon = (methodOfPayment: 'CASH' | 'BANK_CARD', status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') {
            if(methodOfPayment === 'BANK_CARD') return <Icon20ClockOutline fill="var(--vkui--color_icon_secondary)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
            else return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        }
        if (status === 'CONFIRMED') return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'NOT_CONFIRMED') return <Icon20CancelCircleOutline fill="var(--vkui--color_icon_negative)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
    }

    const orders = myOrders.get();

    if (orders.length !== 0) {
        const order = [];

        for (let i = 0; i < orders.length; i++) {
            order.push(
                <div key={"order" + i + "_" + Math.random()}>
                    <Group key={"order" + i + "_" + Math.random()} header={
                        <Header
                            key={"order" + i + "_" + Math.random()}
                            mode="secondary"
                        >
                            {timeConverter(orders[i].created_at)}
                        </Header>
                    }>
                        <RichCell
                            key={"order" + i + "_" + Math.random()}
                            before={<Avatar size={48} style={{ backgroundColor: "#F3294A" }}><Icon28MarketOutline fill="#ffffff" /></Avatar>}
                            text={orders[i].deliveryMethod.name}
                            caption={orders[i].actionWithBonuses === "PAY" ? "К оплате: " + orders[i].amountToBePaid.toLocaleString("ru-RU") + "₸" : ""}
                            after={orders[i].total.toLocaleString("ru-RU") + "₸"}

                            afterCaption={(orders[i].actionWithBonuses === "GAIN" ? "+ " : "-") + orders[i].bonuses.toLocaleString("ru-RU") + " Б"}
                            multiline
                            actions={
                                orders[i].status === 'AWAITING_CONFIRMATION' && orders[i].methodOfPayment === 'BANK_CARD' ?
                                    <ButtonGroup mode="horizontal" gap="s" stretched>
                                        <Button before={<Icon20PaymentCardOutline />} mode="outline" size="s" appearance="neutral" onClick={() => payOrder(orders[i])}>
                                            Оплатить заказ
                                        </Button>
                                        <Button mode="outline" size="s" appearance="neutral" onClick={() => infoAboutOrder(orders[i].id)}>
                                            <Icon20InfoCircleOutline />
                                        </Button>
                                    </ButtonGroup> : <ButtonGroup mode="horizontal" gap="s" stretched>
                                        <Button before={<Icon20InfoCircleOutline />} mode="outline" size="s" appearance="neutral" onClick={() => infoAboutOrder(orders[i].id)}>
                                            Информация о заказе
                                        </Button>
                                    </ButtonGroup>
                            }
                        >

                            Заказ № {orders[i].id} {' '} {getStatusIcon(orders[i].methodOfPayment, orders[i].status)}
                        </RichCell>
                    </Group>
                </div>
            );
        }

        return <>{order}</>
    } else {
        return (
            <Placeholder
                icon={<Icon36GhostSimpleOutline width={96} height={96} fill="#F3294A" />}
                action={
                    <Button size="m" mode="outline" appearance="negative" onClick={() => { activeView.set('homeView'); activeStory.set('menu') }}>
                        Сделать заказ
                    </Button>
                }
                stretched
            >
                У вас пока нет заказов
            </Placeholder>
        );
    }
};

const RenderLoadingOrders = () => {
    const loading = [];

    for (let i = 0; i < 10; i++) {
        loading.push(
            <Group
                key={'loading_' + Math.random()}
                header={
                    <Header
                        mode="secondary"
                        aside={<Skeleton width={90} height={20} />}
                    >
                        <Skeleton width={170} height={20} />
                    </Header>
                }>

                <Cell><Skeleton width={200} height={20} /></Cell>

            </Group>
        )
    }

    return <>{loading}</>
}

const MyOrdersView = ({ id }: BaseProps) => {
    const [loading, SetLoading] = React.useState(false);

    React.useEffect(() => {
        if (myOrders.get().length === 0) {
            SetLoading(true);
            RequestToApi({ method: 'get', url: 'user/orders', token: localStorage.getItem("access_token") }, (response) => {
                myOrders.set(response);
                setTimeout(() => SetLoading(false), 2000);
            });
        }
    }, [])

    const getMyOrders = () => {
        SetLoading(true);
        RequestToApi({ method: 'get', url: 'user/orders', token: localStorage.getItem("access_token") }, (response) => {
            myOrders.set(response);
            setTimeout(() => SetLoading(false), 2000);
        });
    }


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('homeView')} />}
                    after={<PanelHeaderButton onClick={() => getMyOrders()}><Icon28ReplayOutline /></PanelHeaderButton>}
                >
                    Мои заказы
                </PanelHeader>

                {loading ? <RenderLoadingOrders /> : <RenderMyOrders />}

            </Panel>
        </View>
    )
}

export default MyOrdersView;