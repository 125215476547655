import { Tabbar, Epic, TabbarItem, Panel, View, Badge } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { useMediaQuery } from "react-responsive";

import { Icon28HomeOutline, Icon28ShoppingCartOutline, Icon56CoinsStacks3Outline, Icon28Profile, Icon28WrenchOutline, Icon28DoorArrowLeftOutline } from "@vkontakte/icons";
import MenuView from "./MenuView";

import { activeStory, activeModal, userData, newCart, activeView } from "../storage/States";
import CartView from "./CartView";
import ProfileView from "./ProfileView";
import BalanceView from "./BalanceView";
import { GoToCart } from "../utils/GoToCart";



const story = [
    {
        id: 'menu',
        name: () => 'Главная',
        icon: () => <Icon28HomeOutline />,
        indicator: () => <></>,
        onClick: (e: any) => activeStory.set(e.currentTarget.dataset.story),
        isActive: () => true
    },
    {
        id: 'cart',
        name: () => 'Корзина',
        icon: () => <Icon28ShoppingCartOutline />,
        indicator: () => newCart.get().length === 0 ? <></> : <Badge mode="prominent">{newCart.get().length < 100 ? newCart.get().length : "99+"}</Badge>,
        onClick: (e: any) => newCart.get().length !== 0 ? GoToCart() : activeModal.set("cartIsEmpty"),
        isActive: () => true
    },
    {
        id: 'profile',
        name: () => userData.get() !== null ? 'Профиль' : 'Войти в аккаунт',
        icon: () => userData.get() !== null ? <Icon28Profile /> : <Icon28DoorArrowLeftOutline />,
        indicator: () => <></>,
        onClick: (e: any) => userData.get() !== null ? activeStory.set(e.currentTarget.dataset.story) : activeModal.set("authorization"),
        isActive: () => true
    },
    {
        id: 'balance',
        name: () => 'Баланс',
        icon: () => <Icon56CoinsStacks3Outline width={28} height={28} />,
        indicator: () => <></>,
        onClick: (e: any) => userData.get() !== null ? activeStory.set(e.currentTarget.dataset.story) : activeModal.set("authorization"),
        isActive: () => userData.get() !== null ? true : false
    },
    {
        id: 'admin',
        name: () => 'Админ',
        icon: () => <Icon28WrenchOutline />,
        indicator: () => <></>,
        onClick: (e: any) => userData.get() !== null ? userData.get()!.role === 'ADMIN' ? activeView.set('adminView') : null : null,
        isActive: () => userData.get() !== null ? userData.get()!.role === 'ADMIN' ? true : false : false
    }
];

const HomeView = ({ id }: BaseProps) => {
    const EpicStyle = {
        marginTop: '8.5%',
        marginBottom: '2%'
    }

    const isTabletOrDesktop = useMediaQuery({
        query: "(min-width: 900px)"
    });

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <Epic activeStory={activeStory.get()} style={isTabletOrDesktop ? (EpicStyle) : undefined}
                    tabbar={!isTabletOrDesktop && (
                        <Tabbar>
                            {story.map(tab => (tab.isActive() !== false ?
                                <TabbarItem
                                    indicator={tab.indicator()}
                                    onClick={tab.onClick}
                                    selected={activeStory.get() === tab.id}
                                    data-story={tab.id}
                                    text={tab.name()}
                                    key={tab.id + "epic"}
                                >
                                    {tab.icon()}
                                </TabbarItem> : <div key={tab.id + "epic"}></div>
                            ))}
                        </Tabbar>
                    )}>
                    <MenuView id="menu" />
                    <CartView id="cart" />
                    <ProfileView id="profile" />
                    <BalanceView id="balance" />
                </Epic>
            </Panel>
        </View>
    )
}

export default HomeView;