import { Button, ButtonGroup, ModalCard, Snackbar, Spacing } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, lastOrderId, lastOrderPayToken, snackbar } from "../storage/States";
import { Icon28ErrorCircleOutline, Icon56PaymentCardOutline } from "@vkontakte/icons";
import React from "react";


const OrderHasBeenCreated = ({ id }: BaseProps) => {
    const payOrder = () => {
        if (lastOrderPayToken.get().length > 0) window.open(lastOrderPayToken.get(), '_blank', '');
        else {
            activeModal.set('');
            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                >
                    Произошла ошибка при оплате
                </Snackbar>
            )
        }
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon56PaymentCardOutline fill="#F3294A" />}
            header={"Заказ №" + lastOrderId.get()}
            subheader={<>
                Ваш заказ сформирован.

                <br /> <br />Теперь для того чтобы ваш заказ был принят, вам нужно его оплатить.
                Чтобы оплатить заказ нажмите кнопку ниже. 
                <br /><br /><strong >Обязательно сохраните номер заказа</strong>
            </>}
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => payOrder()}
                        >
                            Оплатить заказ
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default OrderHasBeenCreated;