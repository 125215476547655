import { View, Panel, PanelHeader, PanelHeaderBack, Group, CellButton, HorizontalScroll, Tabs, TabsItem, Image, RichCell, Separator, Spacing, ActionSheet, ActionSheetItem, AdaptiveIconRenderer, Footer, ScreenSpinner, Snackbar, Checkbox, PanelHeaderButton } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, activeTab, tabs, popout, snackbar, editableProductId } from "../storage/States";
import { Icon20Cancel, Icon20Check, Icon20DeleteOutline, Icon20DeleteOutlineAndroid, Icon20WriteOutline, Icon24Add, Icon28CancelOutline, Icon28CheckCircleOutline, Icon28DeleteOutline, Icon28DeleteOutlineAndroid, Icon28Done, Icon28EditOutline, Icon28MarketSlashOutline } from "@vkontakte/icons";
import React from "react";
import RequestToApi, { productPhotoUrl } from "../api";
import { getMobileOperatingSystem } from "../Providers";
import { getProductById } from "../utils/ActionsWithCart";
import { setAvailabilityProduct } from "../api/products";

interface RendersTabsType {
    activeTabs: number;
    setActiveTabs: React.Dispatch<React.SetStateAction<number>>;
}

const deleteProduct = (id: number) => {
    popout.set(<ScreenSpinner />);

    RequestToApi({ method: 'delete', url: 'products/' + id, token: localStorage.getItem('access_token') }, (data) => {
        RequestToApi({ method: 'get', url: 'products' }, (data) => {
            popout.set(null);
            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                >
                    Вы успешно удалили позицию
                </Snackbar>
            )
            tabs.set(data);
        }, (e) => popout.set(null));
    }, (e) => popout.set(null));
}

const RenderTabs = () => {
    const tab = tabs.get();
    const renderedTabs = [];

    for (let i = 0; i < tab.length; i++) {
        renderedTabs.push(
            <TabsItem
                key={"tab_" + Math.random()}
                selected={activeTab.get() === tab[i].id}
                onClick={() => activeTab.set(tab[i].id)}
            >
                {tab[i].name}
            </TabsItem>
        )
    }

    return (
        <Tabs
            mode="accent"
            layoutFillMode="stretched"
            withScrollToSelectedTab
            scrollBehaviorToSelectedTab="center"
        >
            <HorizontalScroll arrowSize="m">
                {renderedTabs}
            </HorizontalScroll>
        </Tabs>
    )
}

const RenderProducts = ({ activeTabs }: RendersTabsType) => {
    const baseTargetRef = React.useRef(null);

    const products = tabs.get().find(category => category.id === activeTab.get())?.products;
    const productCardsByCategory = [];

    const openBase = (id: number) => {
        const product = getProductById(id);

        popout.set(
            <ActionSheet onClose={() => popout.set(null)} toggleRef={baseTargetRef}>
                <ActionSheetItem onClick={() => {
                    editableProductId.set(id);
                    activeView.set('editProductView');
                }} before={<AdaptiveIconRenderer IconCompact={Icon20WriteOutline} IconRegular={Icon28EditOutline} />}>Редактировать</ActionSheetItem>
                <ActionSheetItem before={
                    <AdaptiveIconRenderer
                        IconCompact={getMobileOperatingSystem() === 'ios' ? Icon20DeleteOutline : Icon20DeleteOutlineAndroid}
                        IconRegular={getMobileOperatingSystem() === 'ios' ? Icon28DeleteOutline : Icon28DeleteOutlineAndroid}
                    />
                }
                    mode="destructive" onClick={() => deleteProduct(id)}>
                    Удалить
                </ActionSheetItem>
                {product?.availability ?
                    <ActionSheetItem onClick={() => setAvailabilityProduct(id, false)} before={<AdaptiveIconRenderer IconCompact={Icon20Cancel} IconRegular={Icon28CancelOutline} />} mode="destructive">Нет в наличии</ActionSheetItem>
                    : <ActionSheetItem onClick={() => setAvailabilityProduct(id, true)} before={<AdaptiveIconRenderer IconCompact={Icon20Check} IconRegular={Icon28Done} />}>В наличии</ActionSheetItem>}
            </ActionSheet>
        );
    }

    if (products !== undefined && products?.length !== 0) {
        for (let i = 0; i < products!.length; i++) {
            productCardsByCategory.push(
                <div key={"product_admin" + Math.random()}>
                    <RichCell
                        key={"product_admin" + Math.random()}
                        getRootRef={baseTargetRef}
                        onClick={() => openBase(products[i].id)}
                        before={
                            <Image width={96} src={productPhotoUrl + products[i].photo} />
                        }
                        caption={products[i].structure}
                        afterCaption={products[i].availability ? 'В наличии' : 'Нет в наличии'}
                        after={products[i].cost.toLocaleString('ru-RU') + " ₸"}
                    >
                        {products[i].name} {products[i].quantity.length > 0 ? `(${products[i].quantity})` : ``}
                    </RichCell>
                    <Spacing key={"product_admin_spacing" + Math.random()} />
                    <Separator key={"product_admin_separator" + Math.random()} />
                    <Spacing key={"product_admin_spacing2" + Math.random()} />
                </div>
            )
        }
    } else {
        return <Footer>Позиций не найдено</Footer>;
    }
    return <>{productCardsByCategory}</>
}


const ProductsView = ({ id }: BaseProps) => {
    const [activeTabs, setActiveTabs] = React.useState(activeTab.get());


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={<PanelHeaderButton onClick={() => activeView.set('availabilityOfTheDishView')}><Icon28MarketSlashOutline /></PanelHeaderButton>}
                >
                    Блюда
                </PanelHeader>
                <RenderTabs />
                <Group>
                    <CellButton onClick={() => activeView.set('addProductView')} centered before={<Icon24Add />}>
                        Добавить новое блюдо
                    </CellButton>
                </Group>
                <RenderProducts activeTabs={activeTabs} setActiveTabs={setActiveTabs} />
            </Panel>
        </View>
    )
}

export default ProductsView;