import { Button, Div, FormItem, FormStatus, Group, Input, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose, Spinner } from "@vkontakte/vkui";

import BaseProps from "../props/BaseProps";
import { getMobileOperatingSystem } from "../Providers";
import { Icon24Dismiss, Icon28MentionOutline, Icon28LockOutline, Icon24HelpOutline, Icon56UserCircleOutline } from "@vkontakte/icons";
import { actionWithBonuses, activeModal, activeStory, newCart, timeUntilTheNextEmail, userData, userEmail, userPassword } from "../storage/States";
import React from "react";
import { instance } from "../api";

const validateEmail = (email: string) => {
    return email.match(
        /* eslint-disable-next-line */
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const SignInModal = ({ id }: BaseProps) => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
        setErrorMessage("");
    }

    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
        setErrorMessage("");
    }

    const signIn = async () => {
        if (email.length > 0 && password.length > 0) {
            if (validateEmail(email)) {
                
                setLoading(true);

                const data = { email, password }

                instance.post('auth/signin', data)
                    .then(res => {
                        localStorage.setItem("access_token", res.data.access_token);
                        localStorage.setItem("refresh_token", res.data.refresh_token);

                        instance.get('user/profile', { headers: { authorization: "Bearer " + res.data.access_token } })
                            .then(response => {
                                userData.set(response.data);
                                newCart.set(response.data.cart);
                                actionWithBonuses.set('GAIN');
                                activeModal.set('');
                                activeStory.set('profile');
                            })
                            .catch(e => {
                                if (e.response && e.response.data) {
                                    setLoading(false);
                                    setErrorMessage(e.response.data.message);
                                } else {
                                    setLoading(false);
                                    setErrorMessage('Произошли непредвиденные сложности при выполнении запроса');
                                }
                            });
                    })
                    .catch(e => {
                        if (e.response && e.response.data) {
                            if (e.response.data.id === 'EMAIL_IS_NOT_ACTIVATED') {
                                instance.post('auth/resend-time', data)
                                    .then((response) => {
                                        timeUntilTheNextEmail.set(response.data.timeUntilTheNextEmail);
                                        userEmail.set(email);
                                        userPassword.set(password);
                                        setLoading(false);
                                        activeModal.set('necessaryToConfirmEmail');
                                    })
                                    .catch((err) => {
                                        if (e.response && e.response.data) {
                                            setLoading(false);
                                            setErrorMessage(e.response.data.message);
                                        } else {
                                            setLoading(false);
                                            setErrorMessage('Произошли непредвиденные сложности при выполнении запроса');
                                        }
                                    });
                            } else {
                                setLoading(false);
                                setErrorMessage(e.response.data.message);
                            }
                        } else {
                            setLoading(false);
                            setErrorMessage('Произошли непредвиденные сложности при выполнении запроса');
                        }
                    });
            } else {
                setErrorMessage("Электронная почта некорректна");
            }
        } else {
            setErrorMessage("Необходимо заполнить все поля")
        }
    }

    return (
        <ModalPage
            id={id}
            settlingHeight={100}
            hideCloseButton={getMobileOperatingSystem() === 'ios'}
            onClose={() => activeModal.set('')}
            header={
                <ModalPageHeader
                    before={
                        getMobileOperatingSystem() === 'android' && (
                            <PanelHeaderClose onClick={() => activeModal.set('')} />
                        )
                    }
                    after={
                        getMobileOperatingSystem() === 'ios' && (
                            <PanelHeaderButton onClick={() => activeModal.set('')}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    Вход в аккаунт
                </ModalPageHeader>
            }
        >
            <Group>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: "10%" }}>
                    <Icon56UserCircleOutline width={70} height={70} fill="#F3294A" />
                </div>

                {errorMessage.length > 0 ? <Div>
                    <FormStatus mode="error">
                        {errorMessage}
                    </FormStatus>
                </Div> : <></>}

                <FormItem>
                    <Input placeholder="E-mail" type="email" before={<Icon28MentionOutline width={24} height={24} fill="#818C99" />} onChange={onChangeEmail} />
                </FormItem>
                <FormItem>
                    <Input placeholder="Пароль" type="password" before={<Icon28LockOutline width={24} height={24} fill="#818C99" />} after={<Icon24HelpOutline onClick={() => activeModal.set('resetPassword')}/>} onChange={onChangePassword} />
                </FormItem>
                <Div style={{ marginBottom: '25%' }}><Button
                    onClick={signIn}
                    style={{ backgroundColor: "#F3294A" }}
                    size="l"
                    mode="primary"
                    stretched
                    disabled={loading}

                >
                    {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : "Войти"}
                </Button>
                </Div>
            </Group>
        </ModalPage>
    )
}

export default SignInModal;