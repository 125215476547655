import { Banner, Div, Footer, Group, Header, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Skeleton, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { Icon28AddOutline, Icon28WarningTriangleOutline } from "@vkontakte/icons";
import { activeView, activeModal, allReasonsForClosure, editableReasonToClosure } from "../storage/States";
import React from "react";
import { getAllReasonsToClosure } from "../api/reasonsToClosure";

const RenderReasonsToClosure = ({ isActive }: { isActive: boolean }) => {
    const getReasons = allReasonsForClosure.get();

    if (getReasons.length) {
        const render = [];

        for (let i = 0; i < getReasons.length; i++) {
            if (getReasons[i].active === isActive) {
                render.push(
                    <Div key={"reason" + Math.random()}>
                        <Banner
                            before={<Icon28WarningTriangleOutline fill="#F3294A" />}
                            header={getReasons[i].title}
                            subheader={getReasons[i].message}
                            asideMode="expand"
                            onClick={() => { editableReasonToClosure.set(getReasons[i].id); activeModal.set('editReasonToClosure') }}
                        />
                    </Div>
                )
            } else continue;
        }

        if(render.length !== 0) {
            return <>{render}</>
        } else return <Footer>Причины закрытия не найдены</Footer>
    } else return <Footer>Причины закрытия не найдены</Footer>;
}

const RenderLoadingReasonsToClosure = () => {
    const render = [];

    for (let i = 0; i <= 5; i++) {
        render.push(
            <Div key={"loading_reasons_" + Math.random()}>
                <Skeleton height={86} />
            </Div>
        )
    }

    return <>{render}</>
}

const ReasonsToClosureView = ({ id }: BaseProps) => {
    const [loading, SetLoading] = React.useState(true);

    React.useEffect(() => getAllReasonsToClosure(() => setTimeout(() => SetLoading(false), 2000)), [loading]);

    return (
        <View id={id} activePanel={id}>
            <Panel id={id} >
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={<PanelHeaderButton onClick={() => activeModal.set('addReasonToClosure')}><Icon28AddOutline /></PanelHeaderButton>}
                >
                    Причины закрытия
                </PanelHeader>
                <Group header={<Header mode="secondary">Активные причины закрытия</Header>}>
                    {loading ? <RenderLoadingReasonsToClosure /> : <RenderReasonsToClosure isActive={true} />}
                </Group>
                <Group header={<Header mode="secondary">Неактивные причины закрытия</Header>}>
                    {loading ? <RenderLoadingReasonsToClosure /> : <RenderReasonsToClosure isActive={false} />}
                </Group>
            </Panel>
        </View>
    )
}

export default ReasonsToClosureView;