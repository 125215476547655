import ReactDOM from 'react-dom/client';
import App from './App';
import Store from './storage/Store';
import reportWebVitals from './reportWebVitals';

import './lib/css/index.css';
import Providers from './Providers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


Store.subscribe(() => root.render(
  <Providers>
    <App />
  </Providers>
));


reportWebVitals();