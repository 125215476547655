import React from 'react';
import axios from "axios";

import { Icon28ErrorCircleOutline } from '@vkontakte/icons';

import { errorMessage, snackbar, activeView, textForWorkScheduleModal, activeModal, titleForClosure, messageForClosure } from '../storage/States';
import { Snackbar } from "@vkontakte/vkui";

export const apiUrl = process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://api.fujiyama.kz/";
export const productPhotoUrl = apiUrl + "products/photo/";

export const instance = axios.create({
    baseURL: apiUrl,
    timeout: 3600000
});

interface RequestType {
    method: "get" | "post" | "delete" | "put",
    url: string,
    data?: any | FormData,
    token?: string | null
}

const RequestToApi = async ({ method, url, data, token }: RequestType, cb?: (data: any) => void, cbError?: (err: any) => void) => {
    try {
        const response = await instance({
            method: method,
            url: apiUrl + url,
            data: data,
            headers: {
                "authorization": "Bearer " + (token ? token : ""),
                'content-type': (data instanceof FormData ? 'multipart/form-data' : 'application/json')
            }
        });

        if (cb) return cb(response.data);
        else return response.data;
    } catch (e: any) {
        if (cbError) cbError(e);
        if (e.response && e.response.data) {
            if (e.response.data.type === "WARN") {
                if (e.response.data.id === 'NOT_OPEN_TO_CREATE_ORDER' || e.response.data.id === 'CLOSED_TO_CREATE_ORDER') {
                    textForWorkScheduleModal.set(e.response.data.message);
                    activeModal.set('workSchedule');
                } else if (e.response.data.id === 'REASON_TO_CLOSURE') { 
                    titleForClosure.set(e.response.data.title);
                    messageForClosure.set(e.response.data.message);
                    activeModal.set('reasonForClosure');
                }
                else {
                    snackbar.set(
                        <Snackbar
                            mode="dark"
                            onClose={() => snackbar.set(null)}
                            before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                        >
                            {e.response.data.message}
                        </Snackbar>)
                }
            } else {
                errorMessage.set(e.response.data.message);
                activeView.set("errorView");
            }
        } else {
            activeView.set("errorView");
        }

    }
}

export default RequestToApi;