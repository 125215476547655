import { Icon28MotorcycleOutline } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, FormItem, Input, PanelSpinner, Switch, SimpleCell } from "@vkontakte/vkui";
import React from "react";
import BaseProps from "../props/BaseProps";
import { activeModal, deliveryMethods, editabledDeliveryMethodId, popout } from "../storage/States";
import RequestToApi from "../api";

const EditDeliveryMethod = ({ id }: BaseProps) => {
    const deliveryMethod = deliveryMethods.get().find(method => method.id === editabledDeliveryMethodId.get());
    const [name, setName] = React.useState(deliveryMethod!.name);
    const [cost, setCost] = React.useState(deliveryMethod!.cost);
    const [address, setAddress] = React.useState(deliveryMethod!.address);
    const [apartment, setApartment] = React.useState(false);

    const onChangeName = (e: any) => setName(e.target.value);

    const InputCost = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            setCost(Number(cost.toString().substring(0, cost.toString().length - 1)));
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (!isNaN(e.nativeEvent.data)) {
                if (cost > 1000000) return;

                setCost(Number(cost.toString() + e.nativeEvent.data));
            } else return;
        }
    }

    const edit = () => {
        activeModal.set('');
        popout.set(<PanelSpinner />);

        const token = localStorage.getItem('access_token');

        const data = {
            id: deliveryMethod?.id,
            name: name,
            cost: cost,
            address: address,
            apartment: apartment
        }

        RequestToApi({ method: 'put', url: 'order/delivery', data: data, token: token }, (data) => {
            RequestToApi({ method: 'get', url: 'order/delivery' }, (response) => {
                deliveryMethods.set(response);
                popout.set(null);
            }, (e) => popout.set(null));
        }, (e) => popout.set(null));
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28MotorcycleOutline width={56} height={56} fill="#F3294A" />}
            header="Редактирование"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => edit()}
                        >

                            Изменить
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            <FormItem>
                <Input placeholder="Введите название" type="text" value={name} onChange={onChangeName} />
            </FormItem>
            <FormItem>
                <Input placeholder="Введите цену" type="tel" value={cost?.toLocaleString('ru-RU') + " ₸"} onChange={InputCost} />
            </FormItem>
            <FormItem>
                <SimpleCell after={<Switch defaultChecked={address} onClick={() => setAddress(!address)} />}>Ввод адреса?</SimpleCell>
            </FormItem>
            {address ? <FormItem>
                <SimpleCell after={<Switch defaultChecked={apartment} onClick={() => setApartment(!apartment)} />}>Ввод № квартиры/подъезда?</SimpleCell>
            </FormItem> : <></>}
        </ModalCard>
    )
}

export default EditDeliveryMethod;