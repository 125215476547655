import React from 'react';
import { Group, Panel, View, FixedLayout, ScreenSpinner, PanelHeader } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";

import { activeModal, activeView, deliveryMethods, newCart, popout, selectedDeliveryMethod, userData } from '../storage/States';
import ProductInCart from "../elements/ProductInCart";
import { getProductById } from "../utils/ActionsWithCart";
import RequestToApi from "../api";


const CartView = ({ id }: BaseProps) => {
    const newOrder = () => {
        popout.set(<ScreenSpinner state="loading" />);

        if (userData.get() !== null) {
            RequestToApi({
                method: 'get',
                url: 'user/profile',
                token: localStorage.getItem('access_token')
            }, (data: any) => {
                userData.set(data);

                RequestToApi({
                    method: 'get',
                    url: 'order/delivery'
                }, (methods: any) => {
                    deliveryMethods.set(methods);
                    selectedDeliveryMethod.set(methods[0].id)
                    popout.set(null);
                    activeView.set('createOrderView');
                }, (e: any) => popout.set(null));

            }, (e: any) => popout.set(null));
        }

        else {
            RequestToApi({
                method: 'get',
                url: 'order/delivery'
            }, (data: any) => {
                deliveryMethods.set(data);
                selectedDeliveryMethod.set(data[0].id)
                popout.set(null);
                activeView.set('createOrderView');
            });
        }
    }

    return (
        <View id={id} activePanel={id} >
            <Panel id={id} >
            <PanelHeader
                >
                    Корзина
                </PanelHeader>
                <Group style={{ marginBottom: "25%" }}>
                    <ProductInCart />
                </Group>
                <FixedLayout vertical="bottom">
                    <div className=" MobileUIBottomBar_portal">
                        <div className="MobileUIBottomBar_root">
                            <div>
                                <div className="CartBarLayout_root CartBarLayout_footer">
                                    <button className="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MobileCartButton_root" onClick={() => newOrder()}>
                                        <span>
                                            30-60 мин
                                            <div className="MobileCartButtonContent_root">
                                                <div className="MobileCartButtonContent_wrapper MobileCartButtonContent_center">Заказ</div>
                                            </div>
                                            <div className="MobileCartButtonBubble_root">
                                                <p className="Price_root">{(newCart.get().reduce((a, b) => a + (b.count * Number(getProductById(b.productId)?.cost)), 0).toLocaleString("ru-RU"))} ₸</p>
                                            </div>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FixedLayout>
            </Panel>
        </View>
    )
}

export default CartView;