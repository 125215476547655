import { Icon28CheckCircleOutline, Icon28FolderOutline } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, Spinner, Div, FormStatus, FormItem, Input, Snackbar } from "@vkontakte/vkui";
import React from "react";
import { activeModal, activeTab, editableCategoryId, snackbar, tabs } from "../storage/States";
import BaseProps from "../props/BaseProps";
import { instance } from "../api";

const EditCategory = ({ id }: BaseProps) => {
    const [name, setName] = React.useState(tabs.get().find(tab => tab.id === editableCategoryId.get())!.name);
    const [errorMessageFromServer, setErrorMessageFromServer] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const onChangeName = (e: any) => setName(e.target.value);

    const getEditableCategoryOldName = () => tabs.get().find(tab => tab.id === editableCategoryId.get())!.name;

    const edit = () => {
        setErrorMessageFromServer('');
        

        if (name !== getEditableCategoryOldName()) {
            const token = localStorage.getItem('access_token');
            setLoading(true);

            instance.put('products/category', { name, category_id: editableCategoryId.get() }, { headers: { 'authorization': 'Bearer ' + token } })
                .then(() => {
                    instance.get('products', { headers: { 'authorization': 'Bearer ' + token } })
                        .then((response) => {
                            tabs.set(response.data);
                            activeTab.set(response.data[1].id);
                            activeModal.set('');
                            snackbar.set(
                                <Snackbar
                                    mode="dark"
                                    onClose={() => snackbar.set(null)}
                                    before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                                >
                                    Вы успешно изменили название категории
                                </Snackbar>
                            )
                        })
                        .catch(errorHandling);
                })
                .catch(errorHandling);
        } else {
            setErrorMessageFromServer('Новое название категории совпадает со старым')
        }
    }

    const errorHandling = (e: any) => {
        setLoading(false);

        if (e.response && e.response.data) {
            setErrorMessageFromServer(e.response.data.message);
        } else {
            setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
        }
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28FolderOutline width={56} height={56} fill="#F3294A" />}
            header="Редактирование названия категории"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            disabled={loading ? loading : !(name.length !== 0)}
                            onClick={() => edit()}
                        >

                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : "Изменить"}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            {errorMessageFromServer.length > 0 ? <Div>
                <FormStatus mode="error">
                    {errorMessageFromServer}
                </FormStatus>
            </Div> : <></>}
            <FormItem>
                <Input placeholder="Введите название категории" type="text" value={name} onChange={onChangeName} />
            </FormItem>
        </ModalCard>
    )
}

export default EditCategory;