import { Alert, Avatar, Button, ButtonGroup, Div, Footer, Gradient, Group, Header, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Placeholder, ScreenSpinner, Separator, SimpleCell, Snackbar, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, anonimOrder, anonimOrderToken, lastOrderPayToken, popout, snackbar } from "../storage/States";
import { Icon20CancelCircleOutline, Icon20CheckCircleOutline, Icon20ClockOutline, Icon28CheckCircleOutline, Icon28ErrorCircleOutline, Icon28MarketOutline, Icon28MarketSlashOutline, Icon28MoneyWadOutline, Icon28PaymentCardOutline, Icon28ReplayOutline, Icon36CoinsStacks3Outline } from "@vkontakte/icons";
import { getReasonMessage } from "../utils/getReasonTextForCancellation";
import { AsYouType } from "libphonenumber-js";
import RequestToApi from "../api";

const AnonimOrderView = ({ id }: BaseProps) => {
    const order = anonimOrder.get();

    const getStatusIcon = (status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') return <Icon20ClockOutline fill="var(--vkui--color_icon_secondary)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'CONFIRMED') return <Icon20CheckCircleOutline fill="#4BB34B" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
        if (status === 'NOT_CONFIRMED') return <Icon20CancelCircleOutline fill="var(--vkui--color_icon_negative)" style={{ display: 'inline-block', verticalAlign: 'text-top' }} />
    }

    const getTextStatus = (status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        if (status === 'AWAITING_CONFIRMATION') return 'Ожидает оплаты';
        if (status === 'CONFIRMED') return 'Заказ принят';
        if (status === 'NOT_CONFIRMED') return 'Отменён';
    }

    const payOrder = () => {
        if (lastOrderPayToken.get().length > 0) window.open(lastOrderPayToken.get(), '_blank', '');
        else snackbar.set(
            <Snackbar
                mode="dark"
                onClose={() => snackbar.set(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
            >
                Произошла ошибка при оплате
            </Snackbar>
        )
    }

    const getOrder = () => {
        popout.set(<ScreenSpinner />);
        RequestToApi({ method: 'get', url: 'order/by-token', token: anonimOrderToken.get() }, (response) => {
            anonimOrder.set(response);
            popout.set(null)
        }, () => popout.set(null));
    }

    const cancelOrder = () => {
        popout.set(
            <Alert
                actions={[
                    {
                        title: 'Отменить',
                        mode: 'destructive',
                        action: () => cancelOrderConfirmed()
                    },
                    {
                        title: 'Нет',
                        mode: 'cancel',
                    }
                ]}
                actionsLayout="horizontal"
                dismissButtonMode="inside"
                onClose={() => popout.set(null)}
                header="Отменить заказ"
                text="Вы уверены, что хотите отменить заказ?"
            />,

        )
    }

    const cancelOrderConfirmed = () => {
        popout.set(<ScreenSpinner />);
        RequestToApi({ method: 'delete', url: 'order/by-token', token: anonimOrderToken.get() }, (data) => {
            RequestToApi({ method: 'get', url: 'order/by-token', token: anonimOrderToken.get() }, (response) => {
                anonimOrder.set(response);
                popout.set(null);

                snackbar.set(
                    <Snackbar
                        mode="dark"
                        onClose={() => snackbar.set(null)}
                        before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                    >
                        Вы успешно отменили заказ
                    </Snackbar>
                );
            }, () => popout.set(null));
        }, () => popout.set(null));
    }

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('homeView')} />}
                    after={order!.status === 'AWAITING_CONFIRMATION' ? <PanelHeaderButton onClick={() => getOrder()}><Icon28ReplayOutline /></PanelHeaderButton> : <></>}
                >
                    Информация о заказе
                </PanelHeader>
                <Group>
                    <Gradient mode="tint" to="top">
                        <Placeholder
                            icon={<Avatar size={96} style={{ backgroundColor: "#F3294A" }}><Icon28MarketOutline fill="#ffffff" width={56} height={56} /></Avatar>}
                            header={<>Заказ №{order!.id} {' '} {getStatusIcon(order!.status)}</>}

                        >
                            {getTextStatus(order!.status)}
                            <br />
                            <br />
                            {order!.status === 'NOT_CONFIRMED' ? <>Причина: <br /> {getReasonMessage(order!.reasonForCancellation)}</> : <></>}
                            {order!.status === 'AWAITING_CONFIRMATION' ? 'Для того чтобы ваш заказ был принят, вам необходимо его оплатить' : ''}
                        </Placeholder>
                    </Gradient>
                    {order!.status === 'AWAITING_CONFIRMATION' ? <Div>
                        <ButtonGroup mode="horizontal" gap="s" stretched>
                            <Button before={<Icon28PaymentCardOutline />} mode="outline" size="l" appearance="neutral" stretched onClick={() => payOrder()}>
                                Оплатить
                            </Button>
                            <Button before={<Icon28MarketSlashOutline />} mode="outline" size="l" appearance="negative" onClick={() => cancelOrder()} stretched>
                                Отменить
                            </Button>
                        </ButtonGroup>
                    </Div> : <></>}
                    <Group mode="plain" header={<Header>Способ оплаты</Header>}>
                        {order!.methodOfPayment === 'BANK_CARD' ?
                            <SimpleCell before={<Icon28PaymentCardOutline />} indicator={order!.creditCardFirst1.length > 0 ? order!.creditCardFirst1 + "****" + order!.creditCardLast4 : ""}>Банковская карта</SimpleCell> :
                            <SimpleCell before={<Icon28MoneyWadOutline />}>Наличный расчёт</SimpleCell>
                        }
                    </Group>
                    <Group mode="plain" header={<Header >Номер телефона</Header>}>
                        <SimpleCell

                        >
                            {new AsYouType('KZ').input(String(order!.phoneNumber))}
                        </SimpleCell>
                    </Group>
                    {order!.deliveryAddress.length > 0 ?
                        <Group mode="plain" header={<Header >Адрес доставки</Header>}>
                            <SimpleCell

                            >
                                {order!.deliveryAddress}
                            </SimpleCell>
                        </Group>
                        : <></>}
                    {order!.comment.length > 0 ?
                        <Group mode="plain" header={<Header >Комметарий к заказу</Header>}>
                            <SimpleCell

                            >
                                {order!.comment}
                            </SimpleCell>
                        </Group>
                        : <></>}
                    <Group mode="plain" header={<Header>Заказ: </Header>}>
                        <Div style={{ marginTop: 0, paddingTop: 0 }}>
                            {order!.products.map((product, index) => (
                                <div key={'anonim_order_' + order!.id + '_' + index}>
                                    <SimpleCell before={index + 1}
                                        indicator={(product.cost * product.count).toLocaleString("ru-RU") + "₸"}
                                        subtitle={product.count + " шт. × " + product.cost.toLocaleString('ru-RU') + "₸"}>
                                        {product.name}
                                    </SimpleCell>
                                    {index + 1 < order!.products.length ? <><Separator /></> : <></>}
                                </div>
                            ))}
                            <Separator style={{ color: '#000' }} />
                            {order!.deliveryMethod.cost > 0 ? <SimpleCell

                                indicator={order!.summary.toLocaleString('ru-RU') + " ₸"}
                            >
                                Подытог
                            </SimpleCell> : <></>}
                            {order!.deliveryMethod.cost > 0 ? <SimpleCell

                                subtitle={order!.deliveryMethod.name}
                                indicator={"+ " + order!.deliveryMethod.cost.toLocaleString("ru-RU") + " ₸"}
                            >
                                Доставка
                            </SimpleCell> : <></>}
                            <SimpleCell indicator={order!.amountToBePaid.toLocaleString("ru-RU") + " ₸"}>
                                <b>Итого к оплате</b>
                            </SimpleCell>
                        </Div>
                    </Group>
                    {order!.status !== 'NOT_CONFIRMED' ? <Footer>
                        Обязательно запомните номер заказа. Номер заказа необходим для того чтобы убедиться, что именно вы оформили его.
                    </Footer> : <></>}
                </Group >
            </Panel>
        </View>
    )
}

export default AnonimOrderView;