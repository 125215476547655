import React from 'react';
import { Button, ButtonGroup, Counter, Image, RichCell, Separator, Spacing } from "@vkontakte/vkui";
import { Icon24Add, Icon28MinusOutline } from "@vkontakte/icons";


import { newCart } from "../storage/States";
import { addProductInCart, deleteProductFromCart, getProductById } from "../utils/ActionsWithCart";
import { productPhotoUrl } from "../api";

const ProductInCart = () => {

    const products: any = [];
    const productsInCart = newCart.get();

    for (let i = 0; i < productsInCart.length; i++) {
        const productById = getProductById(productsInCart[i].productId);

        if (productById) {
            products.push(
                <div key={"product" + productsInCart[i].productId}>
                    <RichCell
                        before={
                            <Image size={96} src={productPhotoUrl + productById.photo} >
                                <Image.Badge>
                                    {productById.quantity.length > 0 ? <Counter size="s">{productById.quantity}</Counter> : <></>}
                                </Image.Badge>
                            </Image>
                        }
                        caption={productsInCart[i].count + " шт. × " + productById?.cost.toLocaleString('ru-RU') + " ₸"}
                        after={(productsInCart[i].count * productById.cost).toLocaleString('ru-RU') + " ₸"}
                        actions={
                            <ButtonGroup mode="horizontal" gap="s">
                                <Button mode="tertiary" size="s" onClick={() => addProductInCart(productById.id)}>
                                    <Icon24Add color="#000" />
                                </Button>
                                <Button mode="tertiary" size="s" onClick={() => deleteProductFromCart(productById.id)}>
                                    <Icon28MinusOutline width={24} height={24} color="#000" />
                                </Button>
                            </ButtonGroup>
                        }
                    >
                        {productById?.name}
                    </RichCell>
                    <Spacing />
                    {i + 1 < productsInCart.length ? < Separator /> : <></>}
                    <Spacing />
                </div>
            );
        } else products.push(<></>);
    }
    return <h1>{products}</h1>
}

export default ProductInCart;