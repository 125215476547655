import { Button, ModalCard, Spacing } from "@vkontakte/vkui";
import { Icon28ShoppingCartOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal, activeStory } from "../storage/States";
import React from "react";

const CartIsEmptyModal = ({ id }: BaseProps) => {
    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28ShoppingCartOutline width={56} height={56} fill="#F3294A" />}
            header="Ваша корзина пуста"
            subheader="Вы можете выбрать очень вкусные блюда на странице меню и добавить их в корзину"
            actions={
                <React.Fragment>
                    <Spacing size={16} />
                    <Button
                        onClick={() => {
                            activeStory.set("menu");
                            activeModal.set('');
                        }}
                        style={{ backgroundColor: "#F3294A" }}
                        size="l"
                        mode="primary"
                        stretched

                    >
                        Перейти в меню
                    </Button>
                </React.Fragment>
            }
        />
    )
}

export default CartIsEmptyModal;