import React from 'react';
import { Banner, Div, Group, ModalPage, ModalPageHeader, PanelHeaderClose } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, promotions } from "../storage/States";
import { Icon28FavoriteCircleFillGreen } from "@vkontakte/icons";

const Promotions = ({ id }: BaseProps) => {
    return (
        <ModalPage

            id={id}
            onClose={() => activeModal.set('')}
            header={
                <ModalPageHeader before={<PanelHeaderClose onClick={() => activeModal.set('')} />}>
                    Акции
                </ModalPageHeader>
            }
        >
            <Group>
                {promotions.get().map(promotion => (
                    <Div>
                        <Banner
                            key={promotion.id}
                            before={<Icon28FavoriteCircleFillGreen />}
                            header="Акция"
                            subheader={promotion.text}
                        />
                    </Div>
                ))}
            </Group>
        </ModalPage >
    )
}

export default Promotions;