export default class Store {
    private static data: Array<Map<string, any>> = [new Map<string, any>()];
    private static subscribed: Function;

    public static getData(): Map<string, any> {
        return Store.data[Store.data.length - 1];
    }

    public static set<T>(key: string, value: T | undefined) {
        Store.getData().set(key, value);

        return Store.subscribed();
    }

    public static get<T>(key: string): T {
        const container = Store.getData();

        return container.get(key);
    }

    public static create<T>(key: string, value: T | undefined) {
        if (Store.get(key) === undefined) {
            Store.getData().set(key, value);
        }

        return {
            get(): T {
                return Store.get(key);
            },
            set: <T>(value: T) => {
                Store.set(key, value);
            }
        }
    }
    public static subscribe(func: Function) {
        Store.subscribed = func;

        return func();
    }
}