import { CardGrid, ContentCard, Group, Header, InfoRow, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, SimpleCell, Skeleton, View } from "@vkontakte/vkui";
import { Icon28DollarOutline, Icon28ReplayOutline } from "@vkontakte/icons";
import React from "react";

import BaseProps from "../props/BaseProps";
import { activeView } from "../storage/States";

import perDay from '../lib/img/perDay.svg';
import perWeek from '../lib/img/perWeek.svg';
import earnPerMonth from '../lib/img/earnPerMonth.svg';
import earnPerAllTime from '../lib/img/earnPerAllTime.svg';

import countUsers from '../lib/img/countUsers.svg';
import countOrders from '../lib/img/countOrders.svg';

import spentPerDay from '../lib/img/spentPerDay.svg';
import spentPerWeek from '../lib/img/spentPerWeek.svg';
import spentPerMonth from '../lib/img/spentPerMonth.svg';
import spentPerAllTime from '../lib/img/spentPerAllTime.svg';
import RequestToApi from "../api";


const StatsView = ({ id }: BaseProps) => {
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState(0);
    const [orders, setOrders] = React.useState(0);
    const [earned, setEarned] = React.useState({ perDay: 0, perWeek: 0, perMonth: 0, perAllTime: 0 });
    const [spent, setSpent] = React.useState({ perDay: 0, perWeek: 0, perMonth: 0, perAllTime: 0 });
    const [profit, setProfit] = React.useState({ perDay: 0, perWeek: 0, perMonth: 0, perAllTime: 0 });
    const [lostProfits, setLostProfits] = React.useState({ perDay: 0, perWeek: 0, perMonth: 0, perAllTime: 0 });

    const getData = () => {
        setLoading(true);

        RequestToApi({ method: 'get', url: 'stats', token: localStorage.getItem('access_token') }, (data) => {

            setUsers(data.users);
            setOrders(data.orders);
            setEarned(data.bonuses.earn);
            setSpent(data.bonuses.spent);
            setProfit(data.profit);
            setLostProfits(data.lostProfits)

            setTimeout(() => setLoading(false), 2000);
        });
    }

    React.useEffect(() => getData(), []);

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={<PanelHeaderButton onClick={() => getData()}><Icon28ReplayOutline /></PanelHeaderButton>}
                >
                    Статистика
                </PanelHeader>
                <Group header={<Header mode="secondary">Основная информация</Header>}>
                    <CardGrid size="m">
                        <ContentCard
                            src={countUsers}
                            subtitle="Пользователей"
                            text={loading ? <Skeleton /> : users.toLocaleString('ru-RU')}
                        />
                        <ContentCard
                            src={countOrders}
                            subtitle="Исполненных заказов"
                            text={loading ? <Skeleton /> : orders.toLocaleString('ru-RU')}
                        />
                    </CardGrid>
                </Group>
                <Group header={<Header mode="secondary">Прибыль</Header>}>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За день">{loading ? <Skeleton width={100} /> : profit.perDay.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За неделю">{loading ? <Skeleton width={100} /> : profit.perWeek.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За месяц">{loading ? <Skeleton width={100} /> : profit.perMonth.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За все время">{loading ? <Skeleton width={100} /> : profit.perAllTime.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                </Group>
                <Group header={<Header mode="secondary">Потеряно</Header>}>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За день">{loading ? <Skeleton width={100} /> : lostProfits.perDay.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За неделю">{loading ? <Skeleton width={100} /> : lostProfits.perWeek.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За месяц">{loading ? <Skeleton width={100} /> : lostProfits.perMonth.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                    <SimpleCell before={<Icon28DollarOutline />} multiline>
                        <InfoRow header="За все время">{loading ? <Skeleton width={100} /> : lostProfits.perAllTime.toLocaleString('ru-RU') + " ₸"}</InfoRow>
                    </SimpleCell>
                </Group>
                <Group header={<Header mode="secondary">Бонусы</Header>}>
                    <CardGrid size="l">
                        <ContentCard
                            src={earnPerAllTime}
                            subtitle="Начислено"
                            header={loading ? <Skeleton /> : earned.perAllTime.toLocaleString('ru-RU') + " Б"}
                        />
                    </CardGrid>
                    <CardGrid size="s">
                        <ContentCard
                            src={perDay}
                            subtitle="Начислено"
                            header={loading ? <Skeleton /> : earned.perDay.toLocaleString('ru-RU') + " Б"}
                        />
                        <ContentCard
                            src={perWeek}
                            subtitle="Начислено"
                            header={loading ? <Skeleton /> : earned.perWeek.toLocaleString('ru-RU') + " Б"}
                        />
                        <ContentCard
                            src={earnPerMonth}
                            subtitle="Начислено"
                            header={loading ? <Skeleton /> : earned.perMonth.toLocaleString('ru-RU') + " Б"}

                        />
                    </CardGrid>
                </Group>
                <Group>
                    <CardGrid size="l">
                        <ContentCard
                            src={spentPerAllTime}
                            subtitle="Потрачено"
                            header={loading ? <Skeleton /> : spent.perAllTime.toLocaleString('ru-RU') + " Б"}

                        />
                    </CardGrid>
                    <CardGrid size="s">
                        <ContentCard
                            src={spentPerDay}
                            subtitle="Потрачено"
                            header={loading ? <Skeleton /> : spent.perDay.toLocaleString('ru-RU') + " Б"}

                        />
                        <ContentCard
                            src={spentPerWeek}
                            subtitle="Потрачено"
                            header={loading ? <Skeleton /> : spent.perWeek.toLocaleString('ru-RU') + " Б"}

                        />
                        <ContentCard
                            src={spentPerMonth}
                            subtitle="Потрачено"
                            header={loading ? <Skeleton /> : spent.perMonth.toLocaleString('ru-RU') + " Б"}

                        />
                    </CardGrid>
                </Group>
            </Panel>
        </View >
    )
}

export default StatsView;