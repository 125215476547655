import { Button, ButtonGroup, ModalCard, Spacing } from "@vkontakte/vkui";
import { Icon56UserCircleOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal, activeView } from "../storage/States";

import React from "react";

const AuthorizationModal = ({ id }: BaseProps) => {
    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon56UserCircleOutline fill="#F3294A" />}
            header="Войдите в аккаунт"
            subheader="Войдите в аккаунт, чтобы получить возможность накапливать и тратить бонусы"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => activeModal.set('signIn')}
                        >
                            Войти в аккаунт
                        </Button>
                        <Button
                            size="l"
                            mode="primary"
                            appearance="positive"
                            stretched
                            onClick={() => {
                                activeModal.set('');
                                activeView.set('signUpView')
                            }}
                        >
                            Зарегистрироваться
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default AuthorizationModal;