import { Button, ButtonGroup, Div, FormItem, FormStatus, Input, ModalCard, Spacing, Spinner, Group, Header, MiniInfoCell, Snackbar } from "@vkontakte/vkui";
import { Icon28CancelCircleFillRed, Icon28CheckCircleFill, Icon28CheckCircleOutline, Icon28LockOutline, Icon28PincodeLockOutline, Icon28PincodeOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal, snackbar } from "../storage/States";
import React from "react";
import { instance } from "../api";

const ResetPasswordByToken = ({ id }: BaseProps) => {
    const [type, setType] = React.useState('password');
    const [password, setPassword] = React.useState('');

    const [passwordLengthValidated, setPasswordLengthValidated] = React.useState(false);
    const [passwordLowerValidated, setPasswordLowerValidated] = React.useState(false);
    const [passwordUpperValidated, setPasswordUpperValidated] = React.useState(false);
    const [passwordNumberValidated, setPasswordNumberValidated] = React.useState(false);
    const [passwordSpecialValidated, setSpecialValidated] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const [errorMessageFromServer, setErrorMessageFromServer] = React.useState("");

    const validateStrongPassword = (value: string) => {
        const lower = new RegExp('(?=.*[a-z])');
        const upper = new RegExp('(?=.*[A-Z])');
        const number = new RegExp('(?=.*[0-9])');
        /* eslint-disable-next-line */
        const special = new RegExp('(?=.*[!@#\$%\^&\*])');

        if (value.length >= 8) setPasswordLengthValidated(true);
        else setPasswordLengthValidated(false);

        if (lower.test(value)) setPasswordLowerValidated(true);
        else setPasswordLowerValidated(false);

        if (upper.test(value)) setPasswordUpperValidated(true);
        else setPasswordUpperValidated(false);

        if (number.test(value)) setPasswordNumberValidated(true);
        else setPasswordNumberValidated(false);

        if (special.test(value)) setSpecialValidated(true);
        else setSpecialValidated(false);

    }

    const validatePasswordCharacter = (s: string) => {
        const ew = s.charCodeAt(0);


        if (ew >= 32 && ew <= 33) return true;
        if (ew >= 35 && ew <= 38) return true;
        if (ew >= 48 && ew <= 57) return true;
        if (ew >= 64 && ew <= 90) return true;
        if (ew === 94) return true;
        if (ew >= 97 && ew <= 122) return true;

        return false;
    }

    const onChangePassword = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            const newValuePassword = password.substring(0, password.length - 1);
            setPassword(newValuePassword);
            validateStrongPassword(newValuePassword);

        } else if (e.nativeEvent.inputType === 'insertText') {
            var ew = e.nativeEvent.data;

            if (validatePasswordCharacter(ew)) {
                const newValuePassword = password + ew;
                setPassword(newValuePassword);
                validateStrongPassword(newValuePassword);
            } else return;
        }
    }

    const change = () => {
        setLoading(true);
        const token = new URL(window.location.href).searchParams.get('token');

        instance.post('auth/reset-password-by-token', { password: password }, { headers: { 'authorization': "Bearer " + token } })
            .then((response: any) => {
                setLoading(false);
                activeModal.set('');
                snackbar.set(
                    <Snackbar
                        mode="dark"
                        onClose={() => snackbar.set(null)}
                        before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                    >
                        Вы успешно изменили свой пароль
                    </Snackbar>)

                window.history.pushState({}, 'main', '/');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e.response && e.response.data) {
                    setErrorMessageFromServer(e.response.data.message);
                } else {
                    setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
                }
            })
    }

    const changeType = (type: 'password' | 'text') => {
        setType(type);
    }


    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28LockOutline width={56} height={56} fill="#F3294A" />}
            header="Восстановление пароля"
            subheader="Введите ваш новый пароль"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            onClick={() => change()}
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            disabled={loading ? true : !(passwordLengthValidated && passwordLowerValidated && passwordUpperValidated && passwordNumberValidated && passwordSpecialValidated)}
                        >
                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : "Восстановить"}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            {errorMessageFromServer.length > 0 ? <Div>
                <FormStatus mode="error">
                    {errorMessageFromServer}
                </FormStatus>
            </Div> : <></>}

            <FormItem top="Пароль" htmlFor="pass" required>
                <Input
                    id="pass"
                    disabled={loading}
                    type={type}
                    placeholder="Придумайте пароль"
                    value={password}
                    onChange={onChangePassword}
                    before={<Icon28LockOutline fill="#818C99" />}
                    after={type === 'password' ? <Icon28PincodeLockOutline width={24} height={24} onClick={() => changeType('text')} /> : <Icon28PincodeOutline width={24} height={24} onClick={() => changeType('password')} />}
                />
            </FormItem>


            <Group header={<Header mode="secondary">Пароль должен содержать: </Header>}>
                <MiniInfoCell
                    before={passwordLowerValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                >
                    Минимум одну строчную букву (a-z)
                </MiniInfoCell>
                <MiniInfoCell
                    before={passwordUpperValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                >
                    Минимум одну заглавную букву (A-Z)
                </MiniInfoCell>
                <MiniInfoCell
                    before={passwordNumberValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                >
                    Минимум одну цифру (0-9)
                </MiniInfoCell>
                <MiniInfoCell
                    before={passwordSpecialValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                >
                    Минимум один спецсимвол
                </MiniInfoCell>
                <MiniInfoCell
                    before={passwordLengthValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                >
                    Не менее 8 символов
                </MiniInfoCell>
            </Group>
        </ModalCard>
    )
}

export default ResetPasswordByToken;