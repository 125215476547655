import { HorizontalScroll, Skeleton, Tabs, TabsItem } from "@vkontakte/vkui";
import React from "react";

import { tabs, activeTab } from '../storage/States';
import RequestToApi from "../api";

const LoadingTabs = () => {
    const loadingtabs = [];

    for (let i = 0; i < 9; i++) {
        loadingtabs.push(
            <TabsItem
                selected={false}
                key={"loadingTab" + i}
            >
                <Skeleton width={120} height={24} />
            </TabsItem>
        );
    }
    return <>{loadingtabs}</>;
}

const MenuTabs = () => {
    React.useEffect(() => {
        const fetchData = async () => {
            if (tabs.get().length === 0) {
                RequestToApi({ method: 'get', url: 'products' }, (data: any) => {
                    if(data.length !== 0) {
                        tabs.set(data);
                        activeTab.set(data[0].id);
                    }
                }, (e) => {
                    tabs.set([]);
                    activeTab.set(0);
                })
            } else return;
        }

        fetchData();
    }, []);

    return (
        <div className="TouchBubbleCarousel_root MobileRestaurantPageMenuPanel_carousel">
            <nav className="TouchBubbleCarousel_scroll">
                <div className="TouchBubbleCarousel_bubble"></div>
                <Tabs
                    mode="secondary"
                    layoutFillMode="auto"
                    withScrollToSelectedTab
                    scrollBehaviorToSelectedTab="nearest"
                >
                    <HorizontalScroll>
                        {tabs.get().length === 0 ? <LoadingTabs /> : tabs.get()?.map((tab => (<TabsItem selected={activeTab.get() === tab.id} onClick={() => activeTab.set(tab.id)} key={"tab" + tab.id}>{tab.name}</TabsItem>)))}
                    </HorizontalScroll>
                </Tabs>
            </nav>
        </div>
    )
}

export default MenuTabs;