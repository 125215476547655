import { ScreenSpinner, Snackbar } from "@vkontakte/vkui"
import { activeView, errorMessage, orderByDate, ordersForAdminPanel, popout, snackbar } from "../storage/States"
import { instance } from ".";
import { Icon28CheckCircleOutline, Icon28ErrorCircleOutline } from "@vkontakte/icons";

export const cancelOrder = (id: number) => {
    popout.set(<ScreenSpinner />);

    instance.delete('billing/cancel/' + id, {
        headers: { 'authorization': 'Bearer ' + localStorage.getItem('access_token') }
    }).then(response => {
        const { message } = response.data;

        instance.post('order/orders-by-date', { date: orderByDate.get() }, {
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        }).then(res => {
            ordersForAdminPanel.set(res.data);
            popout.set(null);
            snackbar.set(

                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                >
                    {message}
                </Snackbar>

            );
        })
        .catch(catchError)
    })
        .catch(catchError)
}

const catchError = (e: any) => {
    popout.set(null);
    if (e.response && e.response.data) {
        if (e.response.data.type === "WARN") {
            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
                >
                    {e.response.data.message}
                </Snackbar>)
        } else {
            errorMessage.set(e.response.data.message);
            activeView.set("errorView");
        }
    } else {
        activeView.set("errorView");
    }
}