import { Button, Div, FixedLayout, FormItem, FormLayoutGroup, FormStatus, Group, Header, Input, MiniInfoCell, Panel, PanelHeader, PanelHeaderBack, View, Spinner } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { actionWithBonuses, activeModal, activeStory, activeView, newCart, timeUntilTheNextEmail, userData, userEmail, userPassword } from "../storage/States";
import { AsYouType } from "libphonenumber-js";

import flag from '../lib/img/flag.svg';
import React from "react";
import { Icon28CancelCircleFillRed, Icon28CheckCircleFill, Icon28LockOutline, Icon28PincodeLockOutline, Icon28PincodeOutline } from "@vkontakte/icons";
import RequestToApi, { instance } from "../api";

const SignUpView = ({ id }: BaseProps) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isNotDisabled, setIsNotDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');

    const [phoneNumber, setPhoneNumber] = React.useState('+77');
    const [phoneNumberInputError, setPhoneNumberInputError] = React.useState(!(phoneNumber.length === 12));

    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState<string>('empty');

    const [password, setPassword] = React.useState('');
    const [type, setType] = React.useState('password');

    const [passwordLengthValidated, setPasswordLengthValidated] = React.useState(false);
    const [passwordLowerValidated, setPasswordLowerValidated] = React.useState(false);
    const [passwordUpperValidated, setPasswordUpperValidated] = React.useState(false);
    const [passwordNumberValidated, setPasswordNumberValidated] = React.useState(false);
    const [passwordSpecialValidated, setSpecialValidated] = React.useState(false);

    const ERRORS_MAP = {
        'empty': 'Пожалуйста, введите электронную почту',
        'incorrect': 'Электронная почта некорректна',
    };

    const validateEmail = (email: string) => {
        return email.match(
            /* eslint-disable-next-line */
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    };

    const onChangePhoneNumber = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (phoneNumber.length > 3) {
                setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 1));
                setPhoneNumberInputError(true);
            }
            else return;
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (phoneNumber.length < 12 && !isNaN(e.nativeEvent.data)) {
                setPhoneNumber(phoneNumber + e.nativeEvent.data);
                if (phoneNumber.length === 11) setPhoneNumberInputError(false);
            } else return;
        }
    }

    const updateEmail = (value: string) => {
        setEmail(value);
        if (!value) {
            setEmailError('empty');
        } else if (!validateEmail(value)) {
            setEmailError('incorrect');
        } else {
            setEmailError('');
        }
    };

    const validatePasswordCharacter = (s: string) => {
        const ew = s.charCodeAt(0);


        if (ew >= 32 && ew <= 33) return true;
        if (ew >= 35 && ew <= 38) return true;
        if (ew >= 48 && ew <= 57) return true;
        if (ew >= 64 && ew <= 90) return true;
        if (ew === 94) return true;
        if (ew >= 97 && ew <= 122) return true;

        return false;
    }

    const validateStrongPassword = (value: string) => {
        const lower = new RegExp('(?=.*[a-z])');
        const upper = new RegExp('(?=.*[A-Z])');
        const number = new RegExp('(?=.*[0-9])');
        /* eslint-disable-next-line */
        const special = new RegExp('(?=.*[!@#\$%\^&\*])');

        if (value.length >= 8) setPasswordLengthValidated(true);
        else setPasswordLengthValidated(false);

        if (lower.test(value)) setPasswordLowerValidated(true);
        else setPasswordLowerValidated(false);

        if (upper.test(value)) setPasswordUpperValidated(true);
        else setPasswordUpperValidated(false);

        if (number.test(value)) setPasswordNumberValidated(true);
        else setPasswordNumberValidated(false);

        if (special.test(value)) setSpecialValidated(true);
        else setSpecialValidated(false);

    }


    const onChangePassword = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            const newValuePassword = password.substring(0, password.length - 1);
            setPassword(newValuePassword);
            validateStrongPassword(newValuePassword);

        } else if (e.nativeEvent.inputType === 'insertText') {
            var ew = e.nativeEvent.data;

            if (validatePasswordCharacter(ew)) {
                const newValuePassword = password + ew;
                setPassword(newValuePassword);
                validateStrongPassword(newValuePassword);
            } else return;
        }
    }

    const onChangeFirstnameOrLastname = (e: any) => {
        const { id } = e.currentTarget;

        if (id === 'firstname') {
            setFirstname(e.target.value);
        }

        if (id === 'lastname') {
            setLastname(e.target.value);
        }
    }

    React.useEffect(() => {
        setIsNotDisabled(
            (firstname.length > 1)
            && (lastname.length > 1)
            && !phoneNumberInputError
            && (emailError.length === 0)
            && passwordLengthValidated
            && passwordLowerValidated
            && passwordUpperValidated
            && passwordNumberValidated
            && passwordSpecialValidated
        )
    }, [
        firstname.length,
        lastname.length,
        phoneNumberInputError,
        emailError.length,
        passwordLengthValidated,
        passwordLowerValidated,
        passwordUpperValidated,
        passwordNumberValidated,
        passwordSpecialValidated
    ]);

    const SignUp = () => {
        setErrorMessage("");
        setLoading(true);


        instance.post('auth/signup', {
            first_name: firstname,
            last_name: lastname,
            email: email,
            password: password,
            phone_number: phoneNumber
        }, { timeout: 3600000 })
            .then((res: any) => {
                console.log(res);
                localStorage.setItem("access_token", res.data.access_token);
                localStorage.setItem("refresh_token", res.data.refresh_token);

                RequestToApi({ method: 'get', url: 'user/profile', token: res.data.access_token }, (data) => {
                    RequestToApi({ method: 'post', url: 'auth/resend-time', data: { email: email, password: password } }, (response: any) => {
                        userData.set(data);
                        newCart.set(data.cart);
                        actionWithBonuses.set('GAIN');
                        userEmail.set(email);
                        userPassword.set(password);


                        timeUntilTheNextEmail.set(response.timeUntilTheNextEmail !== null ? response.timeUntilTheNextEmail : 0);
                        setLoading(false);
    
                        userEmail.set(email);
                        userPassword.set(password);
                        activeView.set('homeView');
                        activeStory.set('profile');
                        activeModal.set('necessaryToConfirmEmail');
                    })


                    activeView.set('homeView');
                    activeStory.set('profile');
                })
            
            })

            .catch((e) => {
                if (e.response && e.response.data) {
                    setLoading(false);
                    setErrorMessage(e.response.data.message);
                } else {
                    setLoading(false);
                    setErrorMessage('Произошли непредвиденные сложности при выполнении запроса');
                }
            })
    }

    const changeType = (type: 'password' | 'text') => {
        setType(type);
    }

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    delimiter="spacing"
                    before={<PanelHeaderBack onClick={() => activeView.set('homeView')} />}
                >
                    Регистрация
                </PanelHeader>

                {errorMessage.length !== 0 ?
                    <Div>
                        <FormStatus mode="error">
                            {errorMessage}
                        </FormStatus>
                    </Div>
                    : <></>}

                <Group>
                    <FormLayoutGroup mode="horizontal" segmented>
                        <FormItem htmlFor="name" top="Имя" required>
                            <Input id="firstname" value={firstname} placeholder="" onChange={onChangeFirstnameOrLastname} disabled={loading} />
                        </FormItem>
                        <FormItem htmlFor="lastname" top="Фамилия" required>
                            <Input id="lastname" value={lastname} placeholder="" onChange={onChangeFirstnameOrLastname} disabled={loading} />
                        </FormItem>
                    </FormLayoutGroup>

                    <FormItem top="Номер телефона"
                        status={phoneNumberInputError ? 'error' : 'valid'}
                        bottom={phoneNumberInputError ? "Пожалуйста, введите номер телефона в международном формате" : ""}
                        required
                    >
                        <Input
                            disabled={loading}
                            type="tel"
                            placeholder="Введите номер телефона"
                            value={new AsYouType('KZ').input(String(phoneNumber))} onChange={onChangePhoneNumber}
                            before={<img width={28} height={28} src={flag} alt="" />}
                        />
                    </FormItem>
                    <FormItem

                        htmlFor="email"
                        top="E-mail"
                        status={emailError ? 'error' : 'valid'}
                        bottom={emailError ? String(ERRORS_MAP[emailError === 'empty' ? 'empty' : 'incorrect']) : ''}
                        bottomId="email-type"
                        required
                    >
                        <Input
                            disabled={loading}
                            aria-labelledby="email-type"
                            id="email"
                            type="email"
                            name="email"
                            value={email}
                            required
                            onChange={(e) => updateEmail(e.target.value)}
                        />
                    </FormItem>

                    <FormItem top="Пароль" htmlFor="pass" required>
                        <Input
                            id="pass"
                            disabled={loading}
                            type={type}
                            placeholder="Придумайте пароль"
                            value={password}
                            onChange={onChangePassword}
                            before={<Icon28LockOutline fill="#818C99" />}
                            after={type === 'password' ? <Icon28PincodeLockOutline width={24} height={24} onClick={() => changeType('text')} /> : <Icon28PincodeOutline width={24} height={24} onClick={() => changeType('password')} />} />
                    </FormItem>


                    <Group header={<Header mode="secondary">Пароль должен содержать: </Header>} style={{ marginBottom: "20%" }}>
                        <MiniInfoCell
                            before={passwordLowerValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                        >
                            Минимум одну строчную букву (a-z)
                        </MiniInfoCell>
                        <MiniInfoCell
                            before={passwordUpperValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                        >
                            Минимум одну заглавную букву (A-Z)
                        </MiniInfoCell>
                        <MiniInfoCell
                            before={passwordNumberValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                        >
                            Минимум одну цифру (0-9)
                        </MiniInfoCell>
                        <MiniInfoCell
                            before={passwordSpecialValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                        >
                            Минимум один спецсимвол
                        </MiniInfoCell>
                        <MiniInfoCell
                            before={passwordLengthValidated ? <Icon28CheckCircleFill width={20} height={20} /> : <Icon28CancelCircleFillRed width={20} height={20} />}
                        >
                            Не менее 8 символов
                        </MiniInfoCell>
                    </Group>

                    <FixedLayout filled vertical="bottom">
                        <Group>
                            <Div>
                                <Button
                                    onClick={() => SignUp()}
                                    size="l"
                                    stretched
                                    style={{ backgroundColor: "#F3294A" }}
                                    disabled={loading ? true : !isNotDisabled}
                                >
                                    {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : 'Зарегистрироваться'}
                                </Button>
                            </Div>
                        </Group>
                    </FixedLayout>
                </Group>
            </Panel>
        </View>
    )
}

export default SignUpView;