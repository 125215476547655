import { Group, Panel, PanelHeader, PanelHeaderBack, Title, Text, Link, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, activeModal } from "../storage/States";

const DeliveryTermsView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader

                    before={<PanelHeaderBack onClick={() => { activeView.set('homeView'); activeModal.set('infoAboutRestaurant') }} />}
                >
                    Условия доставки
                </PanelHeader>
                <Group>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            1. Зоны доставки
                        </Title>
                        <Text>
                            Мы осуществляем доставку по указанным районам города. Пожалуйста, уточните возможность доставки в ваш район при оформлении заказа.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            2. Время доставки
                        </Title>
                        <Text>
                            Ожидаемое время доставки составляет от 30 до 60 минут, в зависимости от загруженности и расстояния до адреса. Мы стараемся соблюдать эти сроки и информировать вас о статусе вашего заказа.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            3. Стоимость доставки
                        </Title>
                        <Text>
                            Стоимость доставки рассчитывается индивидуально в зависимости от расстояния до вашего адреса. При оформлении заказа вам будет предложена вся необходимая информация.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            4. Оплата
                        </Title>
                        <Text>
                            Мы принимаем оплату только безналичным расчетом при оформлении заказа через сайт.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            5. Качество и свежесть
                        </Title>
                        <Text>
                            Мы гарантируем, что все блюда будут доставлены свежими и в идеальном состоянии. Если вы обнаружили недостатки в доставленном заказе, пожалуйста, свяжитесь с нашей службой поддержки в течение 30 минут.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            6. Контактная информация
                        </Title>
                        <Text>
                            Если у вас есть вопросы или пожелания, не стесняйтесь обращаться к нам по телефону: 
                            <br/> <Link href='tel:+77054618106'>+7 (705) 461-81-06</Link> или адрес электронной почты: <Link href="mailto:info@fujiyama.kz">info@fujiyama.kz</Link>.
                        </Text>
                    </div>
                </Group>
            </Panel>
        </View>
    )
}

export default DeliveryTermsView;