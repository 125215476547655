import { DateInput, Div, FormItem, HorizontalScroll, LocaleProvider, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, ScreenSpinner, SegmentedControl, Tabs, TabsItem, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { actionWithBonusesOrdersAdminPanel, activeModal, activeTabOrdersAdminPanel, activeView, orderByDate, ordersForAdminPanel, popout } from "../storage/States";
import { Icon20ClockOutline, Icon20CheckCircleOutline, Icon20CancelCircleOutline, Icon28ReplayOutline, Icon28MoneyRequestOutline, Icon28MoneySendOutline, Icon28IncognitoOutline, Icon28SearchOutline } from "@vkontakte/icons";
import React from "react";
import RenderOrdersForAdminPanel from "../elements/RenderOrdersForAdminPanel";
import RequestToApi from "../api";

const OrdersAdminPanelView = ({ id }: BaseProps) => {
    const [loading, SetLoading] = React.useState(false);

    const setDate = (d: Date | undefined) => {
        if (d !== undefined) {
            orderByDate.set(d);

            if (d !== undefined) getOrders();
        }
    }

    const getOrders = (cb?: () => void) => {
        if (cb === undefined) SetLoading(true);

        RequestToApi({ method: 'post', url: 'order/orders-by-date', token: localStorage.getItem('access_token'), data: { date: orderByDate.get() } }, (data) => {
            ordersForAdminPanel.set(data);

            setTimeout(() => SetLoading(false), 2000);
            if (cb) cb();
        }, (e) => {
            if (cb) cb();
        });
    }

    const changeStatusOrder = (orderId: number, status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
        const token = localStorage.getItem('access_token');
        popout.set(<ScreenSpinner state="loading" />);

        RequestToApi({ method: 'put', url: 'order/status', data: { orderId, status }, token: token }, (response) => {
            getOrders(() => popout.set(null));
        }, (e) => popout.set(null))
    }

    React.useEffect(() => {
        if (ordersForAdminPanel.get().length === 0) getOrders();
    }, []);

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => { activeView.set('adminView'); ordersForAdminPanel.set([]) }} />}
                    after={<React.Fragment>
                        <PanelHeaderButton onClick={() => getOrders()}><Icon28ReplayOutline /></PanelHeaderButton>
                        <PanelHeaderButton onClick={() => activeModal.set('infoAboutOrderById')}><Icon28SearchOutline /></PanelHeaderButton>
                    </React.Fragment>}
                >
                    Заказы
                </PanelHeader>
                <FormItem>

                    <LocaleProvider value='ru'>
                        <DateInput
                            onChange={setDate}
                            value={orderByDate.get()}
                            disableFuture={true}
                            closeOnChange
                        />
                    </LocaleProvider>

                </FormItem>
                <Tabs
                    mode="accent"
                    layoutFillMode="stretched"
                    withScrollToSelectedTab
                    scrollBehaviorToSelectedTab="center"
                >
                    <HorizontalScroll arrowSize="m">
                        <TabsItem
                            before={<Icon20ClockOutline />}
                            selected={activeTabOrdersAdminPanel.get() === 'AWAITING_CONFIRMATION'}
                            onClick={() => activeTabOrdersAdminPanel.set('AWAITING_CONFIRMATION')}
                        >
                            Ожидают оплаты
                        </TabsItem>
                        <TabsItem
                            before={<Icon20CheckCircleOutline />}
                            onClick={() => activeTabOrdersAdminPanel.set('CONFIRMED')}
                            selected={activeTabOrdersAdminPanel.get() === 'CONFIRMED'}
                        >
                            Оплачены
                        </TabsItem>
                        <TabsItem
                            before={<Icon20CancelCircleOutline />}
                            onClick={() => activeTabOrdersAdminPanel.set('NOT_CONFIRMED')}
                            selected={activeTabOrdersAdminPanel.get() === 'NOT_CONFIRMED'}
                        >
                            Отменененные заказы
                        </TabsItem>
                    </HorizontalScroll>
                </Tabs>
                <Div>
                    <SegmentedControl
                        size="m"
                        name="type"
                        value={actionWithBonusesOrdersAdminPanel.get()}
                        onChange={(value) => actionWithBonusesOrdersAdminPanel.set(String(value))}
                        options={[
                            {
                                label: <Icon28MoneyRequestOutline width={24} height={24} />,
                                value: 'GAIN'
                            },
                            {
                                label: <Icon28IncognitoOutline width={24} height={24} />,
                                value: 'NOT_AUTHORIZED'
                            },
                            {
                                label: <Icon28MoneySendOutline width={24} height={24} />,
                                value: 'PAY'
                            },
                        ]}
                    />
                </Div>
                <Div>
                    <RenderOrdersForAdminPanel loading={loading} changeStatus={changeStatusOrder} />
                </Div>
            </Panel>
        </View>
    )
}

export default OrdersAdminPanelView;