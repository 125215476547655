import { Alert, Banner, Div, Group, Header, Panel, ScreenSpinner, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { actionWithBonuses, activeModal, activeStory, activeView, lastOrderId, newCart, popout, timeUntilTheNextEmail, transactions, userData, userEmail, userPassword } from "../storage/States";
import { Icon20BrushOutline, Icon28SmartphoneOutline, Icon28LockOutline, Icon28MarketOutline, Icon28MentionOutline, Icon28DoorArrowRightOutline } from '@vkontakte/icons';

import { AsYouType } from 'libphonenumber-js';

import background from '../lib/img/backgroundProfile.jpg';
import RequestToApi from "../api";


const ProfileView = ({ id }: BaseProps) => {
    
    const changeEmail = () => {
        const token = localStorage.getItem('access_token');
        popout.set(<ScreenSpinner state="loading" />);

        RequestToApi({ method: 'get', url: 'user/profile', token: token }, (response) => {
            userData.set(response);

            RequestToApi({ method: 'get', url: 'auth/resend-time', token: token }, (res) => {
                timeUntilTheNextEmail.set(res.timeUntilTheNextEmail);
                popout.set(null);

                if (!userData.get()!.isActivate) {
                    activeModal.set('necessaryToConfirmAndEditEmail');
                } else {
                    activeModal.set('changeEmail');
                }

            }, (e) => popout.set(null));

        }, (e) => popout.set(null))

    }

    const exitFromAccountAlert = () => {
        popout.set(
            <Alert
                actions={[
                    {
                        title: 'Отмена',
                        mode: 'cancel',
                    },
                    {
                        title: 'Выйти',
                        mode: 'destructive',
                        action: () => {
                            userEmail.set("");
                            userPassword.set("");
                            newCart.set([]);
                            userData.set(null);
                            timeUntilTheNextEmail.set(null);
                            transactions.set(null);
                            actionWithBonuses.set('NOT_AUTHORIZED');
                            lastOrderId.set(0);

                            localStorage.removeItem('access_token');
                            localStorage.removeItem('refresh_token');

                            activeStory.set("menu");
                        }
                    },
                ]}
                actionsLayout="horizontal"
                dismissButtonMode="inside"
                onClose={() => popout.set(null)}
                header="Выход из аккаунта"
                text="Вы уверены, что хотите выйти из аккаунта?"
            />
        )
    }


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <div className="MobileUILoader_root MobileApp_root" >
                    <div className="MobileUILoader_content MobileApp_content">
                        <div className="MobileApp_safeAreaBlind">
                            <div className="MobilePlacePageRestaurant_root MobileDefaultLayout_container">
                                <div className="RestaurantPageNewHeaderTopBar_topBarContainer">
                                    <div className="RestaurantPageNewHeaderTopBar_transparentTB">
                                        <button className="RestaurantPageNewHeaderTopBar_headBtn HeaderTheme_default" onClick={() => exitFromAccountAlert()}><Icon28DoorArrowRightOutline width={20} height={20} /></button>
                                    </div>
                                </div>
                                <div className="HeaderContainer_body">
                                    <div className="SmartImage_root MobileHeroPhoto_root">
                                        <img src={background} alt="Fujiyama" className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" />
                                    </div>
                                    <div className="HeaderContainer_gradient"></div>
                                    <div className="HeaderContainer_padding">
                                        <h1 className="HeaderContainer_name short">{userData.get()?.firstName + " " + userData.get()?.lastName}</h1>
                                        <div className="HeaderContainer_blurBackground"></div>
                                        <div className="HeaderContainer_actionsWrap">
                                            <ul className="HeaderContainer_actions">
                                                <div className="RestaurantPageNewHeader_directionWrapper RestaurantPageNewHeader_horizontal">
                                                    <button className="ActionBubble_bubble ActionBubble_default ActionBubble_animatedBubble HeaderTheme_default" onClick={() => activeModal.set('changeName')}>
                                                        <div className="ActionBubble_icon"><Icon20BrushOutline width={20} height={20} /></div>
                                                        <div className="ActionBubble_description">
                                                            <big>Редактировать</big>
                                                        </div>
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <main className="MobileRestaurantPageMenu_root MobileRestaurantPageMenu_rounded" >
                                    <div></div>
                                    <div className="MobileShadowableSticky_root MobileRestaurantPageMenu_panel">
                                        <div>
                                            <div className="MobileRestaurantPageMenuPanel_root">
                                                <div className="MobileRestaurantPageMenuPanel_carouselWrapper">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20%" }}>
                                        <Group header={<Header mode="secondary">Информация о пользователе</Header>}>
                                            <Div>
                                                <Banner
                                                    before={<Icon28SmartphoneOutline fill="#F3294A" />}
                                                    header="Номер телефона"
                                                    subheader={new AsYouType('KZ').input(String(userData.get()?.phoneNumber))}
                                                    asideMode="expand"
                                                    onClick={() => activeModal.set('changePhoneNumber')}
                                                />
                                            </Div>
                                            <Div>
                                                <Banner
                                                    before={<Icon28MentionOutline fill="#F3294A" />}
                                                    header="E-mail"
                                                    subheader={<div>{userData.get()?.email}</div>}
                                                    asideMode="expand"
                                                    onClick={() => changeEmail()}
                                                />
                                            </Div>
                                        </Group>
                                        <Group header={<Header mode="secondary">Действия</Header>}>
                                            <Div>
                                                <Banner
                                                    before={<Icon28MarketOutline fill="#F3294A" />}
                                                    header="Мои заказы"
                                                    subheader="Посмотреть прошлые заказы"
                                                    asideMode="expand"
                                                    onClick={() => activeView.set('myOrdersView')}
                                                />
                                            </Div>
                                            <Div>
                                                <Banner
                                                    before={<Icon28LockOutline fill="#F3294A" />}
                                                    header="Пароль"
                                                    subheader="Изменить пароль для входа"
                                                    asideMode="expand"
                                                    onClick={() => activeModal.set('changePassword')}
                                                />
                                            </Div>
                                        </Group>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </View>
    )
}

export default ProfileView;