import { Icon28CheckCircleOutline, Icon28FolderSimplePlusOutline } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, FormItem, Input, Div, FormStatus, Spinner, Snackbar } from "@vkontakte/vkui";
import React from "react";
import BaseProps from "../props/BaseProps";
import { activeModal, activeTab, snackbar, tabs } from "../storage/States";
import { instance } from "../api";

const AddNewCategory = ({ id }: BaseProps) => {
    const [name, setName] = React.useState('');
    const [errorMessageFromServer, setErrorMessageFromServer] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const onChangeName = (e: any) => setName(e.target.value);

    const add = () => {
        const token = localStorage.getItem('access_token');

        setErrorMessageFromServer('');
        setLoading(true);

        instance.post('products/category', { name }, { headers: { 'authorization': 'Bearer ' + token } })
            .then(() => {
                instance.get('products', { headers: { 'authorization': 'Bearer ' + token } })
                    .then((response) => {
                        tabs.set(response.data);
                        activeTab.set(response.data[0].id);
                        activeModal.set('');
                        snackbar.set(
                            <Snackbar
                                mode="dark"
                                onClose={() => snackbar.set(null)}
                                before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                            >
                                Вы успешно добавили новую категорию
                            </Snackbar>
                        )
                    })
                    .catch(errorHandling);
            })
            .catch(errorHandling);
    }

    const errorHandling = (e: any) => {
        console.log(e);
        setLoading(false);

        if (e.response && e.response.data) {
            setErrorMessageFromServer(e.response.data.message);
        } else {
            setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
        }
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28FolderSimplePlusOutline width={56} height={56} fill="#F3294A" />}
            header="Добавление новой категории"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            disabled={loading ? loading : !(name.length !== 0)}
                            onClick={() => add()}
                        >

                            {loading ? <Spinner size="regular" style={{ color: "#fff" }} /> : "Добавить"}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            {errorMessageFromServer.length > 0 ? <Div>
                <FormStatus mode="error">
                    {errorMessageFromServer}
                </FormStatus>
            </Div> : <></>}
            <FormItem>
                <Input placeholder="Введите название категории" type="text" value={name} onChange={onChangeName} />
            </FormItem>
        </ModalCard>
    )
}

export default AddNewCategory;