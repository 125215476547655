import React, { memo, ReactElement } from "react";
import { AdaptivityProvider, AppRoot, ConfigProvider } from "@vkontakte/vkui";

import { useMediaQuery } from 'react-responsive';
import TabletOrDesktop from "./TabletOrDesktop";

interface ProvidersProps {
    children: ReactElement;
}

export const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }

    return "vkcom";
}

const Providers = memo(function Providers({ children }: ProvidersProps): ReactElement {
    const isTabletOrDesktop = useMediaQuery({
        query: "(min-width: 768px)"
    });

    const render =  !isTabletOrDesktop ? (
        <ConfigProvider platform={getMobileOperatingSystem()} appearance="light" isWebView>
            <AdaptivityProvider>
                <AppRoot>
                    {children}
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>) : (<TabletOrDesktop />)


    return render;
});

export default Providers;