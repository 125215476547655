import { Icon28FavoriteCircleFillGreen } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, FormItem, SimpleCell, Switch, ScreenSpinner, Textarea } from "@vkontakte/vkui";
import React from "react";
import BaseProps from "../props/BaseProps";
import { activeModal, allPromotions, editablePromotionId, popout } from "../storage/States";
import RequestToApi from "../api";

const EditPromotion = ({ id }: BaseProps) => {
    const promotion = allPromotions.get().find(promo => promo.id === editablePromotionId.get());

    const [text, setText] = React.useState(promotion?.text);
    const [isActive, setIsActive] = React.useState(promotion?.isActive);

    const editPromo = () => {
        activeModal.set('');
        popout.set(<ScreenSpinner />);

        const data = {
            text: text,
            isActive: isActive
        }

        const token = localStorage.getItem('access_token');

        RequestToApi({ method: 'put', url: 'promotion/' + promotion!.id, data: data, token: token }, (response) => {
            RequestToApi({ method: 'get', url: 'promotion/all', token: token }, (data) => {
                allPromotions.set(data);
                popout.set(null);
            }, (e) => popout.set(null));
        }, (e) => popout.set(null));
    }

    const deletePromo = () => {
        const token = localStorage.getItem('access_token');

        activeModal.set('');
        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'delete', url: 'promotion/' + promotion!.id, token: token }, (response) => {
            RequestToApi({ method: 'get', url: 'promotion/all', token: token }, (data) => {
                console.log(data);
                allPromotions.set(data);
                popout.set(null);
            }, (e) => popout.set(null));
        })
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28FavoriteCircleFillGreen width={56} height={56} fill="#F3294A" />}
            header="Редактирование"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="horizontal" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => editPromo()}
                        >

                            Изменить
                        </Button>
                        <Button
                            appearance="negative"
                            key="join"
                            size="l"
                            mode="outline"
                            stretched
                            onClick={() => deletePromo()}
                        >

                            Удалить
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            <FormItem>
                <Textarea placeholder="Введите описание акции" value={text} onChange={(e) => setText(e.target.value)} />
            </FormItem>
            <FormItem>
                <SimpleCell after={<Switch defaultChecked={isActive} onClick={() => setIsActive(!isActive)} />}>Акция актуальна?</SimpleCell>
            </FormItem>
        </ModalCard>
    )
}

export default EditPromotion;