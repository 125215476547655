import { Avatar, ButtonGroup, Footer, Group, Header, IconButton, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, ScreenSpinner, SimpleCell, Skeleton, Spacing, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, activeView, deliveryMethods, editabledDeliveryMethodId, popout } from "../storage/States";
import { Icon28EditOutline, Icon28MotorcycleOutline, Icon28CancelOutline, Icon28AddOutline } from "@vkontakte/icons";
import React from "react";
import RequestToApi from "../api";

const RenderLoadingDeliveryMethods = () => {
    const render = [];

    for (let i = 0; i < 10; i++) {
        render.push(
            <SimpleCell
                key={"delivery_render_" + Math.random()}
                before={<Skeleton width={48} height={48} borderRadius="50%" />}
                subtitle={<Skeleton width={90} />}
                after={<ButtonGroup gap="m" mode="horizontal" stretched>
                    <Skeleton width={36} height={36} borderRadius="50%" />
                    <Skeleton width={36} height={36} borderRadius="50%" />
                </ButtonGroup>}
            >
                <Skeleton width={120} />
            </SimpleCell>
        )
    }

    return <>{render}</>
}

const DeliveryMethodsView = ({ id }: BaseProps) => {
    const [loading, SetLoading] = React.useState(deliveryMethods.get().length !== 0 ? false : true);

    const getDeliveryMethods = () => {
        RequestToApi({ method: 'get', url: 'order/delivery' }, (data) => {
            deliveryMethods.set(data);
            setTimeout(() => SetLoading(false), 2000);
        })
    }


    React.useEffect(() => {
        if (deliveryMethods.get().length === 0) {
            getDeliveryMethods();
        }
    });

    const deleteDeliveryMethod = (id: number) => {
        popout.set(<ScreenSpinner />);

        RequestToApi({ method: 'delete', url: 'order/delivery/' + id, token: localStorage.getItem('access_token') }, (data) => {
            RequestToApi({ method: 'get', url: 'order/delivery' }, (data) => {
                deliveryMethods.set(data);
                popout.set(null);
            })
        }, (e) => popout.set(null))
    }

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader
                    before={<PanelHeaderBack onClick={() => activeView.set('adminView')} />}
                    after={<PanelHeaderButton onClick={() => activeModal.set('addDeliveryMethod')}><Icon28AddOutline /></PanelHeaderButton>}
                >
                    Доставка
                </PanelHeader>
                <Group header={<Header mode="secondary">Методы доставки</Header>}>
                    {deliveryMethods.get().length !== 0 && !loading ? deliveryMethods.get().map((method, index) => (
                        <>
                            {index !== 0 ? <Spacing size={16} key={"spacind_" + method.id + "_" + method.name} /> : <></>}
                            <SimpleCell
                                key={method.id + "_" + method.name + Math.random()}
                                before={<Avatar size={48}><Icon28MotorcycleOutline fill="#F3294A" /></Avatar>}
                                subtitle={method.cost.toLocaleString('ru-RU') + " ₸"}
                                after={
                                    <ButtonGroup gap="m" mode="horizontal" stretched>
                                        <IconButton label="Редактировать" onClick={() => {
                                            editabledDeliveryMethodId.set(method.id);
                                            activeModal.set('editDeliveryMethod');
                                        }}>
                                            <Icon28EditOutline />
                                        </IconButton>
                                        <IconButton label="Удалить" onClick={() => deleteDeliveryMethod(method.id)}>
                                            <Icon28CancelOutline fill="#F3294A" />
                                        </IconButton>
                                    </ButtonGroup>
                                }
                            >
                                {method.name}
                            </SimpleCell>
                        </>
                    )) : loading ? <RenderLoadingDeliveryMethods /> : <Footer>Методы доставки не найдены</Footer>}
                </Group>
            </Panel>
        </View>
    )
}

export default DeliveryMethodsView;