import React from 'react';
import { Icon28CheckCircleOutline } from '@vkontakte/icons';
import { ScreenSpinner, Snackbar } from '@vkontakte/vkui';
import { activeTab, popout, snackbar, tabs } from '../storage/States';
import RequestToApi from './index';

export const getCategoriesAndProducts = async () => {
    return await RequestToApi({ url: "products", method: "get" });
}

export const setAvailabilityProduct = async (product_id: number, availability: boolean) => {
    popout.set(<ScreenSpinner />);
    return RequestToApi({ method: 'put', url: ('products/' + product_id + '/availability'), data: { availability }, token: localStorage.getItem('access_token')}, (response) => {
        RequestToApi({ method: 'get', url: 'products' }, (data) => {
            popout.set(null);
            snackbar.set(
                <Snackbar
                    mode="dark"
                    onClose={() => snackbar.set(null)}
                    before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                >
                    Успешно!
                </Snackbar>
            )
            tabs.set(data);
        }, (e) => popout.set(null))
    }, (e) => popout.set(null))
}


export const getCategoriesAndProductsWithCallback = (callback: () => void) => {
    return RequestToApi({ method: 'get', url: 'products' }, (data) => {
        tabs.set(data);
        activeTab.set(data[0].id);

        callback();
    })
}