import RequestToApi from "../api";
import { activeStory, newCart, tabs, userData } from "../storage/States";
import NewCartType from "../types/NewCartType";
import ProductType from "../types/ProductType";
import ProductInOrderType from '../types/ProductInOrderType';

export const getProductById = (id: number) => {
    const categoriesAndProducts = tabs.get();

    let product: ProductType | undefined;

    for (let i = 0; i < categoriesAndProducts.length; i++) {
        const findProduct = categoriesAndProducts[i].products.find(product => product.id === id);
        if (findProduct !== undefined) {
            product = findProduct;
            break;
        }
    }

    if (product) return product;
    else return null;
}

const updateCart = () => {
    const access_token = localStorage.getItem("access_token");

    if (userData.get() && access_token) {
        RequestToApi({ method: 'post', url: 'user/cart', data: { cart: newCart.get() }, token: access_token });
    } else return;
}

export const getProductByIdInCart = (id: number) => {
    return newCart.get().find(product => product.productId === id);
}

export const deleteProductFromCart = (id: number) => {
    const productInCart = getProductByIdInCart(id);

    if (productInCart) {
        const getCart = newCart.get();

        for (let i = 0; i < getCart.length; i++) {
            if (getCart[i].productId === id) {
                if (getCart[i].count > 1) {
                    getCart[i].count--;
                    break;
                } else {
                    getCart.splice(i, 1);
                    break;
                }
            } else continue;
        }

        newCart.set(getCart);

        updateCart();

        if (getCart.length === 0) activeStory.set("menu");

        return;
    } else return;
}

export const addProductInCart = (id: number) => {
    const findProductInCart = getProductByIdInCart(id);
    const findProductInMenu = getProductById(id);
    const getCart = newCart.get();

    if (findProductInMenu) {
        if (findProductInCart) {
            getCart.forEach(element => {
                if (element.productId === id) {
                    element.count += 1
                }
            });
        } else {
            getCart.push({
                productId: id,
                count: 1
            });
        }

        newCart.set(getCart);

        updateCart();

        return;
    } else return;
}

export const addProductsInCart = (products: ProductInOrderType[]) => {
    const productsInCart: NewCartType[] = [];

    for(let i = 0; i < products.length; i++) {
        productsInCart.push({
            productId: products[i].productId,
            count: products[i].count
        })
    }

    newCart.set(productsInCart);
    updateCart();

    return;
}

export const checkAvaivability = (products: NewCartType[]) => {
    const productsInCart: NewCartType[] = [];

    for(let i = 0; i < products.length; i++) {
        const product = getProductById(products[i].productId);

        if(product && product.availability) {
            productsInCart.push(products[i])
        } else continue;
    }

    newCart.set(productsInCart);
    
    updateCart();

    return productsInCart;
}

export const clearCart = () => {
    newCart.set([]);
    updateCart();
    activeStory.set("menu");
    return;
}