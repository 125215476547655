import { Button, ButtonGroup, ModalCard, Spacing, Link } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { Icon56UserCircleOutline } from "@vkontakte/icons";
import React from "react";
import { activeModal } from "../storage/States";

const OrderCannotBeCancelled = ({ id }: BaseProps) => {
    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon56UserCircleOutline fill="#F3294A" />}
            header="Ваш заказ уже принят"
            subheader={<>Для того чтобы уточнить возможность отмены заказа, вы можете с нами связаться по номеру телефона: <br /><Link href='tel:+77054618106'>+7 (705) 461-81-06</Link></>}
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => window.open('tel:+77054618106', '_self')}
                            
                        >
                            Позвонить
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default OrderCannotBeCancelled;