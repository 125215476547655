import { HorizontalScroll, Panel, Skeleton, Tabs, TabsItem, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { percentOfBonuses, transactions } from "../storage/States";
import { Icon56CoinsStacks3Outline, Icon20ReplayOutline } from '@vkontakte/icons';

import background from '../lib/img/backgroundBalance.jpg';
import React from "react";
import RequestToApi from "../api";
import ListOfTransactions from "../elements/ListOfTransactions";

const BalanceView = ({ id }: BaseProps) => {
    const [activeTab, setActiveTab] = React.useState<'GAIN' | 'PAY'>('GAIN');
    const [loading, setLoading] = React.useState(false);
  
    const getTransactions = () => {
        setLoading(true);

        RequestToApi({
            method: 'get',
            url: 'user/balance',
            token: localStorage.getItem('access_token')
        }, (data: any) => {
            transactions.set(data ? data : null);
            setLoading(false);
        })
    }

    React.useEffect(() => {
        if(transactions.get() === null) getTransactions();
    }, [])

    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <div className="MobileUILoader_root MobileApp_root" >
                    <div className="MobileUILoader_content MobileApp_content">
                        <div className="MobileApp_safeAreaBlind">
                            <div className="MobilePlacePageRestaurant_root MobileDefaultLayout_container">
                                <div className="HeaderContainer_body">
                                    <div className="SmartImage_root MobileHeroPhoto_root">
                                        <img src={background} alt="Fujiyama" className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" />
                                    </div>
                                    <div className="HeaderContainer_gradient"></div>
                                    <div className="HeaderContainer_padding">
                                        <h1 className="HeaderContainer_name short">
                                            {loading ? <Skeleton width={125} colorFrom="#000" colorTo="#fff" style={{ opacity: "0.5" }} /> :
                                                <div style={{ display: 'flex' }}>{transactions.get()?.balance.toLocaleString('ru-RU')} Б <div style={{ marginLeft: "3%" }}><Icon20ReplayOutline width={20} height={20} onClick={() => getTransactions()} /></div></div>
                                            }
                                        </h1>
                                        <div className="HeaderContainer_blurBackground"></div>
                                        <div className="HeaderContainer_actionsWrap">
                                            <ul className="HeaderContainer_actions">
                                                <div className="RestaurantPageNewHeader_directionWrapper RestaurantPageNewHeader_horizontal">
                                                    <button className="ActionBubble_bubble ActionBubble_default ActionBubble_animatedBubble HeaderTheme_default">
                                                        <div className="ActionBubble_icon"><Icon56CoinsStacks3Outline width={20} height={20} /></div>
                                                        <div className="ActionBubble_description">
                                                            <big>{percentOfBonuses.get().toLocaleString('ru-RU')} %</big>
                                                            <small>от суммы заказа</small>
                                                        </div>
                                                    </button>

                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <main className="MobileRestaurantPageMenu_root MobileRestaurantPageMenu_rounded" >
                                    <div></div>
                                    <div className="MobileShadowableSticky_root MobileRestaurantPageMenu_panel">
                                        <div>
                                            <div className="MobileRestaurantPageMenuPanel_root">
                                                <div className="MobileRestaurantPageMenuPanel_carouselWrapper">
                                                    <div className="TouchBubbleCarousel_root MobileRestaurantPageMenuPanel_carousel">
                                                        <nav className="TouchBubbleCarousel_scroll">
                                                            <div className="TouchBubbleCarousel_bubble"></div>
                                                            <Tabs
                                                                mode="secondary"
                                                                layoutFillMode="auto"
                                                                withScrollToSelectedTab
                                                                scrollBehaviorToSelectedTab="nearest"
                                                            >
                                                                <HorizontalScroll>
                                                                    <TabsItem
                                                                        selected={activeTab === 'GAIN'}
                                                                        onClick={() => setActiveTab('GAIN')}
                                                                        id="tab-recommendations"
                                                                        aria-controls="tab-content-recommendations"
                                                                    >
                                                                        Заработано
                                                                    </TabsItem>
                                                                    <TabsItem
                                                                        selected={activeTab === 'PAY'}
                                                                        onClick={() => setActiveTab('PAY')}
                                                                        id="tab-recommendations"
                                                                        aria-controls="tab-content-recommendations"
                                                                    >
                                                                        Потрачено
                                                                    </TabsItem>
                                                                </HorizontalScroll>
                                                            </Tabs>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "20%" }}>
                                        <ListOfTransactions activeTab={activeTab} loading={loading} />
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </View>
    )
}

export default BalanceView;