import { Group, Link, Panel, PanelHeader, PanelHeaderBack, Title, Text, View } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeView, activeModal } from "../storage/States";

const OfferView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader

                    before={<PanelHeaderBack onClick={() => { activeView.set('homeView'); activeModal.set('infoAboutRestaurant') }} />}
                >
                    Публичная оферта
                </PanelHeader>

                <Group>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            1. Определения
                        </Title>
                        <Text>
                            Настоящая публичная оферта (далее — Оферта) является официальным предложением кафетерия «Фудзияма» (далее — Исполнитель) заключить договор на оказание услуг общественного питания с любым физическим или юридическим лицом (далее — Заказчик), который принял условия данной Оферты.
                        </Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            2. Общие положения
                        </Title>
                        <Text>
                            2.1. Оферта размещена на официальном сайте кафетерия «Фудзияма»: <Link href="https://fujiyama.kz/">fujiyama.kz</Link>.
                            <br />
                            <br />
                            2.2. Акцепт этой Оферты Заказчиком означает полное и безоговорочное согласие с её условиями.
                            <br />
                            <br />
                            2.3. Оферта является публичной и действует до момента её отзыва.
                        </Text>
                    </div>

                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            3. Предмет оферты
                        </Title>
                        <span>3.1. Исполнитель обязуется предоставить Заказчику услуги по организации питания, включая, но не ограничиваясь:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                приготовлением и реализацией блюд и напитков;
                            </li>
                            <li style={{ marginBottom: 15 }}>
                                организацией питания на вынос;
                            </li>
                            <li style={{ marginBottom: 15 }}>
                                возможностью доставки заказов на дом или в офис.
                            </li>
                        </ul>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            4. Условия заказа
                        </Title>
                        <span>4.1. Заказ услуг осуществляется через:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                сайт в сети кафетерия «Фудзияма»;
                            </li>
                            <li style={{ marginBottom: 15 }}>
                                по телефону, указанному на сайте;
                            </li>
                            <li style={{ marginBottom: 15 }}>
                                непосредственно при посещении кафе.
                            </li>
                        </ul>
                        <br />
                        <Text>4.2. При размещении заказа Заказчик обязуется предоставить точную информацию о предпочтениях и количестве людей.</Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            5. Цены и оплата
                        </Title>
                        <Text>5.1. Цены на услуги и товары указаны на сайте кафе и могут изменяться. Полная информация о ценах предоставляется Заказчику при размещении заказа.</Text>
                        <br />
                        <span>5.2. Оплата услуг осуществляется:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                безналичным расчетом через онлайн-оплату при заказе на сайте.
                            </li>
                            <li style={{ marginBottom: 15 }}>
                                наличным/безналичным расчетом при заказе непосредственно в кафе;
                            </li>
                        </ul>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            6. Права и обязанности сторон
                        </Title>
                        <span>6.1. Исполнитель обязуется:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                предоставлять услуги в соответствии с заказом;
                            </li>
                            <li>
                                соблюдать условия настоящей оферты.
                            </li>
                        </ul>
                        <br />
                        <span>6.2. Заказчик обязуется:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                своевременно оплачивать услуги;
                            </li>
                            <li>
                                предоставлять достоверную информацию по заказу.
                            </li>
                        </ul>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            7. Ответственность сторон
                        </Title>
                        <Text>7.1. Исполнитель не несет ответственности за убытки, возникшие в случае неполной или недостоверной информации со стороны Заказчика.</Text>
                    </div>

                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            8. Заключительные положения
                        </Title>
                        <Text>8.1. Эта оферта вступает в силу с момента её опубликования на сайте кафетерия «Фудзияма».</Text>
                        <br />
                        <Text>8.2. Исполнитель вправе в любое время изменять условия оферты, при этом изменения вступают в силу с момента их публикации на сайте.</Text>
                    </div>
                    <div style={{ padding: 20 }}>
                        <Title level="2" style={{ marginBottom: 16 }}>
                            9. Контактная информация
                        </Title>
                        <span>Для получения дополнительной информации Заказчики могут обращаться:</span>
                        <ul>
                            <li style={{ marginBottom: 15 }}>
                                по телефону: <Link href='tel:+77054618106'>+7 (705) 461-81-06</Link>
                            </li>
                            <li>
                                по адресу электронной почты: <Link href='mailto:info@fujiyama.kz'>info@fujiyama.kz</Link>.
                            </li>
                        </ul>
                    </div>
                </Group>
            </Panel>
        </View>
    )
}
export default OfferView;