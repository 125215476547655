import React from 'react';
import { Panel, View } from "@vkontakte/vkui";
import { Icon28MotorcycleOutline, Icon20InfoCircleOutline, Icon20More } from "@vkontakte/icons";

import BaseProps from "../props/BaseProps";
import background from '../lib/img/background.png';
import MenuTabs from "../elements/MenuTabs";
import ProductCards from "../elements/ProductCards";
import { activeModal } from "../storage/States";
import RenderPromotions from "../elements/RenderPromotions";

const MenuView = ({ id }: BaseProps) => {
    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <div className="MobileUILoader_root MobileApp_root" >
                    <div className="MobileUILoader_content MobileApp_content">
                        <div className="MobileApp_safeAreaBlind">
                            <div className="MobilePlacePageRestaurant_root MobileDefaultLayout_container">
                                <div className="HeaderContainer_body">
                                    <div className="SmartImage_root MobileHeroPhoto_root">
                                        <img src={background} alt="Fujiyama" className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" />
                                    </div>
                                    <div className="HeaderContainer_gradient"></div>
                                    <div className="HeaderContainer_padding">
                                        <h1 className="HeaderContainer_name short">Фудзияма</h1>
                                        <div className="HeaderContainer_blurBackground"></div>
                                        <div className="HeaderContainer_actionsWrap">
                                            <ul className="HeaderContainer_actions">
                                                <div className="RestaurantPageNewHeader_directionWrapper RestaurantPageNewHeader_horizontal">
                                                    <button className="ActionBubble_bubble ActionBubble_default ActionBubble_animatedBubble HeaderTheme_default">
                                                        <div className="ActionBubble_icon"><Icon28MotorcycleOutline width={20} height={20} /></div>
                                                        <div className="ActionBubble_description">
                                                            <big>30 – 60</big>
                                                            <small>мин</small>
                                                        </div>
                                                    </button>
                                                    <button className='ActionBubble_bubble ActionBubble_default ActionBubble_animatedBubble HeaderTheme_default RestaurantPageNewHeader_ratingBubble' onClick={() => activeModal.set('infoAboutRestaurant')}>
                                                        <div className='ActionBubble_icon'><Icon20InfoCircleOutline /></div>
                                                        <div className='ActionBubble_description'>
                                                            <big>Инфо</big>
                                                            <small>о ресторане</small>
                                                        </div>
                                                    </button>
                                                    <button className='ActionBubble_bubble ActionBubble_small ActionBubble_animatedBubble HeaderTheme_default' onClick={() => activeModal.set('aboutUs')}>
                                                        <div className='ActionBubble_icon'><Icon20More /></div>
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <main className="MobileRestaurantPageMenu_root MobileRestaurantPageMenu_rounded" >
                                    <div></div>
                                    <div className="MobileShadowableSticky_root MobileRestaurantPageMenu_panel">
                                        <div>
                                            <div className="MobileRestaurantPageMenuPanel_root">
                                                <div className="MobileRestaurantPageMenuPanel_carouselWrapper">
                                                    <MenuTabs />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <RenderPromotions />
                                    <div style={{ marginBottom: "20%" }}>
                                        <ProductCards />
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </View>
    )
}

export default MenuView;