import React from 'react';
import { Skeleton } from '@vkontakte/vkui';
import { Icon24Add, Icon28MinusOutline } from '@vkontakte/icons';

import { tabs, activeTab, windowWidth, orientation, idAboutProduct, activeModal } from '../storage/States';
import { productPhotoUrl } from "../api";

import chunk from '../utils/chunk';

import { addProductInCart, deleteProductFromCart, getProductById, getProductByIdInCart } from '../utils/ActionsWithCart';

const ProductCards = () => {
    const products = tabs.get().find(category => category.id === activeTab.get())?.products;

    const SIZE = (windowWidth.get() < 768 && orientation.get() === "PORTRAIT") ? 2 : (windowWidth.get() > 768 && orientation.get() === "LANDSCAPE") ? 3 : 3;

    const productCardsByCategory = [];

    if (products && (products.length !== 0)) {
        const arrays = chunk(products, SIZE);

        for (let i = 0; i < arrays.length; i++) {
            const cardsBySize = [];

            for (let j = 0; j < arrays[i].length; j++) {
                const card = arrays[i][j];

                const productInCart = getProductByIdInCart(card.id);
                const product = getProductById(card.id);

                cardsBySize.push(
                    <div className="MobileMenuItem_root MobileMenuItem_medium" key={"product_" + Math.random()} >
                        <div className="UiKitProductCard_root UiKitProductCard_m UiKitProductCard_minor MobileMenuItem_card">
                            <div className="UiKitProductCard_pictureWrapper">
                                <div className="SmartImage_root UiKitProductCard_picture SmartImage_hideCover">
                                    <div className="SmartImage_cover" onClick={product?.structure.length !== 0 ? () => {
                                        idAboutProduct.set(card.id);
                                        activeModal.set('infoAboutProduct');
                                    } : () => null}>
                                        <img src={productPhotoUrl + card.photo} className="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" alt=""  />
                                    </div>
                                </div>
                                {card.quantity.length !== 0 ? <div className="UiKitProductCard_tokensWrapper">
                                    <div className={"UiKitToken_root UiKitToken_root-" + card.quantity_color +" UiKitToken_m-" + card.quantity_color + " UiKitProductCard_token UiKitToken_leftRotated"}>
                                        <div className="UiKitToken_content">{card.quantity}</div>
                                    </div>
                                </div> : <></>}
                            </div>
                            <div className="UiKitProductCard_infoWrapper">
                                <span className="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitText_root UiKitText_Body2 UiKitText_Medium UiKitText_Text">{card.cost.toLocaleString('ru-RU')} ₸ </span>
                                <div className="UiKitProductCardTitle_title UiKitProductCard_name UiKitProductCardTitle_s">{card.name}</div>
                            </div>
                            <div className="UiKitCounter_root UiKitProductCard_amountSelect UiKitProductCard_counter UiKitCounter_xs UiKitCounter_floating">
                                {productInCart != null ? (<>
                                    <button className='UiKitCounter_button UiKitCounter_decrement' onClick={() => deleteProductFromCart(card.id)}><Icon28MinusOutline width={24} height={24} /></button>
                                    <span className='UiKitCounter_valueContainer'><b>{productInCart.count}</b></span>

                                    <button className='UiKitCounter_button UiKitCounter_increment UiKitCounter_squareIncrement' onClick={() => addProductInCart(card.id)}><Icon24Add /></button>
                                </>) : card.availability ? 
                                    <button className="UiKitCounter_button UiKitCounter_onlyIncrement" onClick={() => addProductInCart(card.id)}><Icon24Add /></button>
                                    :  <button className="UiKitCounter_button UiKitCounter_onlyIncrement UiKitCounter_disabled"><span>Будет позже</span></button>
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            productCardsByCategory.push(
                <div className="LiteCatList_items" key={"list_of_products_" + Math.random()}>
                    <div className="LiteCatList_row" style={{ height: "316px" }}>
                        {cardsBySize}
                    </div>
                </div>
            );
        }

    } else {
        for (let i = 0; i <= 5; i++) {
            const cardsBySize = [];

            for (let j = 0; j < SIZE; j++) {
                cardsBySize.push(<Skeleton className="MobileMenuItem_root MobileMenuItem_medium" key={'skl' + j + "_" + i} />);
            }

            productCardsByCategory.push(
                <div className="LiteCatList_items" key={"list_of_skl" + i}>
                    <div className="LiteCatList_row" style={{ height: "316px" }}>
                        {cardsBySize}
                    </div>
                </div>
            );
        }
    }

    return <>{productCardsByCategory}</>;
}

export default ProductCards;