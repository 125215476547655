import { Button, ButtonGroup, ModalCard, Spacing } from "@vkontakte/vkui";
import { Icon28MentionOutline } from '@vkontakte/icons';

import BaseProps from "../props/BaseProps";
import { activeModal } from "../storage/States";
import React from "react";

const EmailIsActivated = ({ id }: BaseProps) => {
    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28MentionOutline width={56} height={56} fill="#F3294A" />}
            header="E-mail подтверждён"
            subheader="Вы успешно подтвердили ваш E-mail. Спасибо, что выбираете нас! "
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => activeModal.set('')}
                        >
                            Хорошо
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default EmailIsActivated;