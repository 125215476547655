import { View, Panel, PanelHeader, PanelHeaderBack, Group, File, Div, FormItem, Image, Button, Gradient, Placeholder, Input, Select, FormLayoutGroup, Textarea, ScreenSpinner, Snackbar } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeTab, activeView, popout, snackbar, tabs } from "../storage/States";
import { Icon24Camera, Icon28CheckCircleOutline } from "@vkontakte/icons";
import React from "react";
import RequestToApi from "../api";


const AddProductView = ({ id }: BaseProps) => {
    const [category, setCategory] = React.useState<number>(0);
    const [photoURL, setPhotoURL] = React.useState('');
    const [photo, setPhoto] = React.useState<any>(null);
    const [name, setName] = React.useState('');
    const [cost, setCost] = React.useState<number>(0);
    const [quantity, setQuantity] = React.useState<string>('');
    const [quantityColor, setQuantityColor] = React.useState('white');
    const [structure, setStructure] = React.useState('');

    const onChangePhoto = (e: any) => {
        const [file] = e.target.files;

        if (file) {
            setPhotoURL(URL.createObjectURL(file));
            setPhoto(file);
        }
    }

    const InputCost = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            setCost(Number(cost.toString().substring(0, cost.toString().length - 1)));
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (!isNaN(e.nativeEvent.data)) {
                if (cost > 1000000) return;

                setCost(Number(cost.toString() + e.nativeEvent.data));
            } else return;
        }
    }

    const inputQuantity = (e: any) => {
        setQuantity(e.target.value);
    }

    const addProduct = () => {
        popout.set(<ScreenSpinner />);

        const token = localStorage.getItem('access_token');

        const data = {
            name: name,
            cost: cost,
            quantity: quantity.length > 0 ? quantity : "",
            quantity_color: quantityColor,
            structure: structure,
            category_id: category
        }

        RequestToApi({ method: 'post', url: 'products', data: data, token: token }, (data: any) => {
            let formData = new FormData();
            formData.set('file', photo, photo.name);

            RequestToApi({ method: 'post', url: 'products/set-photo/' + data.product_id, data: formData, token: token }, (res) => {
                RequestToApi({ method: 'get', url: 'products' }, (data: any) => {
                    popout.set(null);
                    activeView.set('productsView');
                    snackbar.set(
                        <Snackbar
                            mode="dark"
                            onClose={() => snackbar.set(null)}
                            before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
                        >
                            Вы успешно добавили новую позицию
                        </Snackbar>
                    )
                    tabs.set(data);
                }, (e) => {
                    popout.set(null);
                })
            }, (e) => popout.set(<ScreenSpinner />));
        }, (e) => popout.set(<ScreenSpinner />));
    }


    return (
        <View id={id} activePanel={id}>
            <Panel id={id}>
                <PanelHeader before={<PanelHeaderBack onClick={() => activeView.set('productsView')} />}>Добавление нового блюда</PanelHeader>
                <Group>
                    <Gradient mode="tint" to="top">
                        <Placeholder
                            icon={<Image id="photo_product" size={96} src={photoURL} />}
                            action={
                                <File mode="outline" appearance="negative" before={<Icon24Camera role="presentation" />} size="m" onChange={onChangePhoto}>
                                    Открыть галерею
                                </File>
                            }
                        >
                        </Placeholder>
                    </Gradient>
                </Group>

                <FormLayoutGroup mode="horizontal">
                    <FormItem
                        top="Название"
                    >
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Введите название"
                        />
                    </FormItem>

                    <FormItem top="Цена">
                        <Input type="tel" id="name" value={cost.toLocaleString('ru-RU') + " ₸"} onChange={InputCost} />
                    </FormItem>
                </FormLayoutGroup>

                <FormLayoutGroup mode="horizontal" segmented>
                    <FormItem htmlFor="quantity">
                        <Input id="quantity" placeholder="Кол-во" value={quantity} onChange={inputQuantity} />
                    </FormItem>
                    <FormItem>
                        <Select
                            onChange={(e) => setQuantityColor(e.target.value)}
                            options={[{ label: 'Белый', value: 'white'}, { label: 'Красный', value: 'red' }].map(
                                (i) => ({
                                    label: i.label,
                                    value: i.value,
                                }),
                            )}
                            value={quantityColor}
                        />
                    </FormItem>
                </FormLayoutGroup>

                <FormItem top="Описание">
                    <Textarea placeholder="Введите описание" value={structure} onChange={(e) => setStructure(e.target.value)} />
                </FormItem>


                <FormItem top="Категория" htmlFor="gender-select-id">
                    <Select
                        onChange={(e) => setCategory(Number(e.target.value))}
                        value={category}
                        id="category-select-id"
                        placeholder="Не выбрана"
                        options={tabs.get().map(tab => {
                            return {
                                label: tab.name,
                                value: tab.id
                            }
                        })}
                    />
                </FormItem>
                <Div>
                    <Button
                        onClick={() => addProduct()}
                        disabled={!(category > 0 && photoURL.length !== 0 && name.length !== 0 && cost > 0)}
                        stretched
                        size="l"
                        style={{ backgroundColor: "#F3294A" }}
                    >
                        Добавить
                    </Button>
                </Div>
            </Panel>
        </View>
    )
}

export default AddProductView;