import React from 'react';
import { Link, ModalPage, Spacing, Subhead, SubnavigationBar, SubnavigationButton } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, activeView } from "../storage/States";
import { Icon28InfoOutline, Icon28MotorcycleOutline, Icon28MoneyWadOutline, Icon28LockOutline } from '@vkontakte/icons';

const InfoAboutRestaurantModal = ({ id }: BaseProps) => {
    return (
        <ModalPage
            id={id}
            onClose={() => activeModal.set('')}
        >

            <div className='MobileUIBottomSheet_baseContainer MobileUIBottomSheet_childContainer MobileUIBottomSheet_baseContainerOpened MobileUIBottomSheet_baseContainerTransitioned'>
                <div className='MobileUIBottomSheet_inner MobileUIBottomSheet_innerShadow MobileUIBottomSheet_innerHideOverflow'>
                    <div className='MobileUIBottomSheetLayout_root'>
                        <div className='MobileUIBottomSheetHeader_root UiKitText_root UiKitText_Title4 UiKitText_Bold UiKitText_Text'>Фудзияма</div>
                        <div className='MobileUIBottomSheetBody_root MobilePlaceInfoBottomSheet_body'>
                            <ul className='MobilePlaceInfoBottomSheet_sections'>
                                <li className='MobilePlaceInfoBottomSheet_section MobilePlaceInfoBottomSheet_addressSection'>
                                    <span>г. Житикара, 6 микрорайон, д. 23, нп. 2</span>
                                    <span className='MobilePlaceInfoBottomSheet_timeLable'>Работаем с 10:00 до 22:00</span>
                                </li>
                                <li className='MobilePlaceInfoBottomSheet_section MobilePlaceInfoBottomSheet_addressSection'>
                                    <span>
                                        <SubnavigationBar mode="fixed">
                                            <SubnavigationButton
                                                before={<Icon28InfoOutline fill="#F3294A" />}
                                                size="l"
                                                onClick={() => { activeModal.set(''); activeView.set('offerView') }}

                                            >
                                                Публичная оферта
                                            </SubnavigationButton>
                                        </SubnavigationBar>
                                        <SubnavigationBar mode="fixed">
                                            <SubnavigationButton
                                                before={<Icon28MotorcycleOutline fill="#F3294A" />}
                                                size="l"
                                                onClick={() => { activeModal.set(''); activeView.set('deliveryTerms') }}

                                            >
                                                Условия доставки
                                            </SubnavigationButton>
                                        </SubnavigationBar>
                                        <SubnavigationBar mode="fixed"><SubnavigationButton
                                            before={<Icon28MoneyWadOutline fill="#F3294A" />}
                                            size="l"
                                            onClick={() => { activeModal.set(''); activeView.set('paymentTermsView') }}

                                        >
                                            Условия оплаты/возврата денег
                                        </SubnavigationButton>
                                        </SubnavigationBar>
                                        <SubnavigationBar mode="fixed">
                                            <SubnavigationButton
                                                before={<Icon28LockOutline fill="#F3294A" />}
                                                size="l"
                                                onClick={() => { activeModal.set(''); activeView.set('privacyPolicyView') }}

                                            >
                                                Политика конфиденциальности
                                            </SubnavigationButton>
                                        </SubnavigationBar>
                                    </span>
                                </li>
                                <Spacing size={20} />
                                <li className="MobilePlaceInfoBottomSheet_footerDescription">
                                    <span>
                                        Исполнитель: ИП «Кафетерий Фудзияма»
                                        <br /> ИИН 810813450587
                                        <br /> 110700, Житикара, 6 микрорайон, 23, нп. 2
                                        <br />
                                        <br />Контактный номер  телефона: <Link href='tel:+77054618106'>+7 (705) 461-81-06</Link>
                                        <br />E-mail: <Link href='mailto:info@fujiyama.kz'>info@fujiyama.kz</Link>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ModalPage >
    )
}

export default InfoAboutRestaurantModal;