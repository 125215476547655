import { useEffect, useRef, useState } from "react";
import { activeModal, timeUntilTheNextEmail, userData } from "../storage/States";
import { Button, ButtonGroup, Div, FormItem, FormStatus, Input, ModalCard, Spacing, Spinner } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { Icon28MentionOutline } from "@vkontakte/icons";
import React from "react";
import { instance } from "../api";


const ERRORS_MAP = {
    'empty': 'Пожалуйста, введите новую электронную почту',
    'incorrect': 'Электронная почта некорректна',
};

const ChangeEmail = ({ id }: BaseProps) => {
    const [timerMinutes, setTimerMinutes] = useState<string>();
    const [timerSeconds, setTimerSeconds] = useState<string>();
    const [showTimer, setShowTimer] = useState<boolean>();
    const [loading, setLoading] = useState(false);


    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string>('empty');

    const [errorMessageFromServer, setErrorMessageFromServer] = useState("");

    let interval = useRef<ReturnType<typeof setInterval>>();

    const startTimer = () => {
        interval.current = setInterval(() => {
            const now = Date.now();

            const distance = timeUntilTheNextEmail.get() - now;

            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current);
                setShowTimer(false);
            } else {
                setTimerMinutes(minutes < 10 ? ("0" + minutes) : String(minutes));
                setTimerSeconds(seconds < 10 ? ("0" + seconds) : String(seconds));
                setShowTimer(true);
            }
        }, 10)
    }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    });

    const validateEmail = (email: string) => {
        return email.match(
            /* eslint-disable-next-line */
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    };


    const updateEmail = (value: string) => {
        setEmail(value);
        if (!value) {
            setEmailError('empty');
        } else if (!validateEmail(value)) {
            setEmailError('incorrect');
        } else {
            setEmailError('');
        }
    };

    const catchError = (e: any) => {
        setLoading(false);
        if (e.response && e.response.data) {
            setErrorMessageFromServer(e.response.data.message);
        } else {
            setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
        }
    }

    const changeEmail = () => {
        setErrorMessageFromServer("");
        setLoading(true);

        const token = localStorage.getItem('access_token');

        const auth = { headers: { 'authorization': 'Bearer ' + token } };

        instance.post('user/email', { email }, auth)
            .then(() => {
                instance.get('auth/resend-time', auth)
                    .then((response) => {
                        timeUntilTheNextEmail.set(response.data.timeUntilTheNextEmail);

                        instance.get('user/profile', auth)
                            .then((response) => {
                                setLoading(false);
                                userData.set(response.data);
                                activeModal.set('necessaryToConfirmAndEditEmail');
                            })
                            .catch(catchError)
                    })
                    .catch(catchError)


            })
            .catch(catchError)
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28MentionOutline width={56} height={56} fill="#F3294A" />}
            header="Изменение E-mail"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="horizontal" stretched>
                        <Button
                            disabled={emailError.length !== 0}
                            size="l"
                            mode="primary"
                            style={{ backgroundColor: "#F3294A" }}
                            stretched
                            onClick={() => changeEmail()}
                        >
                            {!loading ? <>{!showTimer ? "Изменить" : (timerMinutes + ":" + timerSeconds)}</> : <Spinner size="regular" style={{ color: "#fff" }} />}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            <Spacing size={16} />
            {errorMessageFromServer.length > 0 ? <Div>
                <FormStatus mode="error">
                    {errorMessageFromServer}
                </FormStatus>
            </Div> : <></>}
            <FormItem

                htmlFor="email"
                top="E-mail"
                status={emailError ? 'error' : 'valid'}
                bottom={emailError ? String(ERRORS_MAP[emailError === 'empty' ? 'empty' : 'incorrect']) : ''}
                bottomId="email-type"
            >
                <Input
                    disabled={loading}
                    aria-labelledby="email-type"
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    required
                    onChange={(e) => updateEmail(e.target.value)}
                />
            </FormItem>
        </ModalCard>
    )
}

export default ChangeEmail;