import React from 'react';
import { Avatar, Footer, RichCell, Skeleton } from "@vkontakte/vkui";
import { transactions } from "../storage/States";
import { Icon28ClockCircleFillGray, Icon28MoneyRequestOutline, Icon28MoneySendOutline, Icon28CheckCircleFill, Icon28CancelCircleFillRed } from "@vkontakte/icons";

interface ListOfTransactionsType {
    activeTab: 'GAIN' | 'PAY';
    loading: boolean;
}

const getTransactionIcon = (actionWithBonues: 'GAIN' | 'PAY') => {
    if (actionWithBonues === 'GAIN') return <Icon28MoneyRequestOutline fill="#3AB83A" />;
    else return <Icon28MoneySendOutline fill="#FF3A4D" />
}

const getTransactionStatusIcon = (status: 'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED') => {
    if (status === 'AWAITING_CONFIRMATION') return <Icon28ClockCircleFillGray />;
    else if (status === 'CONFIRMED') return <Icon28CheckCircleFill />
    else return <Icon28CancelCircleFillRed />
}

const getValueBonus = (actionWithBonues: 'GAIN' | 'PAY', bonuses: number) => {
    if (actionWithBonues === 'GAIN') return "+ " + bonuses.toLocaleString('ru-RU') + " Б";
    else return "- " + bonuses.toLocaleString('ru-RU') + " Б"
}

const ListOfTransactions = ({ activeTab, loading }: ListOfTransactionsType) => {
    const RenderTransactions = [];

    if (loading) {
        for (let i = 0; i < 10; i++) {
            RenderTransactions.push(
                <RichCell
                    key={'transaction' + i}
                    before={<Skeleton width={36} height={36} borderRadius="50%" />}
                    caption={<Skeleton width={90} />}
                    afterCaption={<Skeleton width={28} height={28} borderRadius="50%" />}
                    multiline
                >
                    <Skeleton width="75%" />
                </RichCell>
            );
        }
        
        return <>{RenderTransactions}</>
    } else {
        const trans = transactions.get();

        if (trans !== null) {
            for (let i = 0; i < trans.transactions.length; i++) {
                if (trans.transactions[i].actionWithBonuses === activeTab) {

                    RenderTransactions.push(
                        <RichCell
                            key={trans.transactions[i].orderId}
                            before={<Avatar size={48}>{getTransactionIcon(trans.transactions[i].actionWithBonuses)}</Avatar>}
                            caption={"Заказ №" + trans.transactions[i].orderId}
                            afterCaption={getTransactionStatusIcon(trans.transactions[i].status)}
                            multiline
                        >
                            {getValueBonus(trans.transactions[i].actionWithBonuses, trans.transactions[i].bonuses)}
                        </RichCell>
                    );
                } else continue;
            }

            return <>{RenderTransactions.length > 0 ? RenderTransactions : <Footer>Транзакции не найдены</Footer>}</>
        }

        else return <Footer>Транзакции не найдены</Footer>;
    }
}

export default ListOfTransactions;