import { Icon28WarningTriangleOutline } from "@vkontakte/icons"
import { ModalCard, Spacing, ButtonGroup, Button } from "@vkontakte/vkui"
import React from "react"
import BaseProps from "../props/BaseProps"
import { activeModal, messageForClosure, titleForClosure } from "../storage/States"

const ReasonForClosure = ({ id }: BaseProps) => {
    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28WarningTriangleOutline width={56} height={56} fill="#F3294A" />}
            header={titleForClosure.get()}
            subheader={messageForClosure.get()}
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => activeModal.set('')}
                        >
                            Хорошо
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        />
    )
}

export default ReasonForClosure;