const timeConverter = (unix: number) => {
    let a = new Date(unix);

    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();

    let hour = a.getHours() < 10 ? '0' + a.getHours() : a.getHours();
    let min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();

    var time = date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;

    return time;
}

export default timeConverter;