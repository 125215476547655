import { ReactElement } from 'react';

import Store from './Store';
import TabsType from '../types/TabsType';
import UserType from '../types/UserType';
import NewCartType from '../types/NewCartType';
import DeliveryMethodType from '../types/DeliveryMethodType';
import TransactionsType from '../types/TransactionsType';
import OrderType from '../types/OrderType';
import OrderForAdminPanel from '../types/OrderForAdminPanelType';
import PromotionType from '../types/PromotionType';
import ReasonToClosureType from '../types/ReasonToClosureType';
import AnonimOrderType from '../types/AnonimOrderType';


export const textForWorkScheduleModal = Store.create<string>('textForWorkScheduleModal', '');

export const titleForClosure = Store.create<string>('titleForClosure', '');
export const messageForClosure = Store.create<string>('messageForClosure', '');

export const activeModal = Store.create<string>('activeModal', '');
export const activeView = Store.create<string>('activeView', 'loadingView');
export const errorMessage = Store.create<string | null>("errorMessage", null);
export const activeStory = Store.create<string>('activeStory', "menu");

export const activeTabOrdersAdminPanel = Store.create<'AWAITING_CONFIRMATION' | 'CONFIRMED' | 'NOT_CONFIRMED'>('activeTabOrdersAdminPanel', 'AWAITING_CONFIRMATION');
export const actionWithBonusesOrdersAdminPanel = Store.create<'NOT_AUTHORIZED' | 'PAY' | 'GAIN'>('actionWithBonusesOrdersAdminPanel', 'NOT_AUTHORIZED');


export const tabs = Store.create<TabsType[]>("tabs", []);
export const draggingListTabs = Store.create<TabsType[]>("draggingListTabs", []);
export const activeTab = Store.create<number>("activeTab", 1);

export const snackbar = Store.create<ReactElement | null>("snackbar", null);
export const popout = Store.create<ReactElement | null>("popout", null);

export const windowWidth = Store.create<number>("windowWidth", 0);
export const orientation = Store.create<'PORTRAIT' | 'LANDSCAPE'>("orientation", 'PORTRAIT');

export const userEmail = Store.create<string>("email", "");
export const userPassword = Store.create<string>("password", "");
export const newCart = Store.create<NewCartType[]>("newCart", []);
export const userData = Store.create<UserType | null>('userData', null);
export const timeUntilTheNextEmail = Store.create<number>('timeUntilTheNextEmail', 0);
export const transactions = Store.create<TransactionsType | null>('transactions', null);
export const myOrders = Store.create<OrderType[]>('myOrders', []);

export const anonimOrder = Store.create<AnonimOrderType | null>('anonimOrder', null);
export const anonimOrderToken = Store.create<string>('anonimOrderToken', '');

export const promotions = Store.create<PromotionType[]>('promotions', []);
export const allPromotions = Store.create<PromotionType[]>('allPromotions', []);

export const allReasonsForClosure = Store.create<ReasonToClosureType[]>('allReasonsForClosure', []);

export const ordersForAdminPanel = Store.create<OrderForAdminPanel[]>('ordersForAdminPanel', []);

export const percentOfBonuses = Store.create<number>('percentOfBonuses', 1);

export const deliveryMethods = Store.create<DeliveryMethodType[]>("deliveryMethods", []);
export const selectedDeliveryMethod = Store.create<number>("selectedDeliveryMethod", 0);
export const actionWithBonuses = Store.create<'NOT_AUTHORIZED' | 'GAIN' | 'PAY'>('actionWithBonuses', 'NOT_AUTHORIZED');
export const lastOrderId = Store.create<number>('lastOrderId', 0);
export const lastOrderPayToken = Store.create<string>('lastOrderPayToken', '');
export const infoAboutOrderById = Store.create<number>('infoAboutOrderById', 0);
export const infoAboutOrderByIdAdmin = Store.create<number>('infoAboutOrderByIdAdmin', 0);

export const editableCategoryId = Store.create<number>('editableCategoryId', 0);
export const editableProductId = Store.create<number>('editableProductId', 0);
export const editabledDeliveryMethodId = Store.create<number>('editableProductId', 0);
export const editablePromotionId = Store.create<string>('editablePromotionId', '');
export const editableReasonToClosure = Store.create<string>('editableReasonToClosure', '');

export const idAboutProduct = Store.create<number>('idAboutProduct', 0);

export const orderByDate = Store.create<Date>('orderByDate', new Date());
export const infoAboutOrderByIdObject = Store.create<OrderForAdminPanel | null>('infoAboutOrderByIdObject', null); 

export const notAvailabilityOfTheDish = Store.create<number[]>('notAvailabilityOfTheDish', []);
export const availabilityOfTheDish = Store.create<number[]>('availabilityOfTheDish', []);