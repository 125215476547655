import React from "react";

import BaseProps from "../props/BaseProps";

import { View, Panel, FixedLayout, Spinner } from '@vkontakte/vkui';

import { activeView, userData, newCart, actionWithBonuses, errorMessage, activeModal, promotions } from "../storage/States";

import full_logo from '../lib/img/full_logo.svg';

import RequestToApi, { instance } from "../api";


const LoadingView = ({ id }: BaseProps) => {
    const goToHomeViewWithoutData = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        RequestToApi({ method: 'get', url: 'promotion' }, (response) => {
            promotions.set(response);
            activeView.set('homeView');
        });
    }

    const activateEmail = async (token: string, cb?: (data: any) => void) => {
        return await instance.put('auth/activate', {}, { headers: { authorization: "Bearer " + token } })
            .then((response) => {
                return (cb !== undefined ? cb(response.data) : null)
            }).catch((e: any) => {
                if (e.response && e.response.data) {
                    if (e.response.data.id === "EMAIL_IS_ACTIVATED") {
                        window.history.pushState({}, 'main', '/');

                        fetchData();
                    }
                } else {
                    activeView.set('errorView');
                }
            });
    }

    const getProfile = async (cb?: (data: any) => void, cbError?: (e: any) => void) => {
        const access_token = localStorage.getItem('access_token');

        if (access_token) {
            return await instance.get('user/profile', { headers: { authorization: "Bearer " + access_token } })
                .then((response: any) => (cb !== undefined ? cb(response.data) : null))
                .catch((e) => (cbError !== undefined ? cbError(e) : null));
        } else {
            return goToHomeViewWithoutData();
        }
    }

    const RefreshToken = (cb: (data: any) => void, cbError?: (e: any) => void) => {
        const refresh_token = localStorage.getItem('refresh_token');

        if (refresh_token) {
            instance.get('auth/refresh', { headers: { authorization: "Bearer " + refresh_token } })
                .then(response => {
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);

                    return (cb !== undefined ? cb((data: any) => cb(data)) : null)
                })
                .catch((e) => (cbError !== undefined ? cbError(e) : null))
        } else {
            return goToHomeViewWithoutData();
        }
    }

    const DataReceived = (data: any) => {
        RequestToApi({ method: 'get', url: 'promotion' }, (response) => {
            promotions.set(response);
            userData.set(data);
            newCart.set(data.cart);
            activeView.set('homeView');
            actionWithBonuses.set('GAIN');
        })
    }


    const fetchData = async () => {
        const url = new URL(window.location.href);

        if (url.searchParams.get('action') !== null && url.searchParams.get('action') === 'emailActivate') {
            const token = url.searchParams.get('token');
            if (token) {
                return await activateEmail(token, (data) => {
                    activeView.set('homeView');
                    activeModal.set('emailIsActivated')
                })
            } else {
                errorMessage.set('Неверный запрос')
                activeView.set('errorView');
            }
        }

        else if (url.searchParams.get('action') !== null && url.searchParams.get('action') === 'resetPassword') {
            const token = url.searchParams.get('token');
            if (token) {
                activeView.set('homeView');
                activeModal.set('resetPasswordByToken');
            } else {
                errorMessage.set('Неверный запрос')
                activeView.set('errorView');
            }

        }

        else if (url.searchParams.get('action') === null || url.searchParams.get('action') !== 'emailActivate' || url.searchParams.get('action') !== 'resetPassword') {
            getProfile((data: any) => DataReceived(data), (e) => {
                if (e.response && e.response.data && e.response.data.id === 'NOT_AUTHORIZED') {
                    RefreshToken((data) => getProfile((data) => DataReceived(data), (e) => goToHomeViewWithoutData()), (e) => goToHomeViewWithoutData());
                }
                else goToHomeViewWithoutData();
            });
        }
    }


    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);


    return (
        <View id={id} activePanel={id}>
            <Panel id={id} centered>
                <div className="icon">
                    <img src={full_logo} width={256} height={256} alt="" />
                </div>
                <FixedLayout vertical='bottom'>
                    <Spinner size="medium" style={{ margin: '50px 0' }} />
                </FixedLayout>
            </Panel>
        </View>
    )
}

export default LoadingView;