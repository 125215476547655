import React from 'react';
import { Cell, Group, List, ModalPage, ModalPageHeader, PanelHeaderClose } from "@vkontakte/vkui";
import BaseProps from "../props/BaseProps";
import { activeModal, deliveryMethods, selectedDeliveryMethod } from "../storage/States";
import { Icon24Done } from "@vkontakte/icons";


const ChooseDeliveryMethod = ({ id }: BaseProps) => {
    return (
        <ModalPage
            id={id}
            onClose={() => activeModal.set('newOrder')}
            header={
                <ModalPageHeader before={<PanelHeaderClose onClick={() => activeModal.set('newOrder')} />}>
                    Способ доставки
                </ModalPageHeader>
            }
        >
            <Group>
                <List style={{ marginBottom: "15%" }}>
                    {deliveryMethods.get().map(method => (
                        <Cell
                            subtitle={method.cost + " ₸"}
                            onClick={() => {
                                selectedDeliveryMethod.set(method.id);
                                activeModal.set('');
                            }}
                            after={
                                selectedDeliveryMethod.get() === method.id ? <Icon24Done fill="var(--vkui--color_icon_accent)" /> : null
                            }
                        >
                            {method.name}
                        </Cell>
                    ))}
                </List>
            </Group>
        </ModalPage >
    )
}

export default ChooseDeliveryMethod;