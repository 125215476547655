import { Icon28SearchOutline } from "@vkontakte/icons";
import { ModalCard, Spacing, ButtonGroup, Button, Spinner, FormItem, Input, Div, FormStatus } from "@vkontakte/vkui";
import { add } from "date-fns";
import React from "react";
import BaseProps from "../props/BaseProps";
import { activeModal, activeView, infoAboutOrderByIdObject } from "../storage/States";
import RequestToApi, { instance } from "../api";

const InfoAboutOrderById = ({ id }: BaseProps) => {
    const [loading, setLoading] = React.useState(false);
    const [orderId, setOrderId] = React.useState<string>('');
    const [errorMessageFromServer, setErrorMessageFromServer] = React.useState('');

    const inputId = (e: any) => {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            setOrderId(orderId.toString().substring(0, orderId.toString().length - 1));
        } else if (e.nativeEvent.inputType === 'insertText') {
            if (!isNaN(e.nativeEvent.data)) {
                setOrderId(orderId + e.nativeEvent.data);
            } else return;
        }
    }

    const search = () => {
        if (loading) return;
        setLoading(true);

        instance.get('order/' + orderId, { headers: { 'authorization': 'Bearer ' + localStorage.getItem('accesss_token') } })
            .then((response) => {
                setLoading(false)
                infoAboutOrderByIdObject.set(response.data);
                activeModal.set('');
                activeView.set('infoAboutOrderByIdView');
             })
            .catch(errorHandling)
    }


    const errorHandling = (e: any) => {
        setLoading(false);

        if (e.response && e.response.data) {
            setErrorMessageFromServer(e.response.data.message);
        } else {
            setErrorMessageFromServer('Произошли непредвиденные сложности при выполнении запроса');
        }
    }

    return (
        <ModalCard
            id={id}
            onClose={() => activeModal.set('')}
            icon={<Icon28SearchOutline width={56} height={56} fill="#F3294A" />}
            header="Поиск заказа по номеру"
            actions={
                <React.Fragment>
                    <Spacing size={8} />
                    <ButtonGroup gap="m" mode="vertical" stretched>
                        <Button
                            style={{ backgroundColor: "#F3294A" }}
                            key="join"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => search()}
                            loading={loading}
                            disabled={orderId.length === 0}
                        >

                            Найти
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        >
            {errorMessageFromServer.length > 0 ?
                <Div>
                    <FormStatus mode="error">
                        {errorMessageFromServer}
                    </FormStatus>
                </Div> : <></>}
            <FormItem top="Номер заказа">
                <Input type="tel" id="name" value={orderId} onChange={inputId} />
            </FormItem>
        </ModalCard>
    )
}

export default InfoAboutOrderById;